import React, { useEffect, useState } from 'react';
import Modal from '../Common/ModalComponent/ModalComponent';
import {
    Avatar,
    makeStyles

} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import './StudentList.scss';
import {
    getstudenthomeworklist, updatestudenthomeworklist, updatestudenthomeworklistfeedbackcommon, studenthomework_detail, imgURL
} from '../../common/API'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Aux from '../../hoc/Wrapper';
import { getCurrentUserId } from '../../common/Functions';
import Button from '../Common/Button/Button';
import { toast } from 'react-toastify';
import Flow from '../SolutionGraph/flow';
import { Row, Col } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
//import Paper from '@mui/material/Paper';
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
//import { IconButton } from '@mui/material'
//import Tooltip from '@mui/material/Tooltip';
import HintIcon from '../../assets/images/icon-hint-show.svg';
import checkWorkIcon from '../../assets/images/icon-check.svg';
import ShowNextstepIcon from '../../assets/images/icon-show-next-step.svg';
import ShowFullSolutionIcon from '../../assets/images/icon-show-full-solution.svg';
import CheckMyworkResultC from '../Answerboard/CheckMyworkResultC';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';




function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    key="student_name"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "student_name" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "student_name"}
                        direction={orderBy === "student_name" ? order : 'asc'}
                        onClick={createSortHandler("student_name")}
                    >
                        Student Name
                        {orderBy === "student_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="totalgrade"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "totalgrade" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "totalgrade"}
                        direction={orderBy === "totalgrade" ? order : 'asc'}
                        onClick={createSortHandler("totalgrade")}
                    >
                        Total Grades
                        {orderBy === "totalgrade" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    key="common_feedback"
                    align={'left'}
                    padding={'normal'}
                    sortDirection={orderBy === "common_feedback" ? order : false}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableSortLabel
                        active={orderBy === "common_feedback"}
                        direction={orderBy === "common_feedback" ? order : 'asc'}
                        onClick={createSortHandler("common_feedback")}
                    >
                        Feedback
                        {orderBy === "common_feedback" ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    avatarlarge: {
        height: 150,
        width: 150,
    },
    linkbutton: {
        fontSize: "10px"
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#a4a4a4",
        color: theme.palette.common.white,
        padding: "10px",
        fontSize: 16,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "7px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const StudentList = () => {

    const params = useParams();
    const classes = useStyles();
    const [challengeTitle, setchallengeTitle] = useState("")
    const [studentData, setstudentData] = useState([])
    const [modalData, setmodalData] = useState([])
    const [flowUIJson, setflowUIJson] = useState([])
    const [selectedStudentName, setselectedStudentName] = useState("")
    const [selectedStudent_id, setselectedStudent_id] = useState(0)
    const [boardId, setboardId] = useState(null)
    const [showModal, setshowmodel] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [currentindex, setcurrentindex] = useState(null)
    const [open, setOpen] = useState(false);
    const [hintdata, sethintdata] = useState([])
    const [loading, setloading] = useState(false)
    const [hintDissplay, sethintDisplay] = useState([])
    const [updatedtagrade, setupdatedtagrade] = useState(0)
    const [currentIndex1, setcurrentindex1] = useState(null)
    const [isgraded, setIsGraded] = useState(false)
    const [is_review_complete, setis_review_complete] = useState(false)
    const [textareavalue, settextareavalue] = useState("")
    const [noequationfound, setnoequationfound] = useState(false)
    const [hintDivVisible, setHintDivVisible] = useState(false)
    const [checkMyWorkDivVisible, setCheckMyWorkDivVisible] = useState(false)
    const [showNextStepDivVisible, setShowNextStepDivVisible] = useState(false)
    const [showSolutionDivVisible, setShowSolutionDivVisible] = useState(false)
    const [userGetPoints, setUserGetPoints] = useState(0)
    const [userTotalPointScore, setUserTotalPointScore] = useState(0)
    const [commonFeedback, setCommonFeedback] = useState("")
    const [reSubmitMessageShow, setReSubmitMessageShow] = useState(false)
    const [totalPages, settotalPages] = useState(1)
    const [page, setpage] = useState(1)
    const [startPage, setstartPage] = useState(0)
    const [rowsPerPage, setrowsPerPage] = useState(10)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('student_name');
    const [selected, setSelected] = React.useState([]);


    let challengeId = params.challengeId

    let homeworkproblems_id = params.homeworkId

    let assumed_content_list = [];
    let stepNo = 0;
    let line_match = 0;
    let isLastStepCorrect = false;
    let correct_version_exists = false;
    let correct_version_line_no = -1;
    let isChallengeCompleted = false;

    const assumed_step_feedback = ["Great job advancing to this step."]
    let ind_assumed_step_feedback = Math.floor(Math.random() * 1);
    const correct_step_feedback =
        ["Well done!", "You've nailed it.", "Spot on.", "Fantastic."];
    let ind_correct_step_feedback = Math.floor(Math.random() * 4);
    const correct_step_feedback_ending =
        ["", ""];
    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);
    // }

    useEffect(() => {
        setloading(true)
        //alert(params.istesthomework)
        const reqData = {
            params: {
                'challenge_id': challengeId, 'homeworkproblems_id': homeworkproblems_id,
                'problem_source': params.problemsource
            }
        }
        getstudenthomeworklist(reqData).then(res => {
            setchallengeTitle(res.data.challengeName)
            if (res.data.data[0] !== undefined) {
                setis_review_complete(res.data.data[0].is_review_complete)
                setstudentData(res.data.data)
                settotalPages(Math.ceil(res.data.data.length / rowsPerPage))
                setloading(false)
            }
            else {
                setloading(false)
            }
        })

    }, [])
    const handleChangePage = (event, page) => {
        // this.setState({ page }, () => {
        //     // this.raiseHandClickHandler()
        // });
        setpage(page)
        setstartPage(page - 1)
    }


    const handleRequestSort = (event, property) => {
        debugger;
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentData.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(studentData, getComparator(order, orderBy)).slice(
                startPage * rowsPerPage,
                startPage * rowsPerPage + rowsPerPage,
            ),
        [studentData, order, orderBy, page, rowsPerPage],
    );


    const resetdta = () => {
        setmodalData(null)
        setflowUIJson(null)
        setshowmodel(true)
        setnoequationfound(true)
        setloading(false)
    }

    const openStudentLinkPopUp = (boardid, studentId, studentName, hintdata, graded, index, usergetpoints, totalpointsscore) => {
        setloading(true)
        setHintDivVisible(false)
        setCheckMyWorkDivVisible(false)
        setShowNextStepDivVisible(false)
        setselectedStudentName(studentName)
        setselectedStudent_id(studentId)
        setIsGraded(graded)
        setcurrentindex1(index)
        setUserTotalPointScore(totalpointsscore)
        setUserGetPoints(parseFloat(usergetpoints).toFixed(2))
        setModalTitle('Homework Details For : ' + studentName)
        const reqData = { params: { 'challenge_id': challengeId, 'homeworkproblems_id': homeworkproblems_id, "studentId": studentId, 'problem_source': params.problemsource } }
        studenthomework_detail(reqData).then(res => {
            if (res.data.data[0] !== undefined) {
                debugger;
                setboardId(boardid)
                sethintdata(hintdata)

                setIsGraded(graded)
                if (res.data.data[0].common_feedback !== undefined && res.data.data[0].common_feedback !== null) {
                    setCommonFeedback(res.data.data[0].common_feedback)
                }
                if (res.data.data[0].submit_id !== undefined && res.data.data[0].submit_id !== null && res.data.data[0].is_review_complete !== undefined && res.data.data[0].is_review_complete !== null && res.data.data[0].is_review_complete !== "false" && res.data.data[0].is_review_complete !== false) {
                    if (parseInt(res.data.data[0].submit_id) > 1)
                        setReSubmitMessageShow(true)
                    else {
                        setReSubmitMessageShow(false)
                    }
                }
                else {
                    setReSubmitMessageShow(false)
                }
                if (res.data.data[0].equations !== undefined && res.data.data[0].equations.length > 0) {
                    if (res.data.data[0].equations[0].result !== undefined && res.data.data[0].equations[0].result !== null && res.data.data[0].equations[0].result.length > 0) {
                        if (res.data.data[0].equations[0].result[0].inputLatex !== "" && res.data.data[0].equations[0].result[0].inputLatex !== null && res.data.data[0].equations[0].result[0].inputLatex !== undefined) {
                            setmodalData(res.data.data[0].equations)
                            setflowUIJson(res.data.data[0].equations[0].ui_json)
                            setshowmodel(true)
                            setloading(false)
                            setnoequationfound(false)
                        }
                        else {
                            resetdta()
                        }
                    }
                    else {
                        resetdta()
                    }
                }
                else {
                    resetdta()
                }
            }
            else {
                resetdta()
            }
        })


    }
    const handlecommonfeedbackchanged = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCommonFeedback(e.target.value)
    }

    const handleFeedbackChangeEventCorrect = (e, index) => {

        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData !== undefined && modalData.length > 0) {
            changeData = [...modalData]
            setcurrentindex(index)
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.tafeedback = e.target.value
                changeData[0].result[index] = tempArr
                setmodalData(changeData)
            }
        }

    }

    const handleFeedbackChangeEventIncorrect = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData !== undefined && modalData.length > 0) {
            changeData = [...modalData]
            setcurrentindex(index)
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.tafeedback = e.target.value
                changeData[0].result[index] = tempArr
                setmodalData(changeData)
            }
        }
    }

    const handleHintPointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData !== undefined && modalData.length > 0) {
            changeData = [...modalData]
            setcurrentindex(index)
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.hint_points = e.target.value
                changeData[0].result[index] = tempArr
                setmodalData(changeData)
            }
        }
    }
    const handlePointsChangeEvent = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let changeData = []
        if (modalData !== undefined && modalData.length > 0) {
            changeData = [...modalData]
            setcurrentindex(index)
            if (changeData[0].result[index] !== undefined) {
                let tempArr = changeData[0].result[index]
                tempArr.points = e.target.value
                changeData[0].result[index] = tempArr
                debugger;
                setmodalData(changeData)
            }
        }
        let ta_totalgrades = 0
        changeData[0].result.map((item) => {
            if (Number.isInteger(item.line_match)) {
                // ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.hint_points, 2))
                if (item.status === "correct") {

                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                    if (parseFloat(item.hint_points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.hint_points, 2)
                    }

                }
                else {

                    if (parseFloat(item.points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                    }

                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                }
            }

        })
        setUserGetPoints(parseFloat(ta_totalgrades).toFixed(2))

    }

    const handleclosemodal = () => {
        setmodalData(null)
        setshowmodel(false)
    }
    const handleUpdateonlyFeedback = () => {
        let form_data = new FormData();
        form_data.append("challengeId", challengeId)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", selectedStudent_id)
        form_data.append("boardId", boardId)
        form_data.append("homeworkproblems_id", homeworkproblems_id)
        form_data.append("problem_source", params.problemsource)
        form_data.append("common_feedback", commonFeedback)
        updatestudenthomeworklistfeedbackcommon(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setupdatedtagrade(res.data)
                let changeData = [...visibleRows]
                if (visibleRows[currentIndex1] !== undefined) {
                    let tempArr = changeData[currentIndex1]
                    //tempArr.totalgrade = parseFloat(ta_totalgrades, 3)
                    tempArr.common_feedback = commonFeedback
                    changeData[currentIndex1] = tempArr
                    setstudentData(changeData)
                }
                setshowmodel(false)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
        })
    }
    const handleUpdate = () => {

        let tafeedbackData = []
        let ta_totalgrades = 0
        let ta_hint_points = 0
        modalData[0].result.map((item) => {
            if (Number.isInteger(item.line_match)) {
                // ta_hint_points = parseFloat(ta_hint_points, 2) - (parseFloat(item.hint_points, 2))
                if (item.status === "correct") {
                    tafeedbackData.push({
                        "status": item.status,
                        "eq_correct": item.eq_correct,
                        "inputLatex": item.inputLatex,
                        "points": item.points,
                        "line_match": item.line_match,
                        "tafeedback": item.tafeedback,
                        "hintdata": item.hintdata,
                        "hint_points": item.hint_points,
                        "checkmyworkuseddata": item.checkmyworkuseddata,
                        "expectedSymbols": item.expectedSymbols,
                        "explanation": item.explanation,
                        "final": item.final,
                        "is_partially_complete": item.is_partially_complete,
                        "is_ue": item.is_ue,
                        "isthumbsup": item.isthumbsup,
                        "linenumber": item.linenumber,
                        "new": item.new,
                        "nextstep_used": item.nextstep_used,
                        "node_id": item.node_id,
                        "remedial_source": item.remedial_source,
                        "remedial_source_display_name": item.remedial_source_display_name,
                        "remedial_text": item.remedial_text,
                        "show_solution_used": item.show_solution_used,
                        "symbolsDiff": item.symbolsDiff,
                        "totalpointscore": item.totalpointscore,
                        "updateremedialafterSubmithw": item.updateremedialafterSubmithw
                    },
                    )

                    ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)

                    if (parseFloat(item.hint_points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.hint_points, 2)
                    }

                }
                else {
                    tafeedbackData.push({
                        "status": item.status,
                        "eq_correct": item.eq_correct === undefined ? item.inputLatex : item.eq_correct,
                        "inputLatex": item.inputLatex,
                        "points": item.points,
                        "line_match": item.line_match,
                        "remedial_text": item.remedial_text,
                        "remedial_text2": item.remedial_text2,
                        "remedial_source_display_name": item.remedial_source_display_name,
                        "remedial_source": item.remedial_source,
                        "tafeedback": item.tafeedback,
                        "hintdata": item.hintdata,
                        "hint_points": item.hint_points,
                        "checkmyworkuseddata": item.checkmyworkuseddata,
                        "correct_version_exists": item.correct_version_exists,
                        "correct_version_line_no": item.correct_version_line_no,
                        "expectedSymbols": item.expectedSymbols,
                        "final": item.final,
                        "is_ue": item.is_ue,
                        "isthumbsup": item.isthumbsup,
                        "linenumber": item.linenumber,
                        "nextstep_used": item.nextstep_used,
                        "node_id": item.node_id,
                        "show_solution_used": item.show_solution_used,
                        "symbolsDiff": item.symbolsDiff,
                        "totalpointscore": item.totalpointscore,
                        "updateremedialafterSubmithw": item.updateremedialafterSubmithw
                    },
                    )

                    if (parseFloat(item.points, 2) < 0) {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.points, 2)
                    }
                    else {
                        ta_totalgrades = parseFloat(ta_totalgrades, 2) - parseFloat(item.points, 2)
                    }

                    // ta_totalgrades = parseFloat(ta_totalgrades, 2) + parseFloat(item.hint_points, 2)
                }
            }
            return tafeedbackData

        })
        // alert(ta_hint_points)
        debugger;
        console.log("ta feedback json - " + JSON.stringify(tafeedbackData))
        let form_data = new FormData();
        form_data.append("challengeId", challengeId)
        form_data.append("ta_user_id", getCurrentUserId())
        form_data.append("user_id", selectedStudent_id)
        form_data.append("boardId", boardId)
        form_data.append("ta_feedback", JSON.stringify(tafeedbackData))
        form_data.append("ta_totalgrades", ta_totalgrades)
        form_data.append("ta_hint_points", ta_hint_points)
        form_data.append("homeworkproblems_id", homeworkproblems_id)
        form_data.append("problem_source", params.problemsource)
        form_data.append("common_feedback", commonFeedback)


        updatestudenthomeworklist(form_data).then(res => {
            if (res.status === 200) {
                toast.success("Updated successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setupdatedtagrade(res.data)
                debugger;
                let changeData = [...visibleRows]
                if (visibleRows[currentIndex1] !== undefined) {
                    let tempArr = changeData[currentIndex1]
                    tempArr.totalgrade = parseFloat(ta_totalgrades, 3)
                    tempArr.common_feedback = commonFeedback
                    changeData[currentIndex1] = tempArr
                    setstudentData(changeData)
                }
                setshowmodel(false)
            }
        }).catch(err => {
            toast.error("Error!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            console.error(err.message)
        })
    }

    const hinticonhandler = () => {
        setHintDivVisible(!hintDivVisible)
    }
    const checkmyworkiconhandler = () => {
        setCheckMyWorkDivVisible(!checkMyWorkDivVisible)
    }
    const showNextStephandler = () => {
        setShowNextStepDivVisible(!showNextStepDivVisible)
    }
    const showSholutionhandler = () => {
        setShowSolutionDivVisible(!showSolutionDivVisible)
    }
    const checkifcorrectversionexists = (correct_version_exists, line_match_number, current_line) => {
        if (correct_version_exists) {
            //return <div className='extra_instructions'>(Note: You have already entered the correct version of this step in line # {line_match_number} 🤔)</div>
            if (line_match_number > current_line) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it with <label className="lineNumber">line #{line_match_number}</label>. Great job adapting and finding the right approach! </div>
                //Note: interim incorrect step. You have already entered the correct version of this step in line # {line_match_number}</div>
                //Great work on noticing the mistake in line 1 and correcting it with the appropriate equation in line 2! 
            }
            else if (line_match_number == -1) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it in your mind as you have progressed to the more advanced step further in your solution. Great job adapting and finding the right approach!</div>
            }
            else {
                return <div className='extra_instructions'>Note: It seems like you've retraced your <label className="lineNumber">line #{current_line}</label>, but you actually had it right the first time in your <label className="lineNumber">line #{line_match_number}</label>. Stick with your initial approach. You're doing great!</div>
            }
            //Let’s assume you know the correct step, since you used the correct version of this step in your line # {line_match_number} in order to arrive at this line 🤔
            // I see that you've attempted this step again, but it looks like you made an error this time. Remember, you already got this step correct earlier. Let's move on to the next step and build upon your previous success!
            // You've returned to step 5, but your initial attempt was actually correct. Trust your instincts and let's keep moving forward to step 6. Keep up the good work!
        }
        return null
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleclosemodal}
                draggable={false}
                popUpSize="medium"
                body=
                {
                    <>
                        {
                            reSubmitMessageShow ? (<div>
                                <span style={{ fontWeight: "bold", fontSize: "18px" }}>Notification: Student Resubmission</span>
                                <span style={{ fontWeight: "bold" }}> A student has resubmitted their homework. Please review and provide feedback on the updated submission.</span>
                            </div>) : null
                        }
                        {
                            noequationfound ? (<div className='correctAnswerContainer'>

                                <div className='answer-container'>
                                    <div className='d-flex align-items-center justify-content-between pb-3'>
                                        <div >
                                            <p className='yrResTxt'>No equations found for - {selectedStudentName}</p>

                                        </div>
                                    </div>
                                    <div className="commonfeedbackclass mt-4">
                                        <div className="popupSectionTitle">Overall feedback by professor</div>
                                        <div className='feedbackBox'>
                                            <span >Feedback</span>
                                            <textarea rows={8} type='text'
                                                value={commonFeedback}
                                                onChange={(e) => handlecommonfeedbackchanged(e)}>
                                            </textarea>

                                        </div>

                                    </div>
                                </div>
                            </div>) : (<>

                                <div className='model-answers'>
                                    {
                                        modalData !== null && modalData !== undefined && modalData.length > 0 ?
                                            modalData[0].result !== undefined ? (
                                                modalData[0].result.map((item, index) => {

                                                    let hint_Data = item.hintdata

                                                    let tafeedback = ""
                                                    if (item.tafeedback !== null && item.tafeedback !== undefined && item.tafeedback !== "" && item.tafeedback !== "null" && item.tafeedback !== 'null' && item.tafeedback !== 'undefined' && item.tafeedback !== '' && item.tafeedback !== "undefined") {
                                                        tafeedback = item.tafeedback.replace(/(<([^>]+)>)/ig, '')
                                                    }

                                                    line_match = item.line_match;

                                                    stepNo += 1
                                                    isLastStepCorrect = item.status === 'correct' ? true : false
                                                    correct_version_exists = item.correct_version_exists ? item.correct_version_exists : false
                                                    if (isLastStepCorrect === false)
                                                        correct_version_exists = item.correct_version_exists ? item.correct_version_exists : false
                                                    if (correct_version_exists) {
                                                        correct_version_line_no = item.correct_version_line_no;
                                                    }

                                                    let lastElement3 = checkifcorrectversionexists(correct_version_exists, correct_version_line_no, line_match)

                                                    let contentdatafinal = []
                                                    // let remedialtext = []
                                                    if (item.remedial_text !== undefined && item.remedial_text !== null && item.remedial_text !== "undefined" && item.remedial_text !== "null") {
                                                        for (let i = 0; i <= item.remedial_text.length; i++) {
                                                            let data = ""
                                                            let data1 = ""
                                                            let data3 = ""
                                                            let data4 = ""

                                                            data = item.remedial_text[i]
                                                            data1 = item.remedial_text2[i]
                                                            data3 = item.remedial_source[i]
                                                            data4 = item.remedial_source_display_name[i]
                                                            if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                                                                data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                data = data.replace(".,", ".")
                                                            }
                                                            if (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '') {
                                                                data1 = data1.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                data1 = data1.replace(".,", ".")
                                                            }
                                                            if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                                                data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                data3 = data3.replace(".,", ".")
                                                            }
                                                            if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                                                                data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                                                data4 = data4.replace(".,", ".")
                                                            }

                                                            let contentdata = (
                                                                <Aux>
                                                                    {
                                                                        (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                                                            <div className="mb-1 mt-3" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '' && data1 !== ",") ?
                                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: data1.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                                                            <a href={data3}
                                                                                target='_blank' rel="noopener noreferrer">
                                                                                <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                                                            : null
                                                                    }

                                                                </Aux>
                                                            );
                                                            contentdatafinal.push(contentdata)


                                                        }
                                                    }
                                                    console.log(tafeedback)
                                                    return (
                                                        Number.isInteger(item.line_match) ? (

                                                            item.status === "correct" ? (
                                                                <>
                                                                    <div className='correctAnswerContainer'>
                                                                        <span className='answer-correct-sign'></span>
                                                                        <div className='answer-container'>
                                                                            <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                                <div>
                                                                                    <p className='yrResTxt'>Your Response</p>
                                                                                    {/* <p>Correct Equation</p> */}
                                                                                </div>
                                                                                {isgraded ?
                                                                                    <div className='pointsContainer'>
                                                                                        <span>Points: </span>
                                                                                        <input name="points" class="form-control bg-yellow" type='text'
                                                                                            onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                            value={item.points}></input>
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                            {
                                                                                item.status === 'correct' ? (
                                                                                    <div className="green">
                                                                                        <CheckCircleIcon style={{ fill: "green" }} />
                                                                                        {<span><span>{correct_step_feedback[ind_correct_step_feedback]} </span>  <span>{correct_step_feedback_ending[ind_correct_step_feedback_ending]}</span></span>}
                                                                                    </div>
                                                                                ) : null
                                                                            }

                                                                            <div className='equationTxt green'>

                                                                            <MathJaxRenderer math={(!isNil(item.mathml) && !isEmpty(item.mathml)) ? item.mathml : item.inputLatex }></MathJaxRenderer>
                                                                            </div>
                                                                            <br />
                                                                            <MathJaxRenderer math={item.explanation}></MathJaxRenderer>
                                                                            <Row className='no-gutters'>
                                                                                <Col sm="9"></Col>

                                                                                <Col sm="3" style={{ textAlign: "end", paddingLeft: "10px" }}>

                                                                                    {
                                                                                        hint_Data !== undefined && hint_Data !== "" && hint_Data.length > 0 ? (
                                                                                            <Tooltip title="Hints Used" placement='top' arrow >
                                                                                                <IconButton onClick={hinticonhandler}>
                                                                                                    <div className={`boardBtnsreview `}                                                >
                                                                                                        <img src={HintIcon} />
                                                                                                    </div>

                                                                                                </IconButton>
                                                                                            </Tooltip>) : null
                                                                                    }

                                                                                    {
                                                                                        item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== null && item.checkmyworkuseddata !== "" ?
                                                                                            (<Tooltip title="Feedback Used" placement='top' arrow>
                                                                                                <IconButton onClick={checkmyworkiconhandler}>
                                                                                                    <div className={`boardBtnsreview`}>
                                                                                                        <img alt='Feedback Used' src={checkWorkIcon} />
                                                                                                    </div>
                                                                                                </IconButton>
                                                                                            </Tooltip>) : null
                                                                                    }
                                                                                    {
                                                                                        item.nextstep_used !== undefined && item.nextstep_used !== null && item.nextstep_used !== "" ?
                                                                                            (<Tooltip title="Hint: Next Step Used" placement='top' arrow>
                                                                                                <IconButton onClick={showNextStephandler}>
                                                                                                    <div className={`boardBtnsreview`}>
                                                                                                        <img alt='Hint: Next Step Used' src={ShowNextstepIcon} />
                                                                                                    </div>
                                                                                                </IconButton>
                                                                                            </Tooltip>) : null
                                                                                    }

                                                                                    {
                                                                                        item.show_solution_used !== undefined && item.show_solution_used !== null && item.show_solution_used !== "" ?
                                                                                            (<Tooltip title="Show Solution Used" placement='top' arrow>
                                                                                                <IconButton onClick={showSholutionhandler}>
                                                                                                    <div className={`boardBtnsreview`}>
                                                                                                        <img alt='Show Solution Used' src={ShowFullSolutionIcon} />
                                                                                                    </div>
                                                                                                </IconButton>
                                                                                            </Tooltip>) : null
                                                                                    }


                                                                                </Col>
                                                                            </Row>
                                                                            <div className='feedbackBox'>
                                                                                <span >Additional feedback by professor</span>
                                                                                <textarea rows={3} type='text'
                                                                                    value={item.tafeedback}
                                                                                    onChange={(e) => handleFeedbackChangeEventCorrect(e, index)}>
                                                                                </textarea>

                                                                            </div>

                                                                            {
                                                                                hintDivVisible ? (
                                                                                    <>
                                                                                        {hint_Data !== undefined && hint_Data !== "" && hint_Data.length > 0 ?
                                                                                            <>
                                                                                                <div className='whiteBox'><div className='popupSectionTitlemediumborder' style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <div className='popupSectionTitlemedium'> Hints Used</div>
                                                                                                    {isgraded ? (
                                                                                                        <div className='pointsContainer'>
                                                                                                            <span>Hint Points: </span>
                                                                                                            <input name="points" class="form-control bg-yellow" type='text'
                                                                                                                onChange={(e) => handleHintPointsChangeEvent(e, index)}
                                                                                                                value={item.hint_points}></input>
                                                                                                        </div>) : null}
                                                                                                </div>
                                                                                                    <div className='tuorporfile-content-card'>
                                                                                                        {
                                                                                                            hint_Data.map((hint) => {

                                                                                                                let displaystring = ""
                                                                                                                if (hint.hint_remedial_resource_display_name !== "None" && hint.hint_remedial_resource_display_name !== null && hint.hint_remedial_resource_display_name !== undefined && hint.hint_remedial_resource_display_name !== "null" && hint.hint_remedial_resource_display_name !== "undefined") {
                                                                                                                    displaystring = hint.hint_remedial_resource_display_name
                                                                                                                }
                                                                                                                else if (hint.hint_remedial_source !== "None" && hint.hint_remedial_source !== null && hint.hint_remedial_source !== undefined && hint.hint_remedial_source !== "null" && hint.hint_remedial_source !== "undefined") {
                                                                                                                    displaystring = hint.hint_remedial_source
                                                                                                                }
                                                                                                                return (
                                                                                                                    <div className='profile-card-text'>
                                                                                                                        <Row>
                                                                                                                            <Col>
                                                                                                                                <div className='modal-content' style={{ marginBottom: '10px', borderBottom: '1px solid lightGray' }}>
                                                                                                                                    <p className='mb-2' dangerouslySetInnerHTML={{ __html: hint.hint_text }} />

                                                                                                                                    <a href={hint.hint_remedial_source} target='_blank' rel="noopener noreferrer">
                                                                                                                                        <p className='mb-2' dangerouslySetInnerHTML={{ __html: displaystring }} />
                                                                                                                                    </a>

                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }


                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : <div className=''></div>
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }

                                                                            {
                                                                                checkMyWorkDivVisible ? (
                                                                                    <>
                                                                                        {
                                                                                            item.checkmyworkuseddata !== undefined && item.checkmyworkuseddata !== "" && item.checkmyworkuseddata !== "[]" && item.checkmyworkuseddata.length > 0 ?

                                                                                                (
                                                                                                    <>
                                                                                                        <div style={{ maxWidth: "700px" }}>
                                                                                                            <div className='popupSectionTitlemedium'> Feedback Used</div>
                                                                                                            <CheckMyworkResultC checkmyworkuseddata={item.checkmyworkuseddata}></CheckMyworkResultC>
                                                                                                        </div>
                                                                                                    </>

                                                                                                ) : null

                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) :
                                                                <>
                                                                    <div className='incorrectAnswerContainer ' >
                                                                        <span className='answer-incorrect-sign'></span>
                                                                        <div className='answer-container'>
                                                                            <div className='d-flex align-items-center justify-content-between pb-3'>
                                                                                <div >
                                                                                    <p className='yrResTxt'>Your Response</p>
                                                                                    {/* <p>In-Correct Equation</p> */}
                                                                                </div>
                                                                                {isgraded ? (
                                                                                    <div className='pointsContainer'>
                                                                                        <span>Points: </span>
                                                                                        <input name="points" class="form-control bg-yellow" type='text'
                                                                                            onChange={(e) => handlePointsChangeEvent(e, index)}
                                                                                            value={item.points}></input>
                                                                                    </div>) : null
                                                                                }

                                                                            </div>

                                                                            {lastElement3}
                                                                            <div className='equationTxt red'>


                                                                            <MathJaxRenderer math={(!isNil(item.mathml) && !isEmpty(item.mathml)) ? item.mathml : item.inputLatex }></MathJaxRenderer>

                                                                            </div>

                                                                            <div className='recommendedBox'>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                    <span>Recommended Resources</span>
                                                                                    {
                                                                                        item.updateremedialafterSubmithw ? (
                                                                                            <div style={{ color: "#96c5f3" }}>Updated</div>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                                {contentdatafinal}
                                                                            </div>

                                                                            <div className='feedbackBox'>
                                                                                <span >Additional feedback by professor</span>
                                                                                <textarea rows={3} type='text'
                                                                                    value={tafeedback}
                                                                                    onChange={(e) => handleFeedbackChangeEventIncorrect(e, index)}>
                                                                                </textarea>

                                                                            </div>



                                                                            {item.hintdata !== undefined && item.hintdata !== "" && item.hintdata.length > 0 && JSON.stringify(item.hintdata) !== "{}" ?
                                                                                <><div className='whiteBox'>
                                                                                    <div className='popupSectionTitlemediumborder' style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                        <div className='popupSectionTitlemedium'> Hints Used</div>
                                                                                        {isgraded ? (
                                                                                            <div className='pointsContainer'>
                                                                                                <span>Hint Points: </span>
                                                                                                <input name="points" class="form-control bg-yellow" type='text'
                                                                                                    onChange={(e) => handleHintPointsChangeEvent(e, index)}
                                                                                                    value={item.ta_hint_points}></input>
                                                                                            </div>) : null}
                                                                                    </div>
                                                                                    <div className='tuorporfile-content-card'>
                                                                                        {item.hintdata.map((hint) => {
                                                                                            let displaystring = ""
                                                                                            if (hint.hint_remedial_resource_display_name !== "None" && hint.hint_remedial_resource_display_name !== null && hint.hint_remedial_resource_display_name !== undefined && hint.hint_remedial_resource_display_name !== "null" && hint.hint_remedial_resource_display_name !== "undefined") {
                                                                                                displaystring = hint.hint_remedial_resource_display_name
                                                                                            }
                                                                                            else if (hint.hint_remedial_source !== "None" && hint.hint_remedial_source !== null && hint.hint_remedial_source !== undefined && hint.hint_remedial_source !== "null" && hint.hint_remedial_source !== "undefined") {
                                                                                                displaystring = hint.hint_remedial_source
                                                                                            }
                                                                                            return (
                                                                                                <div className='profile-card-text'>
                                                                                                    <Row>
                                                                                                        <Col>
                                                                                                            <div className='modal-content' style={{ marginBottom: '10px', borderBottom: '1px solid lightGray' }}>
                                                                                                                <p className='mb-2' dangerouslySetInnerHTML={{ __html: hint.hint_text }} />


                                                                                                                <a href={hint.hint_remedial_source} target='_blank' rel="noopener noreferrer">
                                                                                                                    <p className='mb-2' dangerouslySetInnerHTML={{ __html: displaystring }} />
                                                                                                                </a>

                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                </>
                                                                                : <div className=''></div>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </>


                                                        ) : null

                                                    )

                                                })
                                            ) : null
                                            : "No homework details found for this challenge !"
                                    }
                                </div>
                                <div className="commonfeedbackclass mt-4">
                                    <div className="popupSectionTitle">Overall feedback by professor</div>
                                    <div className='feedbackBox'>
                                        <span >Feedback</span>
                                        <textarea rows={8} type='text'
                                            value={commonFeedback}
                                            onChange={(e) => handlecommonfeedbackchanged(e)}>
                                        </textarea>

                                    </div>

                                </div>

                                {
                                    flowUIJson !== undefined && flowUIJson !== "" && flowUIJson !== null && flowUIJson !== "[]" ?
                                        <div className="solutionGraphCotainerStudent mt-4">
                                            <div className="popupSectionTitle">Solution Graph</div>
                                            <div className="legendContainer">
                                                <div className="legendItemContainer">
                                                    <div className="correct"></div>
                                                    <span>Expected Step</span>
                                                </div>
                                                <div className="legendItemContainer">
                                                    <div className="optional"></div>
                                                    <span>Intermediate Step</span>
                                                </div>
                                            </div>
                                            <Flow readOnly={true} uiJson={flowUIJson} hasCheckMyWOrk={false} ></Flow>
                                        </div>
                                        : null
                                }
                            </>)
                        }


                    </>}
                handleClose={handleclosemodal}
                title=
                {

                    isgraded ?
                        (
                            <>
                                {modalTitle}
                                <span style={{ marginLeft: "10px", fontSize: "20px" }}>
                                    (Total Points: {userGetPoints}/{userTotalPointScore} )</span></>
                        ) : <>{modalTitle}</>

                }
                showButton={false}
                customButton={
                    modalData !== null && modalData !== undefined ?
                        <div className='modal-button text-center'>
                            <Button className="yellow-btn" clicked={() => handleUpdate()}> Update </Button>
                            <span style={{ marginLeft: "10px" }}>
                                <Button className="grey-btn" clicked={handleclosemodal}> Cancel </Button></span>
                        </div>
                        : <div className='modal-button text-center'>
                            <Button className="yellow-btn" clicked={() => handleUpdateonlyFeedback()}> Update </Button>
                            <span style={{ marginLeft: "10px" }}>
                                <Button className="grey-btn" clicked={handleclosemodal}> Ok </Button></span>
                        </div>
                }
            >
            </Modal >

            <div class="container-fluid">
                <div className='container bg-white-with-shadow my-3 my-md-5'>
                    <div className=''>
                        <h5 className='breadcrumbH5'>
                            <Link
                                style={{ textTransform: "none", paddingRight: "2px" }}
                                to={{
                                    pathname: "/homeworkproblems"
                                }}>
                                Homework
                            </Link>
                            <> <ArrowForwardIcon /> Challenge: {challengeTitle}</>
                        </h5>
                    </div>
                    <div className=''>
                        <Box sx={{ width: '100%', mt: 3 }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>

                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        // rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {
                                                visibleRows !== undefined && visibleRows !== null && visibleRows.length > 0 ?

                                                    (visibleRows.map((row, index) => {
                                                        const isItemSelected = isSelected(row.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;



                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                        <Avatar
                                                                            className={classes.avatar}
                                                                            src={imgURL + row.profile_pic}>
                                                                        </Avatar>
                                                                        <div className='mt-2'><button className='buttonStudent' onClick={() => openStudentLinkPopUp(row.boardId, row.student_id, row.student_name, row.hintdata, row.isgraded, index, row.totalgrade, row.totalpointscore)}>{row.student_name}</button></div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="left">{row.isgraded ? row.totalgrade + "/" + row.totalpointscore : "Non Graded"}</TableCell>
                                                                <TableCell align="left">{row.common_feedback}</TableCell>


                                                            </TableRow>
                                                        );
                                                    })) : (<div style={{ padding: "20px" }}>No Student Found!</div>)

                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Stack spacing={2}>



                                    <Pagination
                                        sx={{
                                            "& .MuiTablePagination-selectLabel": {
                                                marginBottom: "0px !important"
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                marginBottom: "0px !important"
                                            }
                                        }}
                                        variant="outlined"
                                        shape="rounded"
                                        color="primary"
                                        count={totalPages}
                                        page={page}
                                        onChange={handleChangePage}
                                    />
                                </Stack>

                            </Paper>

                        </Box>
                    </div>
                </div>
            </div>
            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    )
}

export default StudentList 