import React, { useEffect, useState } from 'react';
import { clone, isNil } from 'lodash';
import './StepByStepComponent.scss';

import Collapse from 'react-bootstrap/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { isEmpty } from 'lodash';
import SingleStepComponent from './SingleStep';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import { CardMedia } from "@material-ui/core";
import { createAwwappBoard, fetchChallenges, imgURL } from '../../common/API';
import { makeStyles } from "@material-ui/core/styles";
import { getCurrentUserId, getCurrentUserRole, Problem_source } from '../../common/Functions';
import HelperButtonComponent from './HelperButtonComponent';

import './StepByStepComponent.scss';
import AnyProblem from '../../components/CurriculumContent/AnyProblem/AnyProblem';
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizePanelHandle from '../../components/Common/ResizePanelHandle/ResizePanelHandle';
import "../../components/Common/ResizePanelHandle/style.css"


const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%',
        marginBottom: '10px'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    }
}));


export default function StepByStepComponent({ assignmentId, problemSource, challengeId, boardId, challengeMapId, teacher }) {
    const [equations, setEquations] = useState([{ id: 1, expanded: true, feedbackText: '', isCorrect: false }]);
    const [maxIdCount, setMaxIdCount] = useState(1);
    const [problemStatement, setProblemStatement] = useState("")
    const [problemImage, setProblemImage] = useState("")
    const [stepEquations, setStepEquations] = useState([{ index: 1, mathml: '' }])
    const [hasAiTutor, setHasAiTutor] = useState(false);
    const [toggleProblemStatement, setToggleProblemStatement] = useState(true)
    const [board, setBoard] = useState(null);
    const [questionType, setQuestionType] = useState("")
    const [loader, setLoader] = useState(false)
    const classes = newStyles();

    useEffect(() => {
        fetchChallengeDetail();
        getBoardId();
    }, [])

    const getBoardId = () => {
        let params = new URLSearchParams();
        params.append('challengeId', challengeId.slice(1, challengeId.length));
        params.append('domain', window.location.origin)
        params.append('question_type', 'frq')
        params.append('problem_source', problemSource)
        params.append('assignment_id', assignmentId !== 0 ? assignmentId : 0);

        params.append('userId', getCurrentUserId())
        if (!teacher) {
            createAwwappBoard(params).then(res => {
                setBoard(res.data.boardId)
            }).catch(err => {
                console.error(err.message);
            });
        }
        else {
            let searchParams = new URLSearchParams(this.props.location.search);
            if (searchParams.get('boardId')) {
                setBoard(searchParams.get('boardId'));
                // this.props.setBoardId(searchParams.get('boardId'))
                // this.setState({
                //   boardId: searchParams.get('boardId'),
                //   teacherStudentId: searchParams.get('student'),
                //   challengeMapId: searchParams.get('challengeMapId')
                // })
                // this.firstOpen = false;
                // if (this.state.challengeType === CONTENT_TYPE.EQUATION) {
                //   this.initializeBoard();
                // }
            }
        }
    }
    const updateStepEquation = (itemIndex, mathml) => {
        setStepEquations((prevEquations) => {
            return prevEquations.map((item) => {
                if (itemIndex === item.index) {
                    return {
                        ...item,
                        mathml: mathml
                    };
                }
                return item;
            });
        });

    }

    const fetchChallengeDetail = () => {
        const reqData = { params: { 'challengeId': challengeId, 'user_id': getCurrentUserId(), 'user_role': getCurrentUserRole() } }
        fetchChallenges(reqData)
            .then(res => {
                setProblemStatement(res.data.question)
                setQuestionType(res.data.type)
                setProblemImage(res.data.question_image)
            })
    }

    const expandCollapseEquation = (selectedStep, hasExpand = false) => {
        setEquations((prevEquations) => {
            if (selectedStep !== -1) {
                return prevEquations.map((item) => {
                    return {
                        ...item,
                        expanded: item.id === selectedStep ? hasExpand : item.expanded
                    };

                });
            } else {
                return prevEquations.map((item) => {
                    return {
                        ...item,
                        expanded: hasExpand
                    };

                });
            }

        });
    }

    const deleteEquation = (selectedStep) => {
        let tempArr = equations.filter(item => item.id !== selectedStep);
        setEquations(tempArr)
    }

    const addNewStepHandler = (e, index) => {
        let tempArr = equations.map(item => ({
            ...item,
            expanded: false
        }));

        if (index !== null) {
            setEquations([...tempArr.slice(0, index), { id: maxIdCount + 1, expanded: true, feedbackText: '', isCorrect: false }, ...tempArr.slice(index, equations.length)]);
        }
        else {
            setEquations([...tempArr, { id: maxIdCount + 1, expanded: true, feedbackText: '', isCorrect: false }]);
        }

        stepEquations.push({ index: maxIdCount + 1, mathml: '' })
        setMaxIdCount(maxIdCount + 1);
    }

    const setFeedback = (stepsFeedback) => {
        setEquations((prevEquations) => {
            return prevEquations.map((item) => {
                const obj = stepsFeedback.filter(val => val.line_match === item.id);
                if (!isNil(obj) && !isEmpty(obj)) {
                    return {
                        ...item,
                        feedbackText: obj[0].explanation,
                        isCorrect: obj[0].status === 'incorrect' || obj[0].status === 'unrecognized' ? false : true,
                        expanded: false
                    };

                }

                return item;
            });
        });
    }

    const handleLoader = (isLoading) => {
        setLoader(isLoading)
    }

    const askAITutorBtnClick = () => {
        setHasAiTutor(true)
    }

    const closeAskAITutor = () => {
        setHasAiTutor(false)
    }

    return (
        <>
            <div className='step ContainerResizepanel'>
                <div className="BottomRow">
                    <PanelGroup autoSaveId="conditional" direction="horizontal">
                        <Panel
                            className="PanelResizePanel"
                            // collapsible={true}
                            defaultSize={25}
                            minSize={15}
                            order={1}
                            id="left"
                        >
                            <div className="PanelContent">

                                {isEmpty(problemStatement) ?
                                    null
                                    :
                                    <div style={{ width: "95%" }} className='statementContainer'>
                                        <h5>Problem Statement:</h5>
                                        <div className={classes.cardMedia}>
                                            <div className={classes.librarPylaceholder}>
                                                <CardMedia
                                                    component="img"
                                                    image={imgURL + problemImage}
                                                    alt="Problem Image"
                                                    className={classes.photoOutlined}
                                                />
                                            </div>
                                        </div>
                                        <MathJaxRenderer fromCKEditor={true} math={problemStatement} />
                                    </div>
                                }
                            </div>
                        </Panel>
                        <ResizePanelHandle />
                        <Panel
                            className="PanelResizePanel"
                            defaultSize={50}
                            minSize={40}
                            // collapsible={true}
                            order={2}
                            id="center">
                            <div className="PanelContent">

                                {/* <div className='showHideProblemStatementContainer'>
                                    {!toggleProblemStatement ? <button type="button" class="btn btn-primary" onClick={() => setToggleProblemStatement(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"></path>
                                        </svg>

                                    </button> : <button type="button" class="btn btn-primary" onClick={() => setToggleProblemStatement(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"></path>
                                        </svg>
                                    </button>}

                                </div> */}
                                <div id='stepByStepContainer' className='stepByStepContainer' style={{ marginTop: toggleProblemStatement ? '0' : '20px' }}>
                                    <div className='expandCollapseLinks'>
                                        <span onClick={() => expandCollapseEquation(-1, true)}>Expand all equations</span>
                                        <span onClick={() => expandCollapseEquation(-1, false)}>Collapse all equations</span>
                                    </div>
                                    <div className='stepByStepContainerSteps'>
                                        {
                                            equations.map((equation, index) => {
                                                return (
                                                    <div key={equation.id}>

                                                        <SingleStepComponent lineNumber={index + 1}
                                                            itemId={equation.id}
                                                            expanded={equation.expanded}
                                                            expandCollapseEquationHandler={expandCollapseEquation}
                                                            deleteEquation={deleteEquation}
                                                            feedbackText={equation.feedbackText}
                                                            isCorrect={equation.isCorrect}
                                                            updateEquationMathml={updateStepEquation}
                                                        >
                                                        </SingleStepComponent>
                                                        <div className='hintContainer'>
                                                            <span onClick={() => setHasAiTutor(!hasAiTutor)}>Hint</span>
                                                            <span>Show next step</span>
                                                        </div>
                                                        <span className='nextStepLinkButton' onClick={(e) => addNewStepHandler(e, index + 1)}>
                                                            <AddCircleIcon />Click to add equation</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='helpButtons'>
                                        <HelperButtonComponent
                                            stepEquations={stepEquations}
                                            updateFeedback={setFeedback}
                                            boardId={board}
                                            challengeMapId={challengeMapId}
                                            problemSource={problemSource}
                                            assignmentId={assignmentId}
                                            questionType={questionType}
                                            handleLoader={handleLoader}
                                            askAITutorHandleClick={askAITutorBtnClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        {
                            hasAiTutor ? (<>
                                <ResizePanelHandle />
                                <Panel
                                    className="PanelResizePanel"
                                    // collapsible={true}
                                    defaultSize={25}
                                    minSize={15}
                                    order={3}
                                    id="right"
                                >
                                    <div className="PanelContent"><div style={{ width: '100%' }}>
                                        <div style={{ height: '750px', background: 'red' }}>
                                            <AnyProblem
                                                //  handleremovequestions={this.handleremovequestions}
                                                //equationClass={this.state.equationClass}
                                                //askAIBgColor={this.state.askAIBgColor}
                                                isInline={false}
                                                //question={this.state.askAIquestion}
                                                onClose={closeAskAITutor}
                                                challengeId={challengeId}
                                                boardId={boardId}
                                                //equationClick={this.state.equationClick}
                                                //handleequationclick={this.handleequationclick}
                                                problem_source={problemSource}
                                                //hinTextforAIAskTutorQuestion={this.props.hinTextforAIAskTutorQuestion}
                                                //forHintAITutor={this.props.forHintAITutor}
                                                //handleremoveAskAIBgcolor={this.handleremoveAskAIBgcolor}
                                                type={teacher}>

                                            </AnyProblem>
                                        </div>
                                    </div></div>
                                </Panel>
                            </>) : null
                        }
                    </PanelGroup>
                </div>

            </div>

            {
                loader && (
                    <CircularProgress
                        size={24}
                        style={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }
        </>
    )
}