import React, { useEffect, useRef, useState } from 'react'


import Collapse from 'react-bootstrap/Collapse';
import { isNil, update } from 'lodash';

import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import CalculateIcon from '@mui/icons-material/Calculate';
import DrawIcon from '@mui/icons-material/Draw';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import './SingleStep.scss'
import { detectDevice, TABLET } from '../../common/Functions';


const KEYBOARD = 'keyboard';
const WHITEBOARD = 'whiteboard';

export default function SingleStepComponent({ lineNumber, itemId, expanded, feedbackToggle, expandCollapseEquationHandler, expandCollapseFeedback, deleteEquation, feedbackText, isCorrect, updateEquationMathml, fullyFeatured = true, inputLatex = '', status = '' }) {
    const [latex, setLatex] = useState(inputLatex);
    const [inputType, setInputType] = useState(fullyFeatured ? WHITEBOARD : KEYBOARD)
    const [wirisInterval, setWirisInterval] = useState(null);
    let editor = useRef(null);

    useEffect(() => {
        if (latex !== '' && fullyFeatured) {
            // updateEquationMathml(itemId, latex)
        }
    }, [latex])

    useEffect(() => {
        if (!isNil(document.getElementById(`${itemId}-editorContainer`)) && !isNil(window.com.wiris.jsEditor)) {
            editor.current = window.com.wiris.jsEditor.JsEditor.newInstance({ 'language': 'en' });
            editor.current.insertInto(document.getElementById(`${itemId}-editorContainer`));
            //editor.current.setMathML(latex);
        }

    }, [])

    useEffect(() => {
        if (expanded && !isNil(editor.current)) {
            clearInterval(wirisInterval)
            let previousContent = '';
            setWirisInterval(setInterval(() => {
                const currentContent = editor.current.getMathML();

                if (currentContent !== previousContent) {
                    // Content has changed, process the new content
                    previousContent = currentContent;
                    getWirisOutput();
                }
            }, 1000)); // Check every 1 second
        } else {
            clearInterval(wirisInterval)
        }


    }, [expanded])

    const getWirisOutput = () => {
        const mathMLContent = editor.current.getMathML();
        if (mathMLContent !== `<math xmlns="http://www.w3.org/1998/Math/MathML"/>`) {
            ///setLatex(mathMLContent)
        }


    }
    const expandCollapseInputBox = (input) => {
        if (!isNil(input)) {
            setInputType(input);
            if (input === KEYBOARD && latex != '' && !isNil(editor.current)) {
                editor.current.setMathML(latex);
            }
            expandCollapseEquationHandler(itemId, true);
        } else {
            expandCollapseEquationHandler(itemId, !expanded);

        }
    }

    const cancelHandler = () => {
        //setLatex(latex)
        expandCollapseEquationHandler(itemId, !expanded)
    }
    const deleteEquationHandler = () => {
        deleteEquation(itemId)
    }

    const saveEquation = () => {
        setLatex(editor.current.getMathML())
        updateEquationMathml(itemId, editor.current.getMathML())
        expandCollapseEquationHandler(itemId, !expanded);
    }

    const mathPixLatexHandler = (response) => {
        if (!isNil(response.data)) {
            response.data.forEach(element => {
                if (element.type === 'mathml') {
                    setLatex(element.value.replace(/\n/g, " "))
                }
            });
        }

    }


    return (


        <>
            <div className='stepContainer' style={{ backgroundColor: feedbackText !== '' ? isCorrect ? '#EBFBEB' : '#FEE9E8' : '#fff' }}>
                <div>
                    {fullyFeatured ?
                        <div className='lineInfoContainer'>
                            <span className='lineLabel'>Line: {lineNumber}</span>
                            <span className='expandCollapse' onClick={() => expandCollapseInputBox(null)}>{expanded ? 'Collapse' : 'Expand'}</span>
                        </div> : null}

                    <Collapse in={expanded}>
                        <div className='inputOptionsContainer'>
                            {/* {fullyFeatured ? <div id="inputBox" className='inputContainer' style={{ visibility: inputType === WHITEBOARD ? 'visible' : 'hidden', height: inputType === WHITEBOARD ? '300px' : '0' }} >
                            <Whiteboard latexHandler={mathPixLatexHandler}></Whiteboard>
                        </div> : null} */}

                            <div id={`${itemId}-editorContainer`} className='inputContainer' style={{ visibility: inputType === KEYBOARD ? 'visible' : 'hidden', height: inputType === KEYBOARD ? '300px' : '0', background: 'white' }} ></div>
                            {!fullyFeatured ? <div className='wirisSaveOptions'>
                                <span className='linkText' onClick={() => saveEquation()}>Update Equation</span>
                                <span onClick={() => cancelHandler()}>Cancel</span>
                            </div> : null}

                        </div>

                    </Collapse>
                </div>



                <div className='equationBox'>
                    {fullyFeatured ? <>
                        <button onClick={() => expandCollapseInputBox(WHITEBOARD)}><DrawIcon /></button>
                        <button onClick={() => expandCollapseInputBox(KEYBOARD)}><CalculateIcon /></button></> :
                        <div className='lineInfoContainer' style={{ borderBottom: 'none' }}>
                            <span className='lineLabel' style={{ paddingLeft: '0px', width: '60px' }}>Line: {lineNumber}</span>

                        </div>
                    }

                    <div className='equationContainer' >
                        <MathJaxRenderer math={latex}></MathJaxRenderer>
                    </div>
                    {!fullyFeatured && detectDevice() !== TABLET ? <span className='linkText' onClick={() => expandCollapseInputBox(KEYBOARD)}>Edit</span> : null}
                    {!fullyFeatured && detectDevice() !== TABLET ? <span className='linkText' onClick={() => deleteEquationHandler()}>Delete</span> : null}
                </div>
                {
                    !fullyFeatured && detectDevice() === TABLET ?
                        <div className='editDeleteButtonContainer'>
                            {!fullyFeatured ? <span className='linkText' onClick={() => expandCollapseInputBox(KEYBOARD)}>Edit</span> : null}
                            <span className='linkText' onClick={() => deleteEquationHandler()}>Delete</span>
                        </div> : null
                }

                {
                    feedbackText !== '' ?
                        <div className='feedbackOptionContainer' onClick={() => expandCollapseFeedback(itemId, !feedbackToggle)}>
                            <div className='feedbackToggleButtonContainer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10"></path>
                                </svg>
                                <span>{feedbackToggle ? 'Hide details' : 'Show details'}</span>
                            </div>
                            <div className='equationStatusContainer'>
                                <>{isCorrect ? <CheckCircleIcon style={{ fill: "green" }} />
                                    : <CancelIcon style={{ fill: "red" }} />}

                                </>
                                <span style={{ color: isCorrect ? 'green' : 'red' }}>{isCorrect ? 'Correct' : 'Incorrect'}</span></div>

                        </div>

                        : null
                }
                <Collapse in={feedbackText !== '' && feedbackToggle}>
                    <div style={{ width: '100%' }}>
                        {/* {status !== 'correct' ? <span><strong>{incorrect_step_feedback[Math.floor(Math.random() * 2)]}</strong></span> : null}
                    {status === 'correct' ? <span><strong>{correct_step_feedback[Math.floor(Math.random() * 4)]}</strong></span> : null}
                    {status === 'unrecognized' ? <><br /><span className="aiAnswerNote">Note: Feedback under review to ensure its quality.</span></> : null} */}
                        <div>{feedbackText}</div>
                        {/* <MathJaxRenderer math={feedbackText}></MathJaxRenderer> */}
                    </div>
                </Collapse>





            </div>
        </>
    )
}