import React, { Component } from 'react'
import './TeacherUI.scss'
import { Container, Row } from 'react-bootstrap';
import { getOnGoingConferences, resetKnowledgeBaseData, clearOnGoingConference } from '../../../common/API';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { getTryThisPinsEnabledFlag, getCurrentUserId, Call_Type } from '../../../common/Functions';
import parse from 'html-react-parser';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#a4a4a4",
        color: theme.palette.common.white,
        padding: "10px",
        fontSize: 16,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "7px"
    },
}));


const StyledTableCellbody = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
        //color: theme.palette.common.white,
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "7px"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default class TeacherUI extends Component {

    state = {
        conferences: [],
        isinfoshow: true,
        pageNum: 1,
        rowsPerPage: 5,
        totalPages: 1,
        startPage: 0,
    }

    fetchData = () => {
        // const reqData = { params: { 'tutor_user_id': getCurrentUserId() } }
        // getOnGoingConferences(reqData)
        //     .then(res => {
        //         this.setState({ conferences: res.data.conferences.filter((a) => a.student_active === true) })
        //         this.setState({ totalPages: Math.ceil(this.state.conferences.length / this.state.rowsPerPage) })
        //     })
        //     .catch(err => {
        //         console.error(err.message);
        //     })
    }
    componentWillMount() {
        this.fetchData()
        setInterval(this.fetchData, 2000);
    }

    clearSessionList = (e) => {
        e.preventDefault()
        clearOnGoingConference()
            .then(res => {
                console.log(res.data);
                toast('Refreshed Session List successfully.', {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
            .catch(err => {
                console.error(err.message);
                toast('We are facing some technical issue.', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }


    clearKnowledgeBaseData = (e) => {
        e.preventDefault()
        resetKnowledgeBaseData({})
            .then(res => {
                console.log(res.data);
                toast('Data cleared successfully.', {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
            .catch(err => {
                console.error(err.message);
                toast('We are facing some technical issue.', {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }

    handleChangePageConference = (event, page) => {
        this.setState({ startPage: page - 1, pageNum: page })
    };

    render() {
        return (
            <div>
                <div className='teacher-ui'>
                    {/* <h3 className='title my-3'>Ongoing conferences will be listed below...</h3> */}
                    <div style={{ marginTop: '30px', width: '-webkit-fill-available' }} className=''>
                        <h5 className="mb-0">Tutoring Sessions</h5>
                        <p className="text-secondary">Find all your active or completed tutoring requests </p>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Student Name</StyledTableCell>
                                        <StyledTableCell>Challenge</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.conferences !== undefined && this.state.conferences !== null && this.state.conferences.length > 0 ? (
                                            this.state.conferences
                                                .slice(this.state.startPage * this.state.rowsPerPage, (this.state.startPage * this.state.rowsPerPage) + this.state.rowsPerPage)
                                                .map((conf, index) => {
                                                    return (
                                                        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                            <StyledTableCellbody component="th" scope="row">
                                                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                                    <div className='mt-2'>{conf.student}</div>
                                                                </div>
                                                            </StyledTableCellbody>
                                                            <StyledTableCellbody>
                                                                {conf.call_type === Call_Type.Challenge ? "Challenge" : "Content"} : {conf.name}
                                                            </StyledTableCellbody>
                                                            <StyledTableCellbody>
                                                                <p className={`availability active m-0`}><span className='spanOnline'> ONLINE</span>
                                                                    <a key={index} href={conf.student_active ? conf.boardUrl : null} className={`availability ${conf.student_active ? 'active' : 'inactive'}`}
                                                                    > Join Session<ArrowRightIcon /></a> </p>
                                                            </StyledTableCellbody>
                                                        </StyledTableRow>
                                                    )
                                                }
                                                )
                                        ) : (<div style={{ padding: "20px" }}>No Conference Found!</div>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            sx={{
                                "& .MuiTablePagination-selectLabel": {
                                    marginBottom: "0px !important"
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    marginBottom: "0px !important"
                                }
                            }}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            count={this.state.totalPages}
                            page={this.state.pageNum}
                            onChange={this.handleChangePageConference}
                        />
                    </div>

                    {getTryThisPinsEnabledFlag() ? <a href="" className='mt-2' onClick={this.clearKnowledgeBaseData}>Refresh KnowledgeBase</a> : null}
                </div>
                <Row>{getTryThisPinsEnabledFlag() ? <a href="" className='mt-2' onClick={this.clearSessionList}>Refresh Session List</a> : null}</Row>
                <ToastContainer />
            </div>
        )
    }
}
