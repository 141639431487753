import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import { Row, Col } from 'react-bootstrap';
import './AssignmentDetails.scss'
import {
    imgURL, getAssignmentDetailsById, fetchChallengeHintnonfrq, checkChallengeAnswer
    , getEntireSolution, saveuserassignment,
    userassigmentautosubmit
    , saveAssignmentUserTimer,
    fetchSubSectionContent,
    dashboardnodechallenges,
    is_pt_submitted
    , CalculatePTMCQGrade
    , update_nonfrqReview,
    generateSVGFromMathML
} from '../../common/API';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import {
    halfTestMarksLimit, timeLapseCounter,
    Institute, fullPTTimeInString, halfPTTimeInString, testInstructionText
} from '../../common/Constants';
import {
    getCurrentUserId, Problem_source, showFullApp, getIsPTTeacher, getInstituteId, convertDurationToSeonds,
    getTimeDifference, convertSecondsToHMS, formatDate,
    getCurrentUserRole,
    QuestionType,
    assignment_Type
} from '../../common/Functions';
import parse from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import Aux from "../../hoc/Wrapper";
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import ProgressSidebar from '../../components/Answerboard/ProgressSidebar/ProgressSidebar';
import HomeworkReviewDetailNonFRQ from '../../components/Answerboard/HomeworkReviewDetailNonFRQ';
import HomeworkReviewDetail from '../../components/Answerboard/HomeworkReviewDetail';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ArrowBack } from '@material-ui/icons';
import DialogContentText from '@mui/material/DialogContentText';
import { isEmpty, isNil } from 'lodash';
import Drawer from '@mui/material/Drawer';
import AnyProblem from '../../components/CurriculumContent/AnyProblem/AnyProblem';
import scrollUp from "../../assets/images/scroll-vertical-1.svg";
import legendCircle from "../../assets/images/legend-circle.svg";
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import MuiButton from '@mui/material/Button';
import FeedbackC from '../../components/Feedback/FeedbackC';
import MuiIconButton from '@mui/material/IconButton';
import MuiCloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import LogoSign from "../../assets/images/logo-sign.svg";
import Paper from '@mui/material/Paper';
import whiteBoardVideo from "../../assets/videos/Quiz_HelpCenter.mp4";
import MCQAssignmentDetails from '../../components/Assignment/MCQAssignmentDetails';
import MSQAssignmentDetails from '../../components/Assignment/MSQAssignmentDetails';
import Cookies from 'universal-cookie';
import ExpandIcon from '../../assets/images/icon-expand.svg';
import AskAITutIcon from '../../assets/images/ai-tutor.png';
import HomeCurriculumContentCard from '../../components/CurriculumContent/CurriculumContentCard/HomeCurriculumContentCard';
import ChallengeCard from '../../container/Homepage/ChallengeCard';
import GraphComponent from '../../container/Homepage/Graph/GraphComponent';
import FRQAssignmentDetails from '../../components/Assignment/FRQAssignmentDetails';
import Flow from '../../components/SolutionGraph/flow';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';


const newStyles = makeStyles((theme) => ({
    root: {},
    cardDetails: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        display: "flex"
    },
    librarPylaceholder: {
        height: "240px",
        alignItems: 'stretch',
        position: 'relative',
        width: '100%'
    },
    cardMedia: {
        width: "390px",
        borderRadius: "8px",
        overflow: "hidden",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "0px",
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "24px"
    },
    photoOutlined: {
        width: "100%",
        height: "210px",
        padding: "5px",
        objectFit: "contain",
        borderRadius: "12px",
    },
    radioGroup: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex"
    },
    formControlClass: {
        justifyContent: "flex-start",
        alignItems: "center",
        display: "inline-flex"
    },
    radioGrp: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "25px"
    },
    formControlLabels: {
        color: "#1B262F",
        fontFamily: "Average Sans",
        fontWeight: "40",
        lineHeight: "27px",
        letterSpacing: "0.15px",
        wordWrap: "break-word",
        bottom: "24px",
        position: "relative"
    },
    btnSubmit: {
        height: '100%',
        paddingLeft: "22px",
        paddingRight: "22px",
        paddingTop: "8px",
        paddingBottom: "8px",
        background: '#4353B0',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        borderRadius: "100px",
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        color: "#fff",
        '&:hover': {
            background: '#4353B0 !important',
        },
    },
    modal_title: {
        "& .modal-title": {
            width: "100% !important"
        }
    }
}));


let modalBody = null;
let modalTitle = null;
let showButton = false;
let customButton = null;
let draggable = false;
let contentModalBody = null;
let contentModalTitle = null;
let showContentModalButton = false;
let attemptedQueList = [];
let isTimeLapse = false
let epochValue = 0;
let isExamSubmitted = false;
let initialTime = null;

const AssignmentDetails = (props) => {

    const classes = newStyles()
    const [loading, setloading] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [showContentModal, setShowContentModal] = useState(false)
    const [assignmentDetailList, setassignmentDetailList] = useState([])
    const [mcqAssignmentList, setmcqAssignmentList] = useState([])
    const [msqAssignmentList, setmsqAssignmentList] = useState([])
    const [frqAssignmentList, setfrqAssignmentList] = useState([])
    const [isTimedAsg, setisTimedAsg] = useState(false)
    const [isGuidedAsg, setisGuidedAsg] = useState(false)
    const [isGradedAsg, setisGradedAsg] = useState(false)
    const [assignmentTrials, setassignmentTrials] = useState(0)
    const [studentName, setStudentName] = useState("")
    const [isTutorReview, setIsTutorReview] = useState(false);
    const [student_Id, setStudent_Id] = useState(0);
    const [isViewByProfessor, setisViewByProfessor] = useState(false)
    const [isReviewAsg, setisReviewAsg] = useState(false)
    const [isAsgResubmit, setisAsgResubmit] = useState(false)
    const [generalInstructions, setgeneralInstructions] = useState("")
    const [attemptedQueCount, setattemptedQueCount] = useState(0)
    const [isPtSubmitted, setisPtSubmitted] = useState(false);
    const [dialogViewInstructionsModalopen, setdialogViewInstructionsModalopen] = useState(false)
    const [dialogSubmitExamConfirmationModalopen, setdialogSubmitExamConfirmationModalopen] = useState(false)
    const [showModalVideo, setshowModalVideo] = useState(false)
    const [whiteBoardVideoDiv, setwhiteBoardVideoDiv] = useState("")
    const [askAIsideBarOpen, setAskAIsideBarOpen] = useState(false);
    const [askAIquestionHint, setaskAIquestionHint] = useState("");
    const [hintBoardId, sethintBoardId] = useState("");
    const [hintChallengeId, setHintchallengeId] = useState("");
    const [showProgressSidebar, setshowProgressSidebar] = useState(false)
    const [progressSidebarContent, setprogressSidebarContent] = useState(null)
    const [withoutUserGetMCQPoints, setWithoutUserGetMCQPoints] = useState("-")
    const [withoutUserGetMSQPoints, setWithoutUserGetMSQPoints] = useState("-")
    const [withoutUserGetFRQPoints, setWithoutUserGetFRQPoints] = useState("-")
    const [attemptedIncorrect, setattemptedIncorrect] = useState(0)
    const [incorrectAttemptCount, setIncorrectAttemptCount] = useState(0)
    const [equationClick, setequationClick] = useState(false)
    const [askAIBgColor, setaskAIBgColor] = useState(false)
    const [bannerOpen, setbannerOpen] = useState(false)
    const [openFeedbackModal, setopenFeedbackModal] = useState(false)
    const [frqreviewModalOpen, setfrqreviewModalOpen] = useState(false)
    const [nonfrqreviewModalOpen, setnonfrqreviewModalOpen] = useState(false)
    const [frqGradeCaclulationTrial, setfrqGradeCaclulationTrial] = useState(0)
    const [deductedpoints, setdeductedpoints] = useState(0)
    const [ptTimeLapseCounter, setptTimeLapseCounter] = useState(0)
    const [totalQuestionCount, settotalQuestionCount] = useState(0);
    const [contentloader, setContentLoader] = useState(false);
    const [othercontentdata, setOtherContentData] = useState([]);
    const [challengeData, setChallengeData] = useState([]);
    const [challengeLoader, setChallengeLoader] = useState(false);
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [isChallengesLoaded, setIsChallengesLoaded] = useState(false);
    const [sub_sectionId, setSectionId] = useState(null);
    const [modalContentData, setModalContentData] = useState([]);
    const [testTotalPoints, settestTotalPoints] = useState(0)
    const [studentTotalPoints, setstudentTotalPoints] = useState(0)
    const [selectedChallengeBoardId, setselectedChallengeBoardId] = useState("")
    const [selectedChallengeId, setselectedChallengeId] = useState("");
    const [selectedChallengeAssignmentId, setselectedChallengeAssignmentId] = useState("");
    const [noequationfound, setnoequationfound] = useState(false)
    const [msqSectionGetPoints, setmsqSectionGetPoints] = useState(0);
    const [userGetMCQPoints, setuserGetMCQPoints] = useState(0)
    const [selectedStudentId, setselectedStudentId] = useState("");
    const [dialogFRQPtSubmitModalopen, setdialogFRQPtSubmitModalopen] = useState(false);
    const [sptchallengeHref, setsptchallengeHref] = useState("");
    const [sptchallenge_id, setsptchallenge_id] = useState("");
    const [sptqueIndex, setsptqueIndex] = useState("");
    const [mcqSectionPoints, setmcqSectionPoints] = useState(0)
    const [msqSectionPoints, setmsqSectionPoints] = useState(0)
    const [frqSectionPoints, setfrqSectionPoints] = useState(0)
    const [userGetFRQPoints, setuserGetFRQPoints] = useState(0)
    const [testId, settestId] = useState(0)
    const [totalcheckmyworkTrial, settotalcheckmyworkTrial] = useState(0)
    const [totalSectionPoints, settotalSectionPoints] = useState(0)
    const [showFeedbackSelectedQuestionType, setshowFeedbackSelectedQuestionType] = useState("")
    const [showfeedbackSelectedQuestionIndex, setshowfeedbackSelectedQuestionIndex] = useState(0)
    const [assignmentType, setassignmentType] = useState(assignment_Type.homework)
    const [isFromDashboard, setisFromDashboard] = useState(false)

    const cookies = new Cookies();

    useEffect(() => {
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            setStudent_Id(parseInt(props.match.params.studentId))
        }
        else {
            setStudent_Id(0)
        }

        cookies.set("timelapse", "", { path: "/" })
        const reqData = {
            params: {
                'user_id': props.match.params.studentId !== undefined && props.match.params.studentId !== null ?
                    parseInt(props.match.params.studentId)
                    :
                    getCurrentUserId(),
                'assignment_id': props.match.params.assignmentId
            }
        }

        settestId(props.match.params.assignmentId)
        getAssignmentDetailsById(reqData).then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                setassignmentDetailList(res.data[0])
                setstudentTotalPoints(res.data[0].totalPoints)
                settestTotalPoints(res.data[0].totalptexammarks)
                setassignmentType(res.data[0].assignment_type)
                if (res.data[0].usergetmsqpoints !== undefined && res.data[0].usergetmsqpoints !== null) {
                    setmsqSectionGetPoints(res.data[0].usergetmsqpoints)
                }
                if (res.data[0].usergetmcqpoints !== undefined && res.data[0].usergetmcqpoints !== null) {
                    setuserGetMCQPoints(res.data[0].usergetmcqpoints)
                }
                if (res.data[0].usergetfrqpoints !== undefined && res.data[0].usergetfrqpoints !== null) {
                    setuserGetFRQPoints(res.data[0].usergetfrqpoints)
                }
                let mcqList = res.data[0].challengedata.filter((e) => e.type === "MCQ" && e.is_multipart === 0 && e.status === "Released")
                setmcqAssignmentList(mcqList)
                let msqList = res.data[0].challengedata.filter((e) => e.type === "MSQ" && e.is_multipart === 0 && e.status === "Released")
                setmsqAssignmentList(msqList)
                let frqList = res.data[0].challengedata.filter((e) => (e.type === "FRQ" && e.status === "Released") || ((e.type === "MCQ" || e.type === "MSQ") && e.is_multipart === 1 && e.status === "Released"))
                setfrqAssignmentList(frqList)

                setisGuidedAsg(res.data[0].isguided)
                setisGradedAsg(res.data[0].isgraded)
                setassignmentTrials(res.data[0].total_trials)
                setisTimedAsg(res.data[0].istimed)
                setgeneralInstructions(res.data[0].general_instructions)
                setisPtSubmitted(res.data[0].isPtSubmitted)

                cookies.set("isguided", res.data[0].isguided, { path: "/" })
                cookies.set("isgraded", res.data[0].isgraded, { path: "/" })
                cookies.set("totaltrials", res.data[0].total_trials, { path: "/" })
                cookies.set("istimed", res.data[0].istimed, { path: "/" })
                cookies.set("isreview", (res.data[0].assignment_status === "completed" ? true : false), { path: "/" })

                if (res.data[0].mcqsectionpoints !== undefined && res.data[0].mcqsectionpoints !== null) {
                    setmcqSectionPoints(res.data[0].mcqsectionpoints)
                }
                if (res.data[0].msqsectionpoints !== undefined && res.data[0].msqsectionpoints !== null) {
                    setmsqSectionPoints(res.data[0].msqsectionpoints)
                }
                if (res.data[0].frqSectionPoints !== undefined && res.data[0].frqSectionPoints !== null) {
                    setfrqSectionPoints(res.data[0].frqSectionPoints)
                }

                epochValue = res.data[0].epoch
                setisAsgResubmit(res.data[0].is_resetassignment)
                if (res.data[0].assignment_status === "completed") {
                    setisReviewAsg(true)
                }

                if (res.data[0].timelapse !== null && res.data[0].timelapse !== undefined && res.data[0].timelapse !== "") {
                    isTimeLapse = true
                    if (cookies.get("fromFRQ", { pathname: "/" }) === true) {
                        cookies.set("fromFRQ", false, { path: "/" })
                    }
                }

                if (parseInt(res.data[0].isanychallengeSubmitted) === 1) {
                    var totalfrqpoints = 0;
                    frqList.forEach(item => {
                        totalfrqpoints += item.challenge_points;
                    });
                    setWithoutUserGetFRQPoints(totalfrqpoints)

                    var totalmcqpoints = 0;
                    mcqList.forEach(item => {
                        totalmcqpoints += item.challenge_points;
                    });
                    setWithoutUserGetMCQPoints(totalmcqpoints)

                    var totalmsqpoints = 0;
                    msqList.forEach(item => {
                        totalmsqpoints += item.challenge_points;
                    });
                    setWithoutUserGetMSQPoints(totalmsqpoints)
                }

                var totalfrqpoints1 = 0;
                var totalfrqmcqpoints1 = 0
                frqList.forEach(item => {
                    totalfrqpoints1 += item.frqchallengepoints;
                });
                frqList.forEach(item => {
                    totalfrqmcqpoints1 += item.totalnonfrqpoints;
                });
                setfrqSectionPoints(parseInt(totalfrqpoints1) + parseInt(totalfrqmcqpoints1))
                var totalmcqpoints1 = 0;
                mcqList.forEach(item => {
                    totalmcqpoints1 += item.totalnonfrqpoints;
                });
                setmcqSectionPoints(totalmcqpoints1)


                var totalmsqpoints1 = 0;
                msqList.forEach(item => {
                    totalmsqpoints1 += item.totalnonfrqpoints;
                });
                setmsqSectionPoints(totalmsqpoints1)


                /// Check if isTimed and set timelapse string accordingly in footer 
                if (res.data[0].istimed && epochValue > 0 && res.data[0].assignment_status !== "completed") {
                    /////////// Calculate current Timer values based on the time difference of Start time and Current time  ///////////

                    if (isTimeLapse) {
                        initialTime = res.data[0].timelapse
                        isTimeLapse = false
                    }
                    else {
                        initialTime = convertSecondsToHMS(res.data[0].assignment_time * 60)
                    }

                    cookies.set("assignmenttime", (res.data[0].assignment_time * 60), { path: "/" })
                    let initialTimeInSec = convertDurationToSeonds(initialTime)

                    let now = new Date();
                    let currentEpoch = Math.floor(now.getTime() / 1000);
                    let getTimeDiffArray = getTimeDifference(epochValue, currentEpoch)
                    let timeDiffString = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                    let timeDiffInSec = convertDurationToSeonds(timeDiffString)

                    let timeLapseInSec = initialTimeInSec - timeDiffInSec
                    let timeLapseString = convertSecondsToHMS(timeLapseInSec)

                    if (timeLapseString.includes("-1:-1")) {
                        timeLapseString = "00:00:00"
                    }

                    setptTimeLapseCounter(timeLapseString)
                }

                /// set Attempted Questions / Total questions values in footer
                let frqcount = 0
                if (frqList !== undefined && frqList !== null && frqList.length > 0) {
                    const groupbylist = groupByFRQ(frqList, frq => frq.multipartgroupid)

                    groupbylist.map(item => {
                        if (item.value !== undefined && item.value.length > 0) {
                            let toalgeouplength = item.value.length
                            let attemptedcountlength = item.value.filter((e) => e.is_attempted === 1).length
                            if (toalgeouplength === attemptedcountlength) {
                                frqcount = frqcount + 1
                            }
                        }
                    });
                }

                let mcqCount = res.data[0].challengedata.filter((e) => e.type === "MCQ" && e.is_multipart === 0 && e.status === "Released" && e.is_attempted === 1).length
                let msqCount = res.data[0].challengedata.filter((e) => e.type === "MSQ" && e.is_multipart === 0 && e.status === "Released" && e.is_attempted === 1).length

                let totalCount = mcqCount + msqCount + frqcount
                setattemptedQueCount(totalCount)
                settotalQuestionCount(res.data[0].totalchallengeperPtTest)
            }

            if (props.location.state !== undefined && props.location.state !== null) {
                if (props.location.state["prevNode"].includes('Dashboard')) {
                    cookies.set("fromPath", props.location.state["prevNode"], { path: "/" })
                    setisFromDashboard(true)
                } else if (props.location.state["prevNode"].includes('Student List')) {
                    cookies.set("fromPath", "", { path: "/" })
                    setisFromDashboard(false)
                }
                else {
                    cookies.set("fromPath", "", { path: "/assignments" })
                    setisFromDashboard(false)
                }

                if (cookies.get("fromPath", { pathname: "/" }) !== undefined && cookies.get("fromPath", { pathname: "/" }) !== "") {
                    if (cookies.get("fromPath", { pathname: "/" }).includes('Dashboard')) {
                        setisFromDashboard(true)
                    }
                }


                if (props.location.state["studentname"] !== undefined) {
                    cookies.set("studentName", props.location.state["studentname"], { path: "/" })
                    setStudentName(props.location.state["studentname"])
                }
                else {
                    setStudentName(cookies.get("studentName", { pathname: "/" }))
                }

                setIsTutorReview(true)
                if (props.match.params.studentId !== undefined) {
                    setStudent_Id(parseInt(props.match.params.studentId))
                    setisViewByProfessor(true)
                }
            }
            else {
                cookies.set("fromPath", "", { path: "/" })
                cookies.set("studentName", "", { path: "/" })
            }

            // if (props.location.state !== undefined && props.location.state !== null && getIsPTTeacher() === "true") {
            //     if (props.location.state["prevNode"].includes('Dashboard')) {
            //         setStudentName(props.location.state["studentname"])
            //         setIsTutorReview(true)
            //         if (props.match.params.studentId !== undefined) {
            //             setStudent_Id(parseInt(props.match.params.studentId))
            //             setisViewByProfessor(true)
            //         }
            //     }
            // }
            // else if (props.location.state !== undefined && props.location.state !== null) {
            //     if (props.location.state["prevNode"].includes('Student List')) {
            //         setStudentName(props.location.state["studentname"])
            //         setIsTutorReview(true)
            //         if (props.match.params.studentId !== undefined) {
            //             setisViewByProfessor(true)
            //             setStudent_Id(parseInt(props.match.params.studentId))
            //         }
            //     }
            // }
        })


        setTimeout(function () {
            const sPosition = cookies.get("PTTestFRQChallengeScroll")
            if (sPosition !== undefined && sPosition !== null && sPosition !== "") {
                const element = document.getElementById(sPosition);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
            else {
                document.getElementById('root').scrollTo({
                    top: 0, behavior:
                        'smooth'
                })
            }
            cookies.remove("PTTestFRQChallengeScroll")
        }, 1000);
    }, [])


    useEffect(() => {
        let initialTimeInSec = 0
        let timeLapseInSec = 0
        let timeLapseString = null
        if (isTimedAsg && epochValue > 0) {
            if (!isReviewAsg) {
                const interval = setInterval(() => {
                    //   if (getInstituteId() === Institute.Rice) {
                    let initialTimeInSec = convertDurationToSeonds(initialTime)

                    let now = new Date();
                    let currentEpoch = Math.floor(now.getTime() / 1000);
                    let getTimeDiffArray = getTimeDifference(epochValue, currentEpoch)
                    let timeDiffString = getTimeDiffArray.hours + ":" + getTimeDiffArray.minutes + ":" + getTimeDiffArray.seconds
                    let timeDiffInSec = convertDurationToSeonds(timeDiffString)

                    timeLapseInSec = initialTimeInSec - timeDiffInSec
                    timeLapseString = convertSecondsToHMS(timeLapseInSec)

                    if (timeLapseString.includes("-1:-1")) {
                        timeLapseString = "00:00:00"
                    }

                    cookies.set("timelapse", timeLapseString, { path: "/" })

                    setptTimeLapseCounter(timeLapseString)
                    checkTimeForAlertMessages(timeLapseString)

                    if (timeDiffInSec > initialTimeInSec) {
                        startExamTimeCountdownComplete()
                        clearInterval(interval)
                    }
                    // }
                    // else {
                    //     let initialTime = timeLapseString === null ? ptTimeLapseCounter : timeLapseString
                    //     initialTimeInSec = convertDurationToSeonds(initialTime)

                    //     let timeLapseInSec = initialTimeInSec - (timeLapseCounter / 1000)
                    //     timeLapseString = convertSecondsToHMS(timeLapseInSec)
                    //     if (timeLapseString.includes("-1:-1")) {
                    //         timeLapseString = "00:00:00"
                    //     }
                    //     cookies.set("timelapse", timeLapseString, { path: "/" })
                    //     setptTimeLapseCounter(timeLapseString)
                    //     checkTimeForAlertMessages(timeLapseString)

                    //     if (timeLapseInSec <= 0) {
                    //         startExamTimeCountdownComplete()
                    //         clearInterval(interval)
                    //     }
                    // }

                    //  console.log('Time lapse after 1 second is - ' + timeLapseString);
                }, timeLapseCounter)

                // if (getInstituteId() !== Institute.Rice) {
                //     return () => {
                //         const reqData = {
                //             "user_id": getCurrentUserId(),
                //             "assignment_id": parseInt(props.match.params.assignmentId),
                //             "timelapse": timeLapseString,
                //             "epoch": epochValue
                //         }

                //         saveAssignmentUserTimer(reqData).then(res => {
                //             if (res.data !== undefined && res.data !== null) {

                //             }
                //         })

                //         clearInterval(interval);
                //     }
                // }
            }
        }

    }, [totalQuestionCount])

    const checkTimeForAlertMessages = (timeCounter) => {
        let hours = timeCounter.split(":")[0]
        let minutes = timeCounter.split(":")[1]
        let seconds = timeCounter.split(":")[2]

        if (props.match.params.practiceMode === "exam") {
            if (parseInt(hours) === 0 && parseInt(minutes) === 10 && parseInt(seconds) === 0) {
                // alert("Last 10 min. to finish the exam..!! Hurry Up.")
                toast("Last 10 min. to finish the exam..!! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }
            else if (parseInt(hours) === 0 && parseInt(minutes) === 5 && parseInt(seconds) === 0) {
                // alert("Last 5 min. to finish the exam..!! Hurry Up.")
                toast("Last 5 min. to finish the exam..!! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }
            else if (parseInt(hours) === 0 && parseInt(minutes) === 1 && parseInt(seconds) === 0) {
                // alert("Last 1 min...If you do not submit this exam, It will get auto submit afterf a min !! Hurry Up.")
                toast("Last 1 min...If you do not submit this exam, It will get auto submit afterf a min !! Hurry Up.", {
                    type: toast.TYPE.WARNING,
                    position: toast.POSITION.TOP_CENTER
                })
            }
        }

        return timeCounter
    }

    const startExamTimeCountdownComplete = () => {
        if (!isExamSubmitted) {
            let timelapse = cookies.get("timelapse", { pathname: "/" })
            if (!timelapse.includes("-1:-1") || !timelapse.includes("00:00:00")) {
                submitExamClick();
            }
        }
    }

    const getMyScoreAsg = (event, assignmentId, userEnteredAns, boardId, questionId, type, index, isHintUsed, isCheckMyWork, mcqGradeCalclulationTrial, isFromFRQ) => {
        if (!isNil(userEnteredAns) && !isEmpty(userEnteredAns)) {

            if (parseInt(mcqGradeCalclulationTrial) < assignmentTrials) {
                let reqData = {
                    userAnswer: userEnteredAns,
                    questionId: questionId,
                    user_id: getCurrentUserId(),
                    assignment_id: assignmentId,
                    hints_used: isHintUsed,
                    problem_source: Problem_source.assignment,
                    boardId: boardId,
                    final_submission: 0,
                    optionselected: 1,
                    fromTestPaper: 1,
                    qtype: type
                }

                CalculatePTMCQGrade(reqData).then(res => {
                    if (res.data !== undefined && res.data !== null) {

                        let isCorrect = false
                        let remainingTiral = 0
                        if (res.data.answerStatus === "correct") {
                            isCorrect = true
                        }
                        else {
                            isCorrect = false
                            setIncorrectAttemptCount(incorrectAttemptCount + 1)
                        }

                        remainingTiral = (assignmentTrials - parseInt(res.data.totalGradeCalculationTrial))

                        let modalBody = (
                            <Aux>
                                <div>
                                    <ul>
                                        <li style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                                            Score : {res.data.totalonlyCheckGrades}
                                        </li>
                                    </ul>
                                    {parseInt(res.data.totalGradeCalculationTrial) === 4 && isGradedAsg ?
                                        <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                            Next trial will be your last trial to check your score. Post that the answer will be auto-submitted.
                                        </div>
                                        :
                                        <>
                                            {
                                                parseInt(res.data.totalGradeCalculationTrial) === (assignmentTrials - 1) && isGradedAsg ?
                                                    <Aux>
                                                        <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '16px' }}>
                                                            <div className='iconNotification'>You have completed all trials for this question. The current answer will be auto-submitted now.</div>
                                                        </div>
                                                    </Aux>
                                                    : null
                                            }
                                        </>
                                    }
                                </div>
                            </Aux>
                        )

                        if (isFromFRQ) {
                            let updateToList = [...frqAssignmentList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {
                                let tempArr = updateToList[index];

                                tempArr.totalGradeCalculationTrial = res.data.totalGradeCalculationTrial;
                                tempArr.remainingTrial = remainingTiral
                                tempArr.isCorrect = isCorrect

                                updateToList[index] = tempArr
                                setfrqAssignmentList(updateToList)

                                setloading(false)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetFRQPoints(total)
                            }
                        }
                        else {
                            let updateToList = [...mcqAssignmentList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {

                                let tempArr = updateToList[index];
                                tempArr.totalGradeCalculationTrial = res.data.totalGradeCalculationTrial;
                                tempArr.remainingTrial = remainingTiral
                                tempArr.isCorrect = isCorrect

                                updateToList[index] = tempArr

                                setmcqAssignmentList(updateToList)
                                setloading(false)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetMCQPoints(total)
                            }
                        }
                        openModal(event, "Attempt " + res.data.totalGradeCalculationTrial + "/" + assignmentTrials + "", modalBody, false, true, true)
                        if (parseInt(mcqGradeCalclulationTrial) === (assignmentTrials - 1)) {
                            mcqmsqProblemSubmitAsg(userEnteredAns, questionId, assignmentId, isHintUsed, boardId, type, index, isFromFRQ)
                        }
                    }
                }).catch(err => {
                    setloading(false)
                    console.error(err.message);
                    toast("Some error occured!", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                })
            }
            else {
                let modalBody = (
                    <Aux>
                        <div className='text-center modal-content' style={{ display: "block" }}>
                            <div style={{ position: 'absolute' }} className='iconNotification'>You have completed all trials for this problem.</div>
                        </div>
                    </Aux>
                )

                openModal(event, "Attempts Exceeded ", modalBody, false, true, true)

            }
        }
        else {

            let modalBody = (
                <Aux>
                    <div style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '12px' }}>
                        <div className='iconNotification'>Please select an answer first ...!</div>
                    </div>
                </Aux>
            )

            openModal(event, "Alert", modalBody, false, true, true)
        }
    }

    const getHintAsg = (event, challengeMapId, boardId, index, type, challenge_Id, assignment_id) => {
        let stId = getCurrentUserId()
        sethintBoardId(boardId)
        setHintchallengeId(challenge_Id)
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }

        let reqData = {
            boardId: boardId,
            user_id: stId,
            challengeMapId: challengeMapId,
            problem_source: Problem_source.assignment,
            assignment_id: assignment_id
        }

        fetchChallengeHintnonfrq({ params: reqData }).then(res => {
            setloading(false)
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                let contentdatafinal = []
                let hintfinalforAiTutor = []
                let finalhintforAitutor = ""
                if (res.data[0].remedial_text === "" || res.data[0].remedial_text === null || res.data[0].remedial_text === undefined) {
                    toast("No more hints found!", {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
                else {
                    res.data.forEach((rem) => {
                        let contentdata = (
                            <Aux>
                                <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: rem.remedial_text ? rem.remedial_text : '' }}></p>
                                <a className='mt-2 ' href={rem.remedial_source_url} target='_blank' rel="noopener noreferrer" >{rem.remedial_source_title ? rem.remedial_source_title : rem.remedial_source_url}</a>
                            </Aux>
                        )
                        contentdatafinal.push(contentdata)
                        hintfinalforAiTutor.push(rem.remedial_text)
                    })
                    if (hintfinalforAiTutor.length > 0) {
                        finalhintforAitutor = "Hint: " + hintfinalforAiTutor.join(", ")
                        setaskAIquestionHint(finalhintforAitutor)
                    }
                    let modalBody = (
                        <>{contentdatafinal}</>
                    );
                    customButton = (
                        <div className='modal-button text-center d-flex justify-content-center'>
                            <div>
                                <button
                                    type='button'
                                    className='modal-footer-button btn mx-auto'
                                    onClick={closeModal}>OK</button>
                            </div>
                            {
                                type === "FRQ" ? (<div style={{ marginLeft: "5px" }}>
                                    <button Tooltip="Get further explanations from the interactive AI tutor."
                                        type='button'
                                        className='modal-footer-button btn mx-auto'
                                        onClick={askAItutorHandler}>Ask AI Tutor</button>
                                </div>) : null
                            }

                        </div>
                    )
                    openModal(event, 'Hint', modalBody, customButton, customButton ? false : true, true);
                }
                let updateToList = type === "MCQ" ? [...mcqAssignmentList] : [...msqAssignmentList]
                if (updateToList[index] !== undefined && updateToList[index] !== null) {
                    let tempArr = updateToList[index];
                    tempArr.hint_used = true;

                    updateToList[index] = tempArr
                    if (type === "MCQ") {
                        setmcqAssignmentList(updateToList)
                    }
                    else {
                        setmsqAssignmentList(updateToList)
                    }
                    setloading(false)
                }
            }
            else if (res.data.message) {
                toast(res.data.message, {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
        }).catch(err => {
            console.error(err.message);
            setloading(false)

            toast("No more hints found!", {
                type: toast.TYPE.INFO,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        })
    }

    const showfeedbackAsg = (event, currentselectedproblemIndex, hintdata, graded, boardId, qtype, challenge_id, assignmentId, frqGradeCaclulationTrial1 = 0, totalpoints = 0) => {
        setdeductedpoints(0)
        setattemptedIncorrect(0)
        let stId = getCurrentUserId()
        setselectedChallengeBoardId(boardId)
        setselectedChallengeId(challenge_id)
        setselectedChallengeAssignmentId(assignmentId)
        setloading(true)
        settotalcheckmyworkTrial(frqGradeCaclulationTrial1)
        setshowFeedbackSelectedQuestionType(qtype)
        settotalSectionPoints(totalpoints)
        setshowfeedbackSelectedQuestionIndex(currentselectedproblemIndex)
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }
        setselectedStudentId(stId)
        if (qtype === QuestionType.frq) {
            setfrqreviewModalOpen(true)
            setnonfrqreviewModalOpen(false)
            setloading(false)
        }
        else {
            setfrqreviewModalOpen(false)
            setnonfrqreviewModalOpen(true)
            setloading(false)
        }
    }

    const svg2img = svg => {
        var placeholder = document.createElement('div');
        placeholder.innerHTML = svg
        var elem = placeholder.firstChild;

        var xml = new XMLSerializer(svg).serializeToString(elem);
        var svg64 = ''
        try {
            svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
        } catch (e) {
            svg64 = btoa(unescape(encodeURIComponent(xml)))
        }
        var b64start = 'data:image/svg+xml;base64,';
        var image64 = b64start + svg64;
        return image64;
    }

    const showSolutionFRQAsg = async (ev, challengeMapId, boardId, assignment_id) => {

        setloading(true)
        let PSContent = null
        let content = null;
        let allEquations = null;
        let challengeType = null;
        let solution = []

        let stId = getCurrentUserId()
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }

        setprogressSidebarContent(null)
        await getEntireSolution({
            params: {
                'challengeMapId': challengeMapId,
                'user_id': stId,
                'problem_source': Problem_source.assignment,
                'boardId': boardId,
                'assignment_id': assignment_id
            }
        }).then(res => {
            setloading(false)

            challengeType = res.data.challengeType;
            solution = res.data.solution
            if (res.data.challengeType === 'frq') {
                allEquations = res.data.solution;
            }

        }).catch(err => {
            setloading(false)
            console.error(err.message);
        })
        if (allEquations && challengeType === "frq") {
            let imgOfSolution = []
            for (let equation of allEquations) {
                const latexReqData = {
                    params: {
                        format: 'svg',
                        fontSize: '18px',
                        latex: equation.latex
                    }
                }
                const response = await generateSVGFromMathML(latexReqData)
                if (response.status === 200) {
                    const svg = response.data;
                    imgOfSolution.push({
                        image: svg2img(svg),
                        explanation: equation.explanation
                    });
                } else {
                    console.error('Unable to convert latex to svg');
                }
            }
            content = (
                <Aux>
                    <div className="solutionGraphCotainer">
                        <div>
                            {
                                solution.map(sol => {
                                    return (
                                        <>
                                            <div className="mt-3 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution green">
                                                <MathJaxRenderer math={`$$${sol.latex}$$`}></MathJaxRenderer>
                                            </div>
                                            <div className="mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution">
                                                <MathJaxRenderer math={sol.explanation}></MathJaxRenderer>
                                            </div>
                                            <div className='titleorcss'></div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Aux>
            )

        } else if (content === null) {
            content = (
                <p>No solutions found</p>
            )
            // Show no solutions found
        }

        PSContent = (
            <div className='d-flex flex-column mx-3 my-3 solutionContainer'>
                <h5 className='mb-2'><u>Entire Solution</u>:</h5>
                {content}
            </div>
        )

        setshowProgressSidebar(true)
        setprogressSidebarContent(PSContent)
    }

    const showSolutionAsg = async (event, challengeMapId, boardId, assignment_id) => {

        let PSContent = null
        let content = null;
        let remedial_resources = []

        let stId = getCurrentUserId()
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }

        setprogressSidebarContent(null)
        await getEntireSolution({
            params: {
                'challengeMapId': challengeMapId,
                'user_id': stId,
                'problem_source': Problem_source.assignment,
                'boardId': boardId,
                'assignment_id': assignment_id
            }
        }).then(res => {
            setloading(false)
            if (res.data.challengeType !== 'frq') {
                let correctAnswer = null;
                if (res.data.challengeType === 'mcq') {
                    correctAnswer = res.data.solution[0]
                } else if (res.data.challengeType === 'msq') {
                    correctAnswer = `<ul key='answer'>`
                    {
                        res.data.solution.map((ans, index) => {
                            correctAnswer += index === 0 ? `<li key={'msq' + index}>${ans}</li>` : `<br/><li key={'msq' + index}>${ans}</li>`
                        })
                    }
                    correctAnswer += `</ul>`


                } else if (res.data.challengeType === 'ranking') {
                    correctAnswer = (
                        <ol>
                            {res.data.solution.map((ans, index) => <li key={'ranking' + index}>{ans}</li>)}
                        </ol>
                    )
                }
                content = correctAnswer;
            }
        }).catch(err => {
            setloading(false)
            console.error(err.message);
        })
        if (content === null) {
            content = (
                <p>No solutions found</p>
            )
        }
        PSContent = (
            <div className='d-flex flex-column mx-3 my-3'>
                <h5 className='mb-2'><u>Correct Answer</u>:</h5>
                {<div dangerouslySetInnerHTML={{ __html: content }} />}
                {remedial_resources !== null && remedial_resources.length > 0 ?
                    <Aux>
                        <h5 className='mb-2 mt-3'><u>Resources</u>:</h5>
                        {remedial_resources.map(resource => {
                            if (resource.remedial_source_url !== null && resource.remedial_source_url.length > 0) {
                                return <a href={resource.remedial_source_url} target="_blank" rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: resource.remedial_source_title }}></p></a>
                            }
                            return null
                        })}
                    </Aux>
                    : null}
            </div>
        )
        setshowProgressSidebar(true)
        setprogressSidebarContent(PSContent)
    }

    const closeProgressSidebar = () => {
        setshowProgressSidebar(false)
    }

    const mcqmsqProblemSubmitAsg = (userEnteredAns, questionId, assignmentId, isHintUsed, boardId, type, index, isFromFRQ) => {
        //debugger;
        setloading(true)
        if (userEnteredAns === "" || userEnteredAns === null || userEnteredAns === undefined) {
            alert("Please select an answer first !")
        }
        else {
            let reqData = {
                userAnswer: userEnteredAns,
                questionId: questionId,
                user_id: getCurrentUserId(),
                assignment_id: assignmentId,
                hints_used: isHintUsed,
                problem_source: Problem_source.assignment,
                boardId: boardId,
                final_submission: 1,
                optionselected: 0,
                qtype: type,
                fromTestPaper: 1
            }

            checkChallengeAnswer(reqData).then(res => {
                if (res.data !== undefined && res.data !== null) {
                    // debugger;
                    setloading(false)
                    if (type === "MCQ") {
                        if (!isFromFRQ) {
                            let updateToList = [...mcqAssignmentList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {
                                let tempArr = updateToList[index]
                                tempArr.selectedAnswer = userEnteredAns;
                                tempArr.is_submitted = 1
                                tempArr.isCorrect = (res.data.status === "correct" ? true : false)
                                tempArr.challenge_points = res.data.returnpoint
                                updateToList[index] = tempArr

                                setmcqAssignmentList(updateToList)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetMCQPoints(total)
                            }
                        }
                        else {
                            let updateToList = [...frqAssignmentList]
                            if (updateToList[index] !== undefined && updateToList[index] !== null) {
                                let tempArr = updateToList[index]
                                tempArr.selectedAnswer = userEnteredAns;
                                tempArr.is_submitted = 1
                                tempArr.isCorrect = (res.data.status === "correct" ? true : false)
                                tempArr.challenge_points = res.data.returnpoint
                                updateToList[index] = tempArr
                                setfrqAssignmentList(updateToList)

                                var total = 0;
                                updateToList.forEach(item => {
                                    total += item.challenge_points;
                                });
                                setWithoutUserGetFRQPoints(total)
                            }
                        }
                    }
                    else if (type === "MSQ") {
                        let updateToList = [...msqAssignmentList]
                        if (updateToList[index] !== undefined && updateToList[index] !== null) {
                            let tempArr = updateToList[index]
                            tempArr.selectedAnswer = userEnteredAns;
                            tempArr.is_submitted = 1
                            tempArr.isCorrect = (res.data.status === "correct" ? true : false)
                            tempArr.challenge_points = res.data.returnpoint
                            updateToList[index] = tempArr

                            setmsqAssignmentList(updateToList)

                            var total = 0;
                            updateToList.forEach(item => {
                                total += item.challenge_points;
                            });
                            setWithoutUserGetMSQPoints(total)
                        }
                    }
                }
            }).catch(err => {
                setloading(false)
                console.error(err.message);
                alert('Error occurred while submitting the answer.');
            })
        }
    }

    const openModal = (event, modTitle, modBody, customBtn = null, showBtn = false, isDraggable = false, isContentPopup = false) => {
        if (event) {
            event.preventDefault();
        }
        if (!isContentPopup) {
            modalBody = modBody;
            modalTitle = modTitle;
            showButton = showBtn;
            customButton = customBtn;
            draggable = isDraggable;
            setshowModal(true)
        } else {
            contentModalBody = modBody;
            contentModalTitle = modTitle;
            showContentModalButton = showBtn;
            customButton = customBtn;
            draggable = false;
            setShowContentModal(true)
        }
    }

    const closeModal = () => {
        setshowModal(false)
    }

    const openTestInstructionPopUp = () => {
        setdialogViewInstructionsModalopen(true)
    }

    const closedialogViewInstructionsModalopen = (e) => {
        setdialogViewInstructionsModalopen(false)
    }

    const closedialogSubmitExamConfirmationModalopen = (e) => {
        setdialogSubmitExamConfirmationModalopen(false)
    }

    const askAItutorHandler = (e) => {
        setAskAIsideBarOpen(true)
        setshowModal(false)
        setequationClick(true)
    }

    const closeaskAItutorHandler = (e) => {
        setAskAIsideBarOpen(false)
    }

    const handleremovequestions = () => {
        setaskAIquestionHint("")
    }

    const handleequationclick = () => {
        setequationClick(false)
    }

    const handleremoveAskAIBgcolor = () => {
        setaskAIBgColor(false)
    }

    const groupByFRQ = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        let array = Array.from(map, ([key, value]) => ({ key, value }));
        return array
    }

    const usageguidehandler = () => {
        setshowModalVideo(true)
        setwhiteBoardVideoDiv(
            <div className='videoModalDiv'>
                <div className='videoDiv'>
                    <video playsinline muted loop={true} autoPlay={true} width="590" height="270" controls>
                        <source src={whiteBoardVideo} type="video/mp4" />
                    </video>
                </div>
                <div className='videoDescription mt-4'>
                    <p>Welcome to the Quiz Guide! In this video, we will walk you through everything you need to know to successfully complete your quiz.</p>
                </div>
                <div className='modal-button text-center'>
                    <Button className="videoClosebtn" onClick={closeusagaeguidehandler}> CLOSE </Button>
                </div>
            </div>
        )
    }

    const closeusagaeguidehandler = () => {
        setshowModalVideo(false)
    }

    const FRQClickScroll = () => {
        if (isNil(frqAssignmentList) || isEmpty(frqAssignmentList)) {
            setshowModal(true)
            modalBody = "No FRQ questions available for this assignment."
            modalTitle = "Alert"
        }
        else {
            const element = document.getElementById('frq-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const MSQClickScroll = () => {
        if (isNil(msqAssignmentList) || isEmpty(msqAssignmentList)) {
            setshowModal(true)
            modalBody = "No MSQ questions available for this assignment."
            modalTitle = "Alert"
        }
        else {
            const element = document.getElementById('msq-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const MCQClickScroll = () => {
        if (isNil(mcqAssignmentList) || isEmpty(mcqAssignmentList)) {
            setshowModal(true)
            modalBody = "No MCQ questions available for this assignment."
            modalTitle = "Alert"
        }
        else {
            const element = document.getElementById('mcq-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const handletopscroll = () => {
        const element = document.getElementById('top-section');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const feedbackLinkHandler = () => {
        setbannerOpen(false)
        setopenFeedbackModal(true)
    }

    const closeBanner = () => {
        setbannerOpen(false)
        setopenFeedbackModal(false)
    }

    const handlecloseFeedbackModal = () => {
        setopenFeedbackModal(false)
    }

    const setMCQUpdatedQueList = (list) => {
        setmcqAssignmentList(list)
    }

    const setMSQUpdatedQueList = (list) => {
        setmsqAssignmentList(list)
    }

    const setFRQUpdatedQueList = (list) => {
        setfrqAssignmentList(list)
    }

    const setAttemptedCount = (count) => {
        setattemptedQueCount(count)
    }

    const showreviewdnonFRQModalclose = () => {
        setnonfrqreviewModalOpen(false)
    }

    const showreviewdModalclose = () => {
        setfrqreviewModalOpen(false)
    }

    const handletabchange = () => {
    }

    const openGraphInFullMode = (e) => {
        let tempArr = [];
        let contentdata = (
            <Aux>
                <div style={{ width: '1300px', height: '500px' }}>
                    <GraphComponent
                        componentId="ptSummaryPopup"
                        assignment_id={parseInt(props.match.params.assignmentId)}
                        isauthordashboard={isViewByProfessor && isReviewAsg ? true : false}
                        selectedstudentId={student_Id}
                        openContentOptionsModal={(e, subSectionId, type, hasPosition) => openContentOptionsModal(e, subSectionId, type, hasPosition)}
                        handletabchange={() => handletabchange}
                        isPT={true}


                    ></GraphComponent></div>
            </Aux>
        )
        tempArr.push(contentdata)
        let modalBody = (
            <>{tempArr}</>
        );
        openModal(e, 'Your Proficiency Graph', modalBody, null, true, false, false);
    }

    const openContentOptionsModal = (e, subSectionId, type = null, hasPosition = false) => {
        if (!isNil(type)) {
            const reqData = { params: { subSectionId: subSectionId, nodetype: type } };
            setloading(true)
            setContentLoader(true);
            setOtherContentData([]);
            setChallengeData([])
            fetchSubSectionContent(reqData)
                .then((res) => {
                    setIsContentLoaded(true)
                    if (res.data.content.length > 0) {

                        setSectionId(subSectionId);
                        setModalContentData(res.data.content);
                        setContentLoader(false);
                        if (res.data.content.length > 1) {
                            setOtherContentData(res.data.content.splice(1))
                        } else {
                            setOtherContentData([])
                        }
                    }

                    getChallenges(subSectionId, type)
                })
                .catch((err) => {
                    setContentLoader(false);
                    setIsContentLoaded(true)
                    getChallenges(subSectionId, type)
                    console.error(err.message);
                });
        }
    }

    const getChallenges = (subSectionId, chapterType) => {
        setChallengeLoader(true)
        let stId = getCurrentUserId()
        if (props.match.params.studentId !== undefined && props.match.params.studentId !== null) {
            stId = parseInt(props.match.params.studentId)
        }
        const reqData1 = { params: { id: subSectionId, user_id: stId, ch_type: chapterType } };
        dashboardnodechallenges(reqData1)
            .then((res) => {
                setSectionId(subSectionId);
                setChallengeLoader(false);
                setIsChallengesLoaded(true)
                setloading(false)
                if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                    setChallengeData(res.data);
                }
                else {
                    setChallengeData([])
                }
            })
            .catch((err) => {
                setChallengeLoader([]);
                setIsChallengesLoaded(true)
                setloading(false)
                console.error(err.message);
            });
    }

    useEffect(() => {
        if (isChallengesLoaded && isContentLoaded) {
            openContentModalPopup();
            setIsContentLoaded(false)
            setIsChallengesLoaded(false)
        }

    }, [challengeData, othercontentdata])

    const openContentModalPopup = () => {

        let content = (
            <>
                <Aux>
                    <Box sx={{ width: 450 }}>
                        <div className="sidebar-right-fix pb-0 position-relative">
                            <div className="content-options">

                                {modalContentData.length > 0 ? (
                                    <HomeCurriculumContentCard
                                        title={null}
                                        data={[modalContentData[0]]}
                                        contentloader={contentloader}
                                        linkStateObj={{
                                            prevPath: ["/"],
                                            prevNode: ["Dashboard"],
                                        }}
                                    />
                                ) : (
                                    <HomeCurriculumContentCard
                                        title={null}
                                        data={modalContentData}
                                        contentloader={contentloader}
                                        linkStateObj={{
                                            prevPath: ["/"],
                                            prevNode: ["Dashboard"],
                                        }}
                                    />
                                )}
                                {othercontentdata.length > 0 ? (
                                    <div style={{ marginTop: "25px" }}>
                                        <HomeCurriculumContentCard
                                            title="Other Curated Content Options"
                                            data={othercontentdata}
                                            contentloader={false}
                                            linkStateObj={{
                                                prevPath: ["/"],
                                                prevNode: ["Dashboard"],
                                            }}
                                        />
                                    </div>
                                ) : null}


                                <h4 className='title' style={{ marginTop: "30px" }}>Interactive Problems</h4>
                                {
                                    challengeData !== undefined && challengeData !== null && challengeData.length > 0 ?
                                        (
                                            <>
                                                <ChallengeCard
                                                    title="Interactive Problems"
                                                    data={challengeData}
                                                    challengeLoader={challengeLoader}>
                                                </ChallengeCard>
                                            </>
                                        ) : (
                                            <Card
                                                style={{
                                                    position: 'relative', marginTop: "20px", padding: "50px"
                                                }}>Interactive problems will be available soon</Card>
                                        )
                                }
                            </div>
                        </div>
                    </Box>
                </Aux>
            </>
        )
        let tempArr = []
        tempArr.push(content)
        let modalBody = (
            <>{tempArr}</>
        );
        openModal(null, 'Recommended Content for Your Cognitive Profile', modalBody, null, true, false, true);
    }

    const getStudentMessage = () => {
        let msg = ''
        if (testTotalPoints !== 0) {
            let percntage = (studentTotalPoints / testTotalPoints) * 100;

            if (percntage >= 97) {
                msg = "Excellence is not an act, but a habit. You're living proof!"
            } else if (percntage >= 93) {
                msg = "Your hard work shines as brightly as your results. Keep aiming high!"
            } else if (percntage >= 90) {
                msg = "Great job! Your efforts are bringing you closer to perfection."
            } else if (percntage >= 87) {
                msg = "Almost there! Your potential is peeking through."
            } else if (percntage >= 83) {
                msg = "Solid work! Your dedication is your stepping stone to success."
            } else if (percntage >= 80) {
                msg = "You've got a strong foundation. Now, build on it!"
            } else if (percntage >= 77) {
                msg = "Your progress is commendable. Keep pushing your limits!"
            } else if (percntage >= 73) {
                msg = "Persistence conquers all. Stay determined!"
            } else if (percntage >= 70) {
                msg = "Every effort counts. You're on the right path!"
            } else if (percntage >= 50) {
                msg = "Solid, room for growth, refine understanding."
            } else {
                msg = "Identify weak areas, persevere, improve."
            }
        }

        return msg
    }

    const handleRedirectFRQ1Asg = (e, challenge_id) => {
        cookies.set('PTTestFRQChallengeScroll', challenge_id);
    }

    const handleRedirectFRQAsg = (e, challengeHref, testId, challenge_id, queIndex) => {
        cookies.set('PTTestFRQChallengeScroll', challenge_id);

        setsptchallengeHref(challengeHref);
        setsptchallenge_id(challenge_id);
        setsptqueIndex(queIndex);

        if (parseInt(isPtSubmitted) === 1) {
            setdialogFRQPtSubmitModalopen(true)
        }
        else {
            props.history.push({
                pathname: sptchallengeHref,
                state: student_Id > 0 ?
                    { prevPath: ['/assignmentdetails/' + testId + "/" + student_Id], prevNode: ['Assignment Details'], problemSource: [`${Problem_source.assignment}`] }
                    :
                    { prevPath: ['/assignmentdetails/' + testId], prevNode: ['Assignment Details'], problemSource: [`${Problem_source.assignment}`] }
            })
        }
    }

    const closedialogFRQPtSubmitModalopen = (e) => {
        setdialogFRQPtSubmitModalopen(false)

        props.history.push({
            pathname: sptchallengeHref,
            state: student_Id > 0 ?
                { prevPath: ['/assignmentdetails/' + testId + "/" + student_Id], prevNode: ['Assignment Summary'] }
                :
                { prevPath: ['/assignmentdetails/' + testId], prevNode: ['Assignment Summary'] }
        })
    }

    const submitExamClick = async () => {
        setdialogSubmitExamConfirmationModalopen(false)
        if (isExamSubmitted) {
            toast("Exam submitted Successfully !", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_RIGHT
            })

            return;
        }
        isExamSubmitted = true;
        setloading(true)

        const reqGetData = {
            params: {
                user_id: getCurrentUserId(),
                assignment_id: props.match.params.assignmentId
            }
        }

        let isPTSubmittedAlready = await is_pt_submitted(reqGetData).then(res => {
            if (res.data !== undefined) {
                if (parseInt(res.data.isPtSubmitted) === 1) {
                    setloading(false);
                    isExamSubmitted = true;
                    toast("This assignment is already submitted.", {
                        type: toast.TYPE.WARNING,
                        position: toast.POSITION.TOP_CENTER
                    })
                    return true
                }
                else {
                    return false
                }
            }
        })

        if (!isPTSubmittedAlready) {
            let timelapse = cookies.get("timelapse", { pathname: "/" })
            if (timelapse.includes("-1:-1")) {
                timelapse = "00:00:00"
            }

            // const reqData1 = {
            //     "user_id": getCurrentUserId(),
            //     "assignment_id": parseInt(props.match.params.assignmentId),
            //     "timelapse": timelapse,
            //     "epoch": epochValue
            // }

            // saveAssignmentUserTimer(reqData1).then(res => {
            //     if (res.data !== undefined && res.data !== null) {
            //         /// PT TimeLapse updated successfully..!
            //     }
            // })

            let challengeList = []
            if (mcqAssignmentList.length > 0) {

                mcqAssignmentList.map(ques => {
                    return (
                        ques.selectedAnswer ?
                            challengeList.push(
                                {
                                    "board": ques.boardId,
                                    "user_id": getCurrentUserId(),
                                    "is_finalsubmission": 1,
                                    "hint_used": ques.hint_used,
                                    "assignment_id": ques.assignment_id,
                                    "problem_source": Problem_source.assignment,
                                    "challenge_id": ques.challenge_id,
                                    "user_answer": ques.selectedAnswer,
                                    "optionselected": 0,
                                    "challengeMapId": ques.user_challenge_map_id,
                                    "assignment_challenge_id": ques.assignment_challenge_id
                                }
                            )
                            : null
                    )
                })
            }

            if (msqAssignmentList.length > 0) {
                msqAssignmentList.map(ques => {
                    return (
                        ques.selectedAnswer ?
                            challengeList.push(
                                {
                                    "board": ques.boardId,
                                    "user_id": getCurrentUserId(),
                                    "is_finalsubmission": 1,
                                    "hint_used": ques.hint_used,
                                    "assignment_id": ques.assignment_id,
                                    "problem_source": Problem_source.assignment,
                                    "challenge_id": ques.challenge_id,
                                    "user_answer": ques.selectedAnswer,
                                    "optionselected": 0,
                                    "challengeMapId": ques.user_challenge_map_id,
                                    "assignment_challenge_id": ques.assignment_challenge_id
                                }
                            )
                            : null
                    )
                })
            }

            if (frqAssignmentList.length > 0) {
                frqAssignmentList.map(ques => {
                    return (
                        ques.is_attempted === 1 ?
                            challengeList.push(
                                {
                                    "board": ques.boardId,
                                    "user_id": getCurrentUserId(),
                                    "is_finalsubmission": 1,
                                    "hint_used": ques.hint_used,
                                    "assignment_id": ques.assignment_id,
                                    "problem_source": Problem_source.assignment,
                                    "challenge_id": ques.challenge_id,
                                    "user_answer": ques.type === "MCQ" ? ques.selectedAnswer : "",
                                    "optionselected": 0,
                                    "challengeMapId": ques.user_challenge_map_id,
                                    "assignment_challenge_id": ques.assignment_challenge_id
                                }
                            )
                            : null
                    )
                })
            }
            const reqData = {
                "challengelist": JSON.stringify(challengeList),
                "user_id": getCurrentUserId(),
                "assignment_id": parseInt(props.match.params.assignmentId),
                "timelapse": timelapse,
                "epoch": epochValue
            }

            saveuserassignment(reqData).then(res => {
                if (res.data !== undefined && res.data !== null) {
                    window.location = "/assignmentdetails/" + props.match.params.assignmentId
                }
            })
        }
    }

    const setdeductedpointshandle = (points) => {
        setdeductedpoints(points)
    }
    const setattemptedIncorrecthandle = (attemptcount) => {
        setattemptedIncorrect(attemptcount)
    }

    const nonFRQpointschangedHandler = (type, points, showfeedbackSelectedQuestionIndex) => {
        if (type === QuestionType.mcq) {
            let data = [...mcqAssignmentList]
            let tempArr = data[showfeedbackSelectedQuestionIndex]
            tempArr.challenge_points = points
            data[showfeedbackSelectedQuestionIndex] = tempArr
            setmcqAssignmentList(data)

            var totalmcqpoints1 = 0;
            data.forEach(item => {
                totalmcqpoints1 += item.challenge_points;
            });
            setuserGetMCQPoints(totalmcqpoints1)
        }
        else {
            let data = [...msqAssignmentList]
            let tempArr = data[showfeedbackSelectedQuestionIndex]
            tempArr.challenge_points = points
            data[showfeedbackSelectedQuestionIndex] = tempArr
            setmsqAssignmentList(data)

            var totalmcqpoints1 = 0;
            data.forEach(item => {
                totalmcqpoints1 += item.challenge_points;
            });
            setmsqSectionGetPoints(totalmcqpoints1)
        }
    }
    const frqpointschangedHandler = (type, points, showfeedbackSelectedQuestionIndex) => {
        let data = [...frqAssignmentList]
        let tempArr = data[showfeedbackSelectedQuestionIndex]
        tempArr.challenge_points = points
        data[showfeedbackSelectedQuestionIndex] = tempArr
        setmsqAssignmentList(data)

        var totalmcqpoints1 = 0;
        data.forEach(item => {
            totalmcqpoints1 += item.challenge_points;
        });
        setuserGetFRQPoints(totalmcqpoints1)

    }
    return (
        <>

            <Dialog
                className='feedbackDialog'
                disableEnforceFocus={true}
                hideBackdrop={false}
                open={openFeedbackModal}
                onClose={handlecloseFeedbackModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ borderBottom: "1px solid #ccc", borderWidth: "95%" }}>
                    Feedback
                </DialogTitle>
                <MuiIconButton
                    aria-label="close"
                    onClick={handlecloseFeedbackModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <MuiCloseIcon />
                </MuiIconButton>
                <DialogContent sx={{ paddingTop: "1px !important", marginRight: "3px" }}>
                    <DialogContentText id="alert-dialog-description">
                        <FeedbackC onClose={handlecloseFeedbackModal}></FeedbackC>
                    </DialogContentText>
                </DialogContent>

            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogSubmitExamConfirmationModalopen}
                onClose={(e) => closedialogSubmitExamConfirmationModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#000", fontSize: '16px' }}>
                            <div className="examInstMainDiv">
                                {attemptedQueCount === totalQuestionCount ?
                                    "Are you sure you want to submit the entire assignment. You will not be able to make any changes after the submission."
                                    :
                                    "All the questions are not completed yet. Do you still want to submit the entire Assignment?"
                                }
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='submitConfirmationButtonContainer'>
                        <button className={classes.btnSubmit} onClick={(e) => closedialogSubmitExamConfirmationModalopen(e)}>
                            Continue Working On Assignment
                        </button>
                        <button className={classes.btnSubmit} onClick={(e) => submitExamClick(e)}>
                            Submit Entire Assignment
                        </button>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogViewInstructionsModalopen}
                onClose={(e) => closedialogViewInstructionsModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ whiteSpace: 'pre', marginTop: "10px", color: "#164b99" }}>
                            <div style={{ whiteSpace: 'pre' }} className="examInstMainDiv">
                                {generalInstructions !== undefined && generalInstructions !== null ? parse(generalInstructions) : null}
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button style={{ width: "150px" }} className={classes.btnSubmit} onClick={(e) => closedialogViewInstructionsModalopen(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                hideBackdrop={false}
                open={dialogFRQPtSubmitModalopen}
                onClose={(e) => closedialogFRQPtSubmitModalopen(e)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ marginTop: "10px", color: "#164b99" }}>
                            Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="dragLabelcancel leftmargin" onClick={(e) => closedialogFRQPtSubmitModalopen(e)}>
                        OK
                    </button>
                </DialogActions>
            </Dialog>

            <Modal
                show={frqreviewModalOpen}
                onHide={showreviewdModalclose}
                draggable={false}
                popUpSize="medium"
                body=
                {
                    <HomeworkReviewDetail
                        challenge_id={selectedChallengeId}
                        boardId={selectedChallengeBoardId}
                        studentId={selectedStudentId}
                        assignment_id={selectedChallengeAssignmentId}
                        selectedStudentName={studentName}
                        isPtSubmitted={isPtSubmitted}
                        frqGradeCaclulationTrial={frqGradeCaclulationTrial}
                        isGradedAsg={isGradedAsg}
                        showreviewdModalclose={showreviewdModalclose}
                        setdeductedpointshandle={setdeductedpointshandle}
                        setattemptedIncorrecthandle={setattemptedIncorrecthandle}
                        totalcheckmyworkTrial={totalcheckmyworkTrial}
                        totalSectionPoints={totalSectionPoints}
                        questionType={QuestionType.frq}
                        showfeedbackSelectedQuestionIndex={showfeedbackSelectedQuestionIndex}
                        frqpointschangedHandler={frqpointschangedHandler}
                    ></HomeworkReviewDetail>
                }
                handleClose={showreviewdModalclose}
                title={
                    deductedpoints > 0 ? (
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    Review Detail
                                </div>
                                <div style={{ fontSize: "15px" }}>
                                    #of incorrect attempts = {attemptedIncorrect === undefined || attemptedIncorrect === null ? 0 : Math.round(attemptedIncorrect)} so, total grades are reduced by {deductedpoints === undefined || deductedpoints === null ? 0 : Math.round(deductedpoints)}%
                                </div>

                            </div>
                        </>
                    ) : "Review Detail"
                }
                showButton={false}

            >
            </Modal >
            <Modal
                show={nonfrqreviewModalOpen}
                onHide={showreviewdnonFRQModalclose}
                draggable={false}
                popUpSize="medium"
                body=
                {
                    <HomeworkReviewDetailNonFRQ
                        challenge_id={selectedChallengeId}
                        boardId={selectedChallengeBoardId}
                        studentId={selectedStudentId}
                        assignment_id={selectedChallengeAssignmentId}
                        selectedStudentName={studentName}
                        isPtSubmitted={isPtSubmitted}
                        frqGradeCaclulationTrial={frqGradeCaclulationTrial}
                        isGradedAsg={isGradedAsg}
                        showreviewdnonFRQModalclose={showreviewdnonFRQModalclose}
                        setdeductedpointshandle={setdeductedpointshandle}
                        setattemptedIncorrecthandle={setattemptedIncorrecthandle}
                        totalcheckmyworkTrial={totalcheckmyworkTrial}
                        totalSectionPoints={totalSectionPoints}
                        questionType={showFeedbackSelectedQuestionType}
                        nonFRQpointschangedHandler={nonFRQpointschangedHandler}
                        showfeedbackSelectedQuestionIndex={showfeedbackSelectedQuestionIndex}
                    ></HomeworkReviewDetailNonFRQ>
                }
                handleClose={showreviewdnonFRQModalclose}
                title={
                    deductedpoints > 0 ? (
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    Review Detail
                                </div>
                                <div style={{ fontSize: "15px" }}>
                                    #of incorrect attempts = {attemptedIncorrect === undefined || attemptedIncorrect === null ? 0 : Math.round(attemptedIncorrect)} so, total grades are reduced by {deductedpoints === undefined || deductedpoints === null ? 0 : Math.round(deductedpoints)}%
                                </div>

                            </div>
                        </>
                    ) : "Review Detail"
                }
                showButton={false}

            >
            </Modal>

            <div className='questionRenderer' id="top-section">
                <div className='divBackLink container'>
                    <ArrowBack
                        style={{
                            color: "#4252AF",
                            width: "0.75em",
                            height: "0.98em",
                            marginLeft: "10px"
                        }}
                    />

                    <Link className='backlinkbutton'
                        to={{
                            pathname: student_Id > 0 ? "/" :
                                (props.location.state === undefined ? "/assignments" :
                                    (
                                        props.location.state["prevNode"].includes('Dashboard') || props.location.state["prevNode"].includes('Student List') ? props.location.state["prevPath"]
                                            : "/assignments"
                                    )
                                )
                            , state: {
                                prevPath: student_Id > 0 ? "/" :
                                    props.location.state === undefined ? ['/assignments'] :
                                        (
                                            props.location.state["prevNode"].includes('Dashboard') || props.location.state["prevNode"].includes('Student List') ?
                                                props.location.state["prevPath"] : "/assignments"
                                        )
                                , prevNode: student_Id > 0 ? (isFromDashboard ? ['Proficiency_View'] : ['Assignment_Summary']) : (props.location.state === undefined ? ["Assignments"] : (props.location.state["prevNode"].includes('Student List') ? ['Assignment_Summary'] : ['Proficiency_View'])),
                                assignmentId: [`${props.match.params.assignmentId}`]
                                , problemsource: [`${Problem_source.assignment}`]
                                , assignmentName: props.location.state === undefined ? "" : [`${props.location.state["assignmentName"] !== undefined ? props.location.state["assignmentName"] : ""}`]
                            }
                        }}
                    >
                        <div style={{ textDecoration: "underline" }}>
                            {
                                student_Id > 0 ? (isFromDashboard ? "Back to Dashboard" : "Back to Student List") :
                                    props.location.state === undefined ? "Back to Assignments" :
                                        props.location.state["prevNode"].includes('Dashboard') || props.location.state["prevNode"].includes('Student List')
                                            ? "Back to " + props.location.state["prevNode"]
                                            : "Back to Assignments"
                            }
                        </div>
                    </Link>
                </div>
                <div className='container-fluid'>
                    <div className='container bg-white-with-shadow my-3 my-md-5'>
                        <div className="testTitleDiv">
                            {
                                isTutorReview ? (<p className="testTitleTextStudent">
                                    {studentName}
                                </p>) : null
                            }
                            <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                                <p className="testTitleText">
                                    {isReviewAsg ? "Assessment For " : null} {assignmentDetailList.title}
                                </p>
                                <p className='testTitledate'>{formatDate(assignmentDetailList.start_date)}  - {formatDate(assignmentDetailList.end_date)}</p>
                            </div>

                            <div className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                                <div style={{ paddingLeft: '3px' }} className='d-flex justify-content-start' >
                                    <button onClick={FRQClickScroll} className='btn btn-link'>FRQ Section</button>
                                    {getInstituteId() !== Institute.Rice || (msqAssignmentList !== undefined && msqAssignmentList !== null && msqAssignmentList.length > 0) ? <button onClick={MSQClickScroll} className='btn btn-link'>MSQ Section</button> : null}
                                    <button onClick={MCQClickScroll} className='btn btn-link'>MCQ Section</button>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    {generalInstructions === undefined || generalInstructions === null || generalInstructions === "" || isReviewAsg
                                        ?
                                        null
                                        :
                                        <button style={{ position: 'relative', left: '10px', textDecorationLine: 'underline' }} onClick={openTestInstructionPopUp} className='btn btn-link'>
                                            View Essential Quiz Instructions</button>
                                    }

                                    {!isReviewAsg ? <button style={{ position: 'relative', textDecorationLine: 'underline' }} onClick={usageguidehandler} className='btn btn-link'>
                                        Usage Guide</button> : null}
                                </div>
                            </div>

                            <div className="lineBreak"></div>
                        </div>

                        {
                            !isReviewAsg ? null :
                                <>
                                    <div style={{ height: '100%', width: '100%', float: 'left', marginBottom: '50px' }}>
                                        {isGradedAsg ?
                                            <div className="divOverallResult">
                                                <div className="divHeaderass">Overall Test Results</div>
                                                <div className="divProgress">
                                                    <div className="divPointsCircle">
                                                        <div className="Sharts" style={{ width: 149, height: 149, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                            <div className="ShartsCharts1" style={{ width: 149, height: 149, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
                                                                <div className="ElementsOval" style={{ width: 149, height: 149, position: 'relative' }}>
                                                                    <div className="Color" style={{ width: 149, height: 149, left: 0, top: 0, position: 'absolute', background: '#E8F5D9' }}></div>
                                                                </div>
                                                                <div className="divPoints">
                                                                    <div className="divScore">{studentTotalPoints}/{testTotalPoints}</div>
                                                                    <div className="divPointText">points</div>
                                                                </div>
                                                                <div className="Oval" style={{ width: 127.86, height: 149, background: '#76B72E' }}></div>
                                                            </div>
                                                        </div>
                                                        <div className="divPointDesc">{getStudentMessage()}</div>
                                                    </div>
                                                    {
                                                        parseInt(isPtSubmitted) === 1 ? (
                                                            <div className="divPointDesc" style={{ width: "100%" }}>
                                                                Important: Your grades reflect your submitted solutions only. Any modifications afterward won't alter your grades.

                                                            </div>
                                                        ) : null
                                                    }

                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="divProfiencyResult">
                                            <div className="divHeaderass">Your Proficiency Graph</div>
                                            <div className="graph-legend" style={{ background: "#fff", bottom: 'unset', top: '12px', color: 'black' }}>
                                                <img src={legendCircle} />
                                                <div>
                                                    <div className="legend-completed">
                                                        <span>% Completion</span>
                                                    </div>
                                                    <div className="legend-mastery">
                                                        {" "}
                                                        <span>% Proficiency</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="divProgressGraph">
                                                {showFullApp() ?
                                                    <div className='boardBtns expandViewBtn' title='Expand' onClick={() => openGraphInFullMode()}>
                                                        <img src={ExpandIcon} />
                                                    </div> : null
                                                }

                                                <GraphComponent
                                                    componentId="ptSummary"
                                                    isauthordashboard={isViewByProfessor && isReviewAsg ? true : false}
                                                    selectedstudentId={student_Id}
                                                    assignment_id={parseInt(props.match.params.assignmentId)}
                                                    openContentOptionsModal={(e, subSectionId, type, hasPosition) => openContentOptionsModal(e, subSectionId, type, hasPosition)}
                                                    handletabchange={() => handletabchange}
                                                    isPT={true}
                                                ></GraphComponent>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ height: '100%', width: '100%', float: 'left' }}>
                                        <div className="divResult">
                                            <div className="divHeaderass marginbottom50">Problem Level Result</div>
                                        </div>
                                    </div>
                                </>
                        }

                        {mcqAssignmentList !== undefined && mcqAssignmentList !== null && mcqAssignmentList.length > 0 ?
                            <div className="questionTypeMainDiv" id="mcq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Multiple Choice Questions</div>
                                        <div className="avatar">
                                            <div className="op">MCQ</div>
                                            <div className="minWidth" />
                                        </div>
                                    </div>
                                    {isGradedAsg ?
                                        <div className="totalPoints25">
                                            {isReviewAsg ?
                                                "Total Points: " + (userGetMCQPoints !== undefined
                                                    && userGetMCQPoints !== null ? parseFloat(userGetMCQPoints).toFixed(2) : 0) + "/" +
                                                (mcqSectionPoints !== undefined
                                                    && mcqSectionPoints !== null ? parseFloat(mcqSectionPoints).toFixed(2) : 0)
                                                :
                                                (
                                                    <>
                                                        {
                                                            isAsgResubmit ? ("Total Points: " + (userGetMCQPoints !== undefined
                                                                && userGetMCQPoints !== null ? parseFloat(userGetMCQPoints).toFixed(2) : 0) + "/" +
                                                                (mcqSectionPoints !== undefined
                                                                    && mcqSectionPoints !== null ? parseFloat(mcqSectionPoints).toFixed(2) : 0)) :
                                                                ("Total Points: " + withoutUserGetMCQPoints + "/" + (mcqSectionPoints !== undefined
                                                                    && mcqSectionPoints !== null ? parseFloat(mcqSectionPoints).toFixed(2) : 0))
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="questionDivMain">
                                    {mcqAssignmentList.map((question, Index) => {
                                        return (
                                            <MCQAssignmentDetails
                                                mcqQuestion={question}
                                                assignmentType={assignmentType}
                                                isGraded={isGradedAsg}
                                                trials={assignmentTrials}
                                                isGuided={isGuidedAsg}
                                                isTimed={isTimedAsg}
                                                isReview={isReviewAsg}
                                                isFromFRQ={false}
                                                queIndex={Index}
                                                isPtSubmitted={isPtSubmitted}
                                                attemptedQueCountAsg={attemptedQueCount}
                                                attemptedQuestionCount={setAttemptedCount}
                                                mcqchallengepoint={assignmentDetailList.mcqchallengepoint}
                                                totalnonfrqpoints={assignmentDetailList.totalnonfrqpoints}
                                                attemptedQuestionList={attemptedQueList}
                                                mcqQuestionList={mcqAssignmentList}
                                                setMCQUpdatedQueList={setMCQUpdatedQueList}
                                                openAsgModal={openModal}
                                                getHint={getHintAsg}
                                                showSolution={showSolutionAsg}
                                                showfeedback={showfeedbackAsg}
                                                getMyScore={getMyScoreAsg}
                                                isAsgResubmit={isAsgResubmit}
                                                mcqmsqProblemSubmit={mcqmsqProblemSubmitAsg}
                                            />
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            : null
                        }

                        {msqAssignmentList !== undefined && msqAssignmentList !== null && msqAssignmentList.length > 0 ?
                            <div className="questionTypeMainDiv" id="msq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Multiple Select Questions</div>
                                        <div className="avatar">
                                            <div className="op">MSQ</div>
                                            <div className="minWidth" />
                                        </div>
                                    </div>
                                    {isGradedAsg ?
                                        <div className="totalPoints25">
                                            {isReviewAsg ?
                                                "Total Points: " + (msqSectionGetPoints !== undefined
                                                    && msqSectionGetPoints !== null ? parseFloat(msqSectionGetPoints).toFixed(2) : 0) + "/" + (msqSectionPoints !== undefined
                                                        && msqSectionPoints !== null ? parseFloat(msqSectionPoints).toFixed(2) : 0)
                                                :
                                                (
                                                    <>
                                                        {
                                                            isAsgResubmit ? "Total Points: " + (msqSectionGetPoints !== undefined
                                                                && msqSectionGetPoints !== null ? parseFloat(msqSectionGetPoints).toFixed(2) : 0) + "/" + (msqSectionPoints !== undefined
                                                                    && msqSectionPoints !== null ? parseFloat(msqSectionPoints).toFixed(2) : 0)
                                                                : "Total Points: " + withoutUserGetMSQPoints + "/" + (msqSectionPoints !== undefined
                                                                    && msqSectionPoints !== null ? parseFloat(msqSectionPoints).toFixed(2) : 0)
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    <Tooltip key="msqToolTip" style={{ flexDirection: 'column' }} title="Scroll To Top"
                                        placement='top' arrow>
                                        <div onClick={handletopscroll} className='pointerclassblink'><img src={scrollUp} height="30px" width="30px" /></div>
                                    </Tooltip>

                                </div>

                                <div className="questionDivMain">
                                    {msqAssignmentList.map((question, Index) => {
                                        return (
                                            <MSQAssignmentDetails
                                                msqQuestion={question}
                                                assignmentType={assignmentType}
                                                isGraded={isGradedAsg}
                                                trials={assignmentTrials}
                                                isGuided={isGuidedAsg}
                                                isTimed={isTimedAsg}
                                                isReview={isReviewAsg}
                                                isFromFRQ={false}
                                                queIndex={Index}
                                                isPtSubmitted={isPtSubmitted}
                                                attemptedQueCountAsg={attemptedQueCount}
                                                attemptedQuestionCount={setAttemptedCount}
                                                mcqchallengepoint={assignmentDetailList.msqchallengepoint}
                                                totalnonfrqpoints={assignmentDetailList.totalnonfrqpoints}
                                                attemptedQuestionList={attemptedQueList}
                                                msqQuestionList={msqAssignmentList}
                                                setMSQUpdatedQueList={setMSQUpdatedQueList}
                                                openAsgModal={openModal}
                                                getHint={getHintAsg}
                                                showSolution={showSolutionAsg}
                                                showfeedback={showfeedbackAsg}
                                                getMyScore={getMyScoreAsg}
                                                isTutorReviewDone={isTutorReview}
                                                isAsgResubmit={isAsgResubmit}
                                                mcqmsqProblemSubmit={mcqmsqProblemSubmitAsg}
                                            />
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                            null
                        }

                        {frqAssignmentList !== undefined && frqAssignmentList !== null && frqAssignmentList.length > 0 ?
                            <div className="questionTypeMainDiv" id="frq-section">
                                <div className="questionTypeDiv">
                                    <div className="questionTypeTitleDiv">
                                        <div className="questionTypeTitleText">Full Response Questions</div>

                                        <div className="avatar">
                                            <div className="op">FRQ</div>
                                            <div className="minWidth" />
                                        </div>
                                    </div>
                                    {isGradedAsg ?
                                        <div className="totalPoints25">
                                            {isReviewAsg ?
                                                "Total Points: " + parseFloat(userGetFRQPoints).toFixed(2) + "/" + parseFloat(frqSectionPoints).toFixed(2)
                                                : (
                                                    <>
                                                        {
                                                            isAsgResubmit ? "Total Points: " + parseFloat(userGetFRQPoints).toFixed(2) + "/" + parseFloat(frqSectionPoints).toFixed(2)
                                                                : "Total Points: " + withoutUserGetFRQPoints + "/" + parseFloat(frqSectionPoints).toFixed(2)
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    <Tooltip key="frqToolTip" style={{ flexDirection: 'column' }} title="Scroll To Top"
                                        placement='top' arrow>
                                        <div onClick={handletopscroll} className='pointerclassblink'><img src={scrollUp} height="30px" width="30px" /></div>
                                    </Tooltip>
                                </div>
                                <span className="frqInfo">In this practice test, the format of some FRQs is enhanced to pinpoint knowledge gaps more precisely than typical text-based answers allow.</span>
                                <div className="questionDivMain">
                                    {frqAssignmentList.map((question, Index) => {
                                        let challengeLinkAsg
                                        if (student_Id > 0) {
                                            challengeLinkAsg = "/answerboardpt/c" + question.challenge_id + "/" + question.assignment_id + "/0/" + student_Id
                                        }
                                        else {
                                            challengeLinkAsg = "/answerboardpt/c" + question.challenge_id + "/" + question.assignment_id
                                        }
                                        return (
                                            question.status === "Released" ?
                                                <>
                                                    {question.type === "FRQ" ?
                                                        <FRQAssignmentDetails
                                                            frqQuestion={question}
                                                            isGraded={isGradedAsg}
                                                            trials={assignmentTrials}
                                                            isGuided={isGuidedAsg}
                                                            isTimed={isTimedAsg}
                                                            isReview={isReviewAsg}
                                                            challengeHref={challengeLinkAsg}
                                                            isFromFRQ={true}
                                                            queIndex={Index}
                                                            assignmentType={assignmentType}
                                                            isPtSubmitted={isPtSubmitted}
                                                            attemptedQueCountAsg={attemptedQueCount}
                                                            attemptedQuestionCount={setAttemptedCount}
                                                            frqchallengepoint={assignmentDetailList.frqchallengepoint}
                                                            totalnonfrqpoints={assignmentDetailList.totalfrqpoints}
                                                            attemptedQuestionList={attemptedQueList}
                                                            frqQuestionList={frqAssignmentList}
                                                            setFRQUpdatedQueList={setFRQUpdatedQueList}
                                                            openAsgModal={openModal}
                                                            showfeedback={showfeedbackAsg}
                                                            handleRedirectFRQ={handleRedirectFRQAsg}
                                                            handleRedirectFRQ1={handleRedirectFRQ1Asg}
                                                            testId={props.match.params.assignmentId}
                                                            isAsgResubmit={isAsgResubmit}
                                                            showSolutionFRQ={showSolutionFRQAsg}
                                                            studentId={student_Id}
                                                        />
                                                        :
                                                        <MCQAssignmentDetails
                                                            mcqQuestion={question}
                                                            isGraded={isGradedAsg}
                                                            trials={assignmentTrials}
                                                            isGuided={isGuidedAsg}
                                                            isTimed={isTimedAsg}
                                                            isReview={isReviewAsg}
                                                            assignmentType={assignmentType}
                                                            isFromFRQ={true}
                                                            queIndex={Index}
                                                            isPtSubmitted={isPtSubmitted}
                                                            attemptedQueCountAsg={attemptedQueCount}
                                                            attemptedQuestionCount={setAttemptedCount}
                                                            mcqchallengepoint={assignmentDetailList.mcqchallengepoint}
                                                            totalnonfrqpoints={assignmentDetailList.totalnonfrqpoints}
                                                            attemptedQuestionList={attemptedQueList}
                                                            mcqQuestionList={mcqAssignmentList}
                                                            setMCQUpdatedQueList={setMCQUpdatedQueList}
                                                            openAsgModal={openModal}
                                                            getHint={getHintAsg}
                                                            showSolution={showSolutionAsg}
                                                            showfeedback={showfeedbackAsg}
                                                            getMyScore={getMyScoreAsg}
                                                            frqQuestionList={question}
                                                            isAsgResubmit={isAsgResubmit}
                                                            mcqmsqProblemSubmit={mcqmsqProblemSubmitAsg}
                                                        />
                                                    }
                                                </>
                                                : null
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    {isReviewAsg ? (
                        <>
                            {
                                isAsgResubmit === true || isAsgResubmit === "true" ? (<div className='examPaperFooterQuestion'>
                                    <div className='progressFooter'>
                                        {isTimedAsg ?
                                            <div className="countDownDiv">
                                                <div className="divTimeCounter">
                                                    <div className="timeLeft">Time left</div>
                                                    <div className="timeNumber">
                                                        {ptTimeLapseCounter}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="submitExamDiv">
                                            <div className="questionsAttempted">Attempted Questions: {attemptedQueCount}/{totalQuestionCount}</div>
                                            <div className='btnSubmitDiv'>

                                                <Button className={classes.btnSubmit} disabled={isExamSubmitted} onClick={() => setdialogSubmitExamConfirmationModalopen(true)}>   SUBMIT ENTIRE QUIZ</Button>

                                            </div>
                                        </div>
                                    </div>
                                </div>) : null
                            }
                        </>
                    ) :
                        <div className='examPaperFooterQuestion'>
                            <div className='progressFooter'>
                                {isTimedAsg ?
                                    <div className="countDownDiv">
                                        <div className="divTimeCounter">
                                            <div className="timeLeft">Time left</div>
                                            <div className="timeNumber">
                                                {ptTimeLapseCounter}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="submitExamDiv">
                                    <div className="questionsAttempted">Attempted Questions: {attemptedQueCount}/{totalQuestionCount}</div>
                                    <div className='btnSubmitDiv'>
                                        <Button className={classes.btnSubmit} disabled={isExamSubmitted} onClick={() => setdialogSubmitExamConfirmationModalopen(true)}>   SUBMIT ENTIRE ASSIGNMENT</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <ToastContainer />
                </div>
            </div >

            <ProgressSidebar
                show={showProgressSidebar}
                closeSidebar={closeProgressSidebar}>
                {progressSidebarContent}
            </ProgressSidebar>

            <Modal
                show={showModal}
                handleClose={closeModal}
                title={modalTitle}
                body={modalBody}
                showButton={showButton}
                customButton={customButton}
                draggable={draggable} />

            <Modal
                show={showContentModal}
                handleClose={() => setShowContentModal(false)}
                title={contentModalTitle}
                body={contentModalBody}
                showButton={showContentModalButton}
                customButton={customButton}
                draggable={false} />

            <Modal className='modalVideo'
                show={showModalVideo}
                onHide={closeusagaeguidehandler}
                draggable={false}
                body={whiteBoardVideoDiv}
                handleClose={closeusagaeguidehandler}
                hideHeader={true}
                showButton={false}
            >
            </Modal>

            {
                loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: "#164b99",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )
            }

            <Drawer
                anchor="right"
                open={askAIsideBarOpen}
                onClose={closeaskAItutorHandler}
            >
                <div className='anyProblemChartBotpt' style={{ width: window.innerWidth * 0.25 }}>
                    <AnyProblem
                        handleremovequestions={handleremovequestions}
                        isInline={false}
                        question={askAIquestionHint}
                        onClose={() => closeaskAItutorHandler()}
                        challengeId={hintChallengeId}
                        boardId={hintBoardId}
                        equationClick={true}
                        askAIBgColor={askAIBgColor}
                        handleequationclick={handleequationclick}
                        handleremoveAskAIBgcolor={handleremoveAskAIBgcolor}
                        isWOP={false}
                        fromPTTest={true}
                    ></AnyProblem>
                </div>
            </Drawer>

            <TrapFocus open disableAutoFocus disableEnforceFocus>
                <Fade appear={false} in={bannerOpen}>
                    <Paper
                        elevation={24}
                        role="dialog"
                        aria-modal="false"
                        aria-label="Cookie banner"
                        square
                        variant="outlined"
                        tabIndex={-1}
                        sx={{
                            position: 'fixed',
                            right: 0,
                            m: 0,
                            p: 2,
                            borderWidth: "1px",
                            borderColor: "black",
                            // borderTopWidth: 1,
                            zIndex: 9999,
                            width: "20% !important",
                            right: "30px",
                            bottom: "40px",
                            borderRadius: "10px",
                            '@media (max-width: 600px)': {
                                width: '100% !important',
                                right: "0px !important"
                            },
                            '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                width: '34% !important'
                            }
                        }}
                    >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-start"
                            gap={2}

                        >
                            <img src={LogoSign} alt="" className="feebackcompanyLogo" />

                            <Typography sx={{
                                fontSize: "18px !important"
                            }}>Please help us improve aiPlato.</Typography>
                        </Stack>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-end"
                            gap={2}
                            sx={{ mt: 1 }}
                        >
                            <MuiButton size="small" onClick={closeBanner} variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    fontSize: "15px"
                                }}>
                                No Thanks
                            </MuiButton>
                            <MuiButton size="small"
                                onClick={feedbackLinkHandler}
                                variant="contained"
                                sx={{

                                    textTransform: "capitalize",
                                    fontSize: "15px",
                                    '&:hover': {
                                        color: '#ffffff',
                                    },

                                }}>
                                Feedback
                            </MuiButton>
                        </Stack>
                    </Paper>
                </Fade>
            </TrapFocus>
        </>
    )
}

export default withRouter(AssignmentDetails)