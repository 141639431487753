import React, { Suspense, lazy, Component, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Whiteboard from './container/Whiteboard/Whiteboard';
import CuriosityExploration from './container/CurriculumContent/CuriosityExploration';
import CurriculumContent from './container/CurriculumContent/CurriculumContent';
import Homepage from './container/Homepage/Homepage';
import LogRocket from 'logrocket';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import NotesBookmarks from './container/NotesBookmarks/NotesBookmarks';
import Challenges from './container/Challenges/Challenges';
import TeacherUI from './container/TutorScreens/TeacherUI/TeacherUI'
import ScrollMemory from 'react-router-scroll-memory';
import CurriculumContentDashboard from './container/CurriculumContent/CurriculumContentDashboard';
import Cookies from 'universal-cookie';
import { getCurrentUserEmail, getCurrentUserStatus, getCurrentUserName, getCurrentUserId, getCurrentUserRole, setSession, APP_URL, showFullApp, getIsPTTeacher } from './common/Functions';
import { demoUserId } from './common/Constants'
import { updateSessionTime, getIPAddress, savedemouserdetail } from './common/API';
import SessionHistory from './container/TutorScreens/SessionHistory/SessionHistory';
import AddChallenges from './container/Challenges/AddChallenges/AddChallenges';
import SolutionGraph from './container/SolutionGraph/SolutionGraph';
import SolutionGraphText from './container/SolutionGraph/SolutionGraphText';
import CurriculumMap from './container/CurriculumMap/CurriculumMap';
import Tutor from './container/Tutor/Tutor'
//import TutorAvailability from './container/Tutor/TutorAvailability';
import ChatBotComponent from './components/Common/Chatbot/ChatBot';
import ScrollToTop from './ScrollToTop';
import RealtimeQAComponent from './components/RealtimeQA/RealtimeQAComponent';
// import HomeworkProblems from './container/Challenges/HomeworkProblems';
import HomeworkProblems from './components/Homework/Homework';
import StudentList from './components/StudentList/StudentList';
import StudentListNonFRQ from './components/StudentList/StudentListNonFRQ';
import ManageClass from './components/ManageClass/ManageClass';
import SubmitQuestion from './components/SubmitQuestion/SubmitQuestion';
import AnyProblem from './components/CurriculumContent/AnyProblem/AnyProblem';
import CloseIcon from '@mui/icons-material/Close';
import "./App.css";
import AuthorHomepage from './container/Homepage/Author/HomePage';
import ChangePassword from './container/ChangePassword/ChangePassword';
import { isNil } from 'lodash';
import DataCollections from './container/DataCollections/DataCollections';
import ChallengeSymbol from './container/ChallengeSymbol/ChallengeSymbol';
import configData from "./common/config.json";
import WOPComponent from './container/WOP/WOPComponent';
import InteractionEvaluation from './container/DataCollections/InteractionEvaluation';
import AssignmentList from './container/Assignment/AssignmentList';
import AssignmentDetails from './container/Assignment/AssignmentDetails';
import StepByStepComponent from './container/StepByStep/StepByStepComponent';


const TutorAvailability = lazy(() => import(`./container/Tutor/TutorAvailability`));


class App extends Component {

  sessionId = undefined;

  constructor(props) {
    super(props);

    this.state = {
      openAnyproblemModal: false,
      classCSS: ""
    }
  }

  handleOpenAnyProblemModal = () => {
    this.setState({ openAnyproblemModal: true, classCSS: 'animate' })
  }

  handleCloseAnyProblemModal = () => {
    this.setState({ openAnyproblemModal: false })
  }

  componentWillMount() {
    const cookies = new Cookies();
    console.log('cookie ', cookies.get('isValid'));
    const email = getCurrentUserEmail()
    const name = getCurrentUserName()
    const userId = getCurrentUserId()
    const role = getCurrentUserRole()
    const status = getCurrentUserStatus()

    if (cookies.get('isValid') !== 'yes'
      || role === undefined || name === undefined || userId === undefined || email === undefined
    ) {
      console.log('not a valid user');
      window.location.href = APP_URL//process.env.REACT_APP_BASE_URL
    }
  }
  componentDidMount() {
    this.updateSessionData()
    setInterval(this.updateSessionData, 1000 * 60);
   

  }
  getRoleBasedRoutes = () => {
    const userRole = getCurrentUserRole()
    const routesMapping = {
      Admin: <>
        <Route path="/" exact component={Homepage} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/curiosity-exploration" exact component={CuriosityExploration} />
        <Route path="/curriculum-content/:topicId/:questionId?/:teacher?" exact component={CurriculumContent} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/notes-bookmarks" exact component={NotesBookmarks} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* <Route path="/practicetest" exact component={PracticePaper} /> */}

        {/* <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} /> */}
      </>,
      Student: <>
        {getIsPTTeacher() === 'true' ? <Route path="/" exact component={AuthorHomepage} /> : <Route path="/" exact component={Homepage} />}
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/curiosity-exploration" exact component={CuriosityExploration} />
        <Route path="/curriculum-content/:topicId/:questionId?/:teacher?" exact component={CurriculumContent} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/notes-bookmarks" exact component={NotesBookmarks} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/realtimeqa" exact component={SubmitQuestion} />
        <Route path="/changepassword" exact component={ChangePassword} />
        {/* <Route path="/practicetest" exact component={PracticePaper} /> */}
        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} />
     */}
      </>,
      Tutor: <>
        <Route path="/" exact component={TeacherUI} />
        <Route path="/session-history" exact component={SessionHistory} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/curriculum-content/:topicId/:questionId?/:teacher?" exact component={CurriculumContent} />
        <Route path="/profile" exact component={Tutor} />
        {/* <Suspense> <Route path="/scheduler" exact component={TutorAvailability} /></Suspense> */}
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/studentlist/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentList} />
        <Route path="/studentlistnonfrq/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentListNonFRQ} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* <Route path="/practicepaper" exact component={PracticePaper} /> 
       <Route path="/practicetest" exact component={PracticePaper} />
        <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} />
    */}
      </>,
      ContentAuthor: <>
        <Route path="/" exact component={AuthorHomepage} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/addchallenges/:challengeId?/:state?" component={AddChallenges} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/solutiongraph/:challengeId?" exact component={SolutionGraph} />
        <Route path="/solutiongraphtext/:challengeId?" exact component={SolutionGraphText} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-content/:topicId/:questionId?/:teacher?" exact component={CurriculumContent} />
        <Route path="/curriculum-map" exact component={CurriculumMap} />
        <Route path="/realtime-qa" exact component={RealtimeQAComponent} />
        <Route path="/manage-class" exact component={ManageClass} />
        <Route path="/curiosity-exploration" exact component={CuriosityExploration} />
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/studentlist/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentList} />
        <Route path="/studentlistnonfrq/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentListNonFRQ} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/addeditsubstitution/:challengeId?" exact component={ChallengeSymbol} />
        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* <Route path="/practicepaper" exact component={PracticePaper} />
        <Route path="/practicetest" exact component={PracticePaper} />
        <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} /> */}
      </>,
      Professor: <>
        <Route path="/" exact component={AuthorHomepage} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/addchallenges/:challengeId?/:state?" component={AddChallenges} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        {/* <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} /> */}
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/solutiongraph/:challengeId?" exact component={SolutionGraph} />
        <Route path="/solutiongraphtext/:challengeId?" exact component={SolutionGraphText} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-content/:topicId/:questionId?/:teacher?" exact component={CurriculumContent} />
        <Route path="/curriculum-map" exact component={CurriculumMap} />
        <Route path="/realtime-qa" exact component={RealtimeQAComponent} />
        <Route path="/manage-class" exact component={ManageClass} />
        <Route path="/curiosity-exploration" exact component={CuriosityExploration} />
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/studentlist/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentList} />
        <Route path="/studentlistnonfrq/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentListNonFRQ} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/tutorDashboard" exact component={TeacherUI} />

        <Route path="/session-history" exact component={SessionHistory} />
        <Route path="/datacollection" exact component={DataCollections} />
        <Route path="/interactionEvaluation" exact component={InteractionEvaluation} />
        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        <Route path="/step" exact component={StepByStepComponent} />
        {/*         <Route path="/practicetest" exact component={PracticePaper} />
        <Route path="/practicepaper" exact component={PracticePaper} />
        <Route path="/addeditsubstitution/:challengeId?" exact component={ChallengeSymbol} />      
        <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} /> */}
      </>,
      ContentReviewer: <>
        <Route path="/" exact component={AuthorHomepage} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/addchallenges/:challengeId?/:state?" component={AddChallenges} />
        <Route path="/solutiongraph/:challengeId?" exact component={SolutionGraph} />
        <Route path="/solutiongraphtext/:challengeId?" exact component={SolutionGraphText} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/studentlist/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentList} />
        <Route path="/studentlistnonfrq/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentListNonFRQ} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/addeditsubstitution/:challengeId?" exact component={ChallengeSymbol} />
        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* 
             <Route path="/practicetest" exact component={PracticePaper} />
        <Route path="/practicepaper" exact component={PracticePaper} />     
        <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} /> */}
      </>,
      ContentExpert: <>
        <Route path="/" exact component={AuthorHomepage} />
        <Route path="/challenges" exact component={Challenges} />
        <Route path="/addchallenges/:challengeId?/:state?" component={AddChallenges} />
        <Route path="/solutiongraph/:challengeId?" exact component={SolutionGraph} />
        <Route path="/solutiongraphtext/:challengeId?" exact component={SolutionGraphText} />
        <Route path="/answerboard/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardpt/:questionId/:assignmentId?/:teacher?/:studentId?" exact component={Whiteboard} />
        <Route path="/answerboardhow/:questionId/:homeworkId?/:teacher?" exact component={Whiteboard} />
        <Route path="/answerboardhw/:questionId/:teacher?/:homeworkId?" exact component={Whiteboard} />
        <Route path="/answerboardassignment/:questionId/:assignmentId?/:teacher?" exact component={Whiteboard} />
        <Route path="/curriculum-content" exact component={CurriculumContentDashboard} />
        <Route path="/curriculum-problems" exact component={CurriculumContentDashboard} />
        <Route path="/homeworkproblems" exact component={HomeworkProblems} />
        <Route path="/realtime-qa" exact component={RealtimeQAComponent} />
        <Route path="/studentlist/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentList} />
        <Route path="/studentlistnonfrq/:challengeId?/:homeworkId?/:problemsource?" exact component={StudentListNonFRQ} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/addeditsubstitution/:challengeId?" exact component={ChallengeSymbol} />

        <Route path="/wop/:menuId?" exact component={WOPComponent} />
        <Route path="/assignments" exact component={AssignmentList} />
        <Route path="/assignmentdetails/:assignmentId?/:studentId?" exact component={AssignmentDetails} />
        {/* 
        <Route path="/practicetest" exact component={PracticePaper} />
        <Route path="/practicepaper" exact component={PracticePaper} /> 
        <Route path="/TestInstructions/:practiceId/:practiceMode" exact component={TestInstructions} />
        <Route path="/questionRenderer/:practiceId/:practiceMode" exact component={QuestionRenderer} />
        <Route path="/quesionRenderer/:practiceId/:practiceMode/:review?/:cIndex?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?" exact component={QuestionRenderer} />
        <Route path="/quesionRendererauthor/:practiceId/:practiceMode/:review?/:studentId?/:problemsource?" exact component={QuestionRenderer} />*/}
      </>,
    }
    return routesMapping[userRole]
  }

  updateSessionData = () => {

    if (this.sessionId === undefined) {
      getIPAddress()
        .then(res => {
          const reqData = {
            userId: getCurrentUserId(),
            ip: res.data.query,
          }
          this.updateSession(reqData)
        })
        .catch(error => {
          const reqData = {
            userId: getCurrentUserId(),
            ip: '0.0.0.0',
          }
          this.updateSession(reqData)
        })
    } else {
      const reqData = {
        userId: getCurrentUserId(),
        sessionId: this.sessionId,
      }
      this.updateSession(reqData)
    }
  }


  updateSession = (reqData) => {
    updateSessionTime(reqData)
      .then(res => {
        this.sessionId = res.data.sessionId;
        setSession(res.data.sessionId)
      })
      .catch(err => {
        console.error(err.message);
      })
  }
  navigateToUserInterestedPage() {
  }
  render() {

    return (
      <BrowserRouter>
        {/* <Tour  /> */}
        <ScrollMemory />

        <Header />
        {this.state.openAnyproblemModal ?
          <div id='anyProblemDiv' className={'anyprobApp ' + this.state.classCSS}>
            <CloseIcon style={{ cursor: 'pointer', marginTop: '1.5%' }} onClick={() => this.handleCloseAnyProblemModal()} className='' />
            <AnyProblem selectedTab="AnyProblem"></AnyProblem>
          </div>
          : null
        }

        {this.getRoleBasedRoutes()}
        {/* <ChatBotComponent navigationFunction={this.navigateToUserInterestedPage}></ChatBotComponent> */}
        {/* <ScrollToTop /> */}

        {
          showFullApp() ? (<div id="submitFeedback">
            <a style={{ color: 'white' }}
              rel='noopener noreferrer'
              target='_blank'
              href={configData.WEBSITE_URL + "feedback"}
            > feedback</a>
          </div>) : null
        }


        {/* {!this.state.openAnyproblemModal ? 
        : null }  */}
        <Footer />
      </BrowserRouter>
    );

  }
}

export default App;