import React from 'react';
import './Header.scss';
import Logo from './Logo/Logo';
import Navlinks from './Navlinks/Navlinks';
import Navlink from './Navlinks/Navlink/Navlink';
import { Row } from 'react-bootstrap';
import UserProgress from './UserProgress/UserProgress';
import Notifications from './UserProfile/Notifications/Notifications';
import UserProfile from './UserProfile/UserProfile';
import { withNamespaces } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'universal-cookie';
import {
    getCurrentUserRole, getTryThisPinsEnabledFlag, hasAiPlatoUser, isMattUser
    , User_Role, showFullApp, getCurrentUserId, getInstituteId, isDemoUser
} from '../../common/Functions';
import {
    AnyProblem_Challenge_Type,
    Institute
    //, demoUserId 
} from '../../common/Constants';
import { getcoursesbyinstituteid } from "../../common/API"
import TryThisPin from '../Common/TryThisPin/TryThisPin';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Modal, Button } from 'react-bootstrap';
import configData from '../../common/config.json';
import { truncate } from 'lodash';
import AccessLimitationContent from './AccessLimitationContent';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import LogoSign from '../../assets/images/logo-sign.svg';
import {
    Paper,
    Typography
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FeedbackC from '../Feedback/FeedbackC';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const cookies = new Cookies()

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showChallengePin: true,
            isinfoshow: true,
            isMenuExpanded: false,
            selectedmenuitem: 0,
            fromProblemstatement: false,
            othersubjectselected: false,
            urlpathname: "",
            showModal: false,
            menuName: "",
            menuAccessLimitationText: "",
            menuAccessImage: null,
            previousPath: "",
            bannerOpen: false,
            openFeedbackModal: false,
            menuItemData: []
        }
    }
    componentDidMount() {
        if (getCurrentUserId() !== null && getCurrentUserId() !== undefined && getCurrentUserId() !== "") {
            const reqData = { params: { "user_id": getCurrentUserId() } };
            getcoursesbyinstituteid(reqData)
                .then(res => {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        this.setState({ menuItemData: res.data.data }, () => {
                            let menuselecteditem = cookies.get("menuselecteditem")
                            if (menuselecteditem !== undefined && menuselecteditem !== null && menuselecteditem !== "") {
                                let hasExistingSelectedItem = false
                                res.data.data.forEach(element => {
                                    if (element.value === parseInt(menuselecteditem)) {
                                        this.setState({ selectedmenuitem: parseInt(menuselecteditem) });
                                        hasExistingSelectedItem = true;
                                    }
                                });

                                if (!hasExistingSelectedItem) {
                                    if (res.data.data.length > 0) {
                                        this.setState({ selectedmenuitem: res.data.data[0].value })
                                        cookies.set('menuselecteditem', res.data.data[0].value)
                                    }
                                }
                            }
                            else {
                                if (res.data.data.length > 0) {
                                    this.setState({ selectedmenuitem: res.data.data[0].value })
                                    cookies.set('menuselecteditem', res.data.data[0].value)
                                }
                            }

                        })
                    }
                })
                .catch(err => {
                    console.error(err.message)
                    this.setState({ loading: false })
                })
        }

    }


    onMenuItemSelection = () => {
        this.setState({ isMenuExpanded: !this.state.isMenuExpanded })
    }
    getRoleBasedRoutes = () => {
        const userRole = getCurrentUserRole()
        const routesMapping = {
            Admin: <>
                <Navlink isdisabled={false} title={i18next.t("header", { returnObjects: 'true' }).links.curricullum} link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} />
                {getTryThisPinsEnabledFlag() ?
                    <TryThisPin style={{
                        position: 'absolute',
                        right: '186px'
                    }} showPin={this.state.showChallengePin}>
                        <Navlink isdisabled={false} title={i18next.t("header", { returnObjects: 'true' }).links.challenge} link='/curriculum-problems' onClick={() => this.setState({ showChallengePin: false })} onMenuClick={this.onMenuItemSelection} />
                    </TryThisPin>
                    :
                    <Navlink isdisabled={false} title={i18next.t("header", { returnObjects: 'true' }).links.challenge} link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} />
                }
                <Navlink title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} isdisabled={false} />

                <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />

                {/* <Navlink title={i18next.t("header", { returnObjects: 'true' }).links.notes} link='/notes-bookmarks'
                    onMenuClick={this.onMenuItemSelection} isdisabled={false} /> */}
            </>,
            Student: <>
                <Navlink title="AI-Interactive Content" link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                <Navlink title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                {
                    isDemoUser() === "true" ?
                        <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={true} />
                        :
                        <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                }
                {
                    isDemoUser() === "true" ?
                        (<Navlink title="AI: In-Class Q/A" link='/realtimeqa' onMenuClick={this.onMenuItemSelection} isdisabled={true} />)
                        :
                        <Navlink title="AI: In-Class Q/A" link='/realtimeqa' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                }
            </>,
            Tutor: <>
                {/* {
                    hasAiPlatoUser() ? <Navlink title="Homework Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : isMattUser() ? <Navlink title="Mr. Stanger HW Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : <Navlink title="Rice University Homework" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} />
                } */}
                <Navlink isdisabled={false} title="Session History" link='/session-history' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Scheduler" link='/scheduler' onMenuClick={this.onMenuItemSelection} />
            </>,

            ContentAuthor: <>
                <Navlink isdisabled={false} title="AI-Interactive Content" link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} />
                <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                {/* {
                    hasAiPlatoUser() ? <Navlink isdisabled={false} title="Homework Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : isMattUser() ? <Navlink title="Mr. Stanger HW Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : <Navlink title="Rice University Homework" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} />

                } */}

                {/* <Navlink title="Interactive Problems" link='/challenges' />
                <Navlink title={i18next.t("header", { returnObjects: 'true' }).links.curricullum} link='/curriculum-content' /> */}
                <Navlink isdisabled={false} title="Curriculum Map" link='/curriculum-map' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="AI: In-Class Q/A" link='/realtime-qa' onMenuClick={this.onMenuItemSelection} />
            </>,
            Professor: <>
                <Navlink isdisabled={false} title="AI-Interactive Content" link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} />
                <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                {/* {
                    hasAiPlatoUser() ? <Navlink title="Homework Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : isMattUser() ? <Navlink title="Mr. Stanger HW Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : <Navlink title="Rice University Homework" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} />
                } */}

                {/* <Navlink title="Interactive Problems" link='/challenges' />
             <Navlink title={i18next.t("header", { returnObjects: 'true' }).links.curricullum} link='/curriculum-content' /> */}
                <Navlink isdisabled={false} title="AI: In-Class Q/A" link='/realtime-qa' onMenuClick={this.onMenuItemSelection} />


            </>,
            ContentReviewer: <>
                <Navlink isdisabled={false} title="AI-Interactive Content" link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} />
                <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                {/* {
                    hasAiPlatoUser() ? <Navlink title="Homework Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : isMattUser() ? <Navlink title="Mr. Stanger HW Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : <Navlink title="Rice University Homework" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} />
                } */}

                {/* <Navlink title="Interactive Problems" link='/challenges' />  */}
            </>,

            ContentExpert: <>
                <Navlink isdisabled={false} title="AI-Interactive Content" link='/curriculum-content#Video' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Interactive Problems" link='/curriculum-problems' onMenuClick={this.onMenuItemSelection} />
                <Navlink title="Assignments" link='/assignments' onMenuClick={this.onMenuItemSelection} isdisabled={false} />
                {/* {
                    hasAiPlatoUser() ? <Navlink title="Homework Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : isMattUser() ? <Navlink title="Mr. Stanger HW Problems" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} /> : <Navlink title="Rice University Homework" link='/homeworkproblems' onMenuClick={this.onMenuItemSelection} />
                } */}
                <Navlink isdisabled={false} title="AI: In-Class Q/A" link='/realtime-qa' onMenuClick={this.onMenuItemSelection} />
                <Navlink isdisabled={false} title="Scheduler" link='/scheduler' onMenuClick={this.onMenuItemSelection} />
                {/* <Navlink title="Interactive Problems" link='/challenges' /> */}
            </>,
        }
        return routesMapping[userRole]
    }
    handleMenuSelection = (event) => {
        let urlpathname = window.location.pathname
        this.setState({ selectedmenuitem: event.target.value })

        cookies.set('menuselecteditem', event.target.value)

        if (urlpathname.includes("/") || urlpathname.includes("/assignments") || urlpathname.includes("/realtimeqa")) {
            window.location.reload()
        }
    }
    handleOpen = (linkMenu) => {
        this.setState({ showModal: true, menuName: linkMenu })
    }

    handleClose = () => {
        this.setState({ showModal: false, menuName: '' })
    }

    handleSignUpButtonClick = () => {
        window.location.href = configData.APP_URL + 'signup';
    }
    closeBanner = () => {
        this.setState({ bannerOpen: false, openFeedbackModal: false })
    }
    feedbackLinkHandler = () => {
        this.setState({ openFeedbackModal: true, bannerOpen: false })
    }
    handlecloseFeedbackModal = () => {
        this.setState({ openFeedbackModal: false })
    }
    render() {


        return (
            <>
                <Dialog className='feedbackDialog'
                    disableEnforceFocus={true}
                    hideBackdrop={false}
                    open={this.state.openFeedbackModal}
                    onClose={this.handlecloseFeedbackModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ borderBottom: "1px solid #ccc" }}>
                        Feedback
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={this.handlecloseFeedbackModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent sx={{ paddingTop: "1px !important" }}>
                        <DialogContentText id="alert-dialog-description">
                            <FeedbackC onClose={this.handlecloseFeedbackModal}></FeedbackC>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
                <div className='header' id="div_header">

                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <div className="d-flex align-items-center" >
                                    <span className="tourTitle pl-2">aiPlato</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AccessLimitationContent pageName={this.state.menuName} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.handleSignUpButtonClick}>Sign Up</Button>
                        </Modal.Footer>
                    </Modal>

                    <div className='container-fluid'>
                        <Row className='position-relative align-items-center'>
                            <div className='logo'>
                                <Logo />
                            </div>
                            {
                                showFullApp() ? (<div className="course-name-container">

                                    <div className="course-list">
                                        <FormControl variant="standard" sx={{ minWidth: 120, zIndex: "9999 !important" }}>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.selectedmenuitem}
                                                onChange={this.handleMenuSelection}
                                                sx={{
                                                    zIndex: "9999 !important",
                                                    fontFamily: "Roboto !important",
                                                    "& .MuiSvgIcon-root": {
                                                        fill: "#fff !important"
                                                    },
                                                    "& .MuiMenu-root MuiModal-root": {
                                                        zIndex: "9999 !important"
                                                    },
                                                    "& .MuiInputBase-root": {
                                                        "& ::before": {
                                                            borderBottom: "none !important"
                                                        },
                                                    },
                                                    "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
                                                        "& ::before": {
                                                            borderBottom: "none !important"
                                                        },
                                                    },
                                                    "& .MuiInput-root": {
                                                        "& ::before": {
                                                            borderBottom: "none !important"
                                                        },
                                                    },
                                                    "& .MuiSelect-root": {
                                                        "& ::before": {
                                                            borderBottom: "none !important"
                                                        },
                                                    },

                                                    "& .MuiSelect-select": {
                                                        color: "#fff !important",
                                                        "& ::before": {
                                                            borderBottom: "none !important"
                                                        },
                                                    },
                                                    "& .MuiButtonBase-root": {
                                                        fontFamily: "Roboto !important",
                                                    },
                                                    "& .MuiMenuItem-root": {
                                                        fontFamily: "Roboto !important",
                                                    },
                                                    "& a": {
                                                        color: "#fff !important",
                                                        "&:hover": {
                                                            textDecoration: "none"
                                                        },
                                                        "&:active": {
                                                            textDecoration: "none"
                                                        },
                                                        "&:visited": {
                                                            textDecoration: "none"
                                                        }
                                                    },

                                                }}

                                            >
                                                {
                                                    this.state.menuItemData !== undefined && this.state.menuItemData !== null && this.state.menuItemData.length > 0 ? (

                                                        this.state.menuItemData.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item.value} sx={{ zIndex: "9999 !important" }}>
                                                                    <Tooltip title="Pre-Beta Release, AI under training">
                                                                        {
                                                                            item.key === "Maths" || item.key === "Biology" || item.key === "Chemistry" ?
                                                                                (
                                                                                    <Link className="linkcsslight"
                                                                                        to={AnyProblem_Challenge_Type.AnyProblem}
                                                                                    >  {item.key}</Link>
                                                                                ) :
                                                                                (
                                                                                    <Link className="linkcsslight"
                                                                                        to="#"
                                                                                    >  {item.key}</Link>
                                                                                )
                                                                        }

                                                                    </Tooltip>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    ) : null

                                                }

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>) : null
                            }

                            <Navlinks isExpanded={this.state.isMenuExpanded} onMenuClick={this.onMenuItemSelection}>
                                <Navlink title={i18next.t("header", { returnObjects: 'true' }).links.home} link='/' onMenuClick={this.onMenuItemSelection} />
                                {this.getRoleBasedRoutes()}
                            </Navlinks>
                            <div className='content-right'>
                                {getCurrentUserRole() !== "Tutor" ? <UserProgress link='#' /> : null}
                                {(getCurrentUserRole() === User_Role.Tutor || getCurrentUserRole() === User_Role.Student || getCurrentUserRole() === User_Role.Professor) ? <Notifications></Notifications> : null}
                                <UserProfile />
                            </div>
                        </Row>
                    </div >
                </div >
                <TrapFocus open disableAutoFocus disableEnforceFocus>
                    <Fade appear={false} in={this.state.bannerOpen}>
                        <Paper
                            elevation={24}
                            role="dialog"
                            aria-modal="false"
                            aria-label="Cookie banner"
                            square
                            variant="outlined"
                            tabIndex={-1}
                            sx={{
                                position: 'fixed',
                                right: 0,
                                m: 0,
                                p: 2,
                                borderWidth: "1px",
                                borderColor: "black",
                                // borderTopWidth: 1,
                                zIndex: 9999,
                                width: "20% !important",
                                right: "30px",
                                bottom: "40px",
                                borderRadius: "10px",
                                '@media (max-width: 600px)': {
                                    width: '100% !important',
                                    right: "0px !important"
                                },
                                '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                    width: '34% !important'
                                }
                            }}
                        >
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-start"
                                gap={2}

                            >
                                <img src={LogoSign} alt="" className="feebackcompanyLogo" />

                                <Typography sx={{
                                    fontSize: "18px !important"
                                }}>Please help us improve aiPlato.</Typography>



                            </Stack>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="flex-end"
                                gap={2}
                                sx={{ mt: 1 }}
                            >
                                <MuiButton size="small" onClick={this.closeBanner} variant="outlined"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px"

                                    }}>
                                    No Thanks
                                </MuiButton>
                                <MuiButton size="small"
                                    // target='_blank ' 
                                    // href={`${configData.WEBSITE_URL}feedback`} 
                                    onClick={this.feedbackLinkHandler}
                                    variant="contained"
                                    sx={{

                                        textTransform: "capitalize",
                                        fontSize: "15px",
                                        '&:hover': {
                                            color: '#ffffff',

                                        },


                                    }}>
                                    Feedback
                                </MuiButton>

                            </Stack>


                        </Paper>
                    </Fade>
                </TrapFocus>
            </>

        );
    }
}



export default withNamespaces()(withRouter(Header));

