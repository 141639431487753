import React from 'react';
import '../Challenges/TopicChallenges/TopicChallenges.scss';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { User_Role, Problem_source } from '../../common/Functions';
import { imgURL } from '../../common/API';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import parse from 'html-react-parser';
import ImageList from "@material-ui/core/ImageList";

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(3),
            width: "25ch"
        }
    },
    gridList: {
        height: "auto",
        position: "relative",
        overflowY: "unset",
        marginBottom: "10px !important",
        width: "100%",
        cursor: "auto"
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        cursor: "auto",
        width: "97%"
    },
    cardstudent: {
        // minWidth: "300px",
        // height: "370px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#fff",
        cursor: "auto",
        width: "97%"
    },
    carddisabled: {
        // minWidth: "300px",
        // height: "340px",
        margin: "10px",
        borderRadius: "10px",
        //position: "relative",
        overflowY: "unset",
        overflowX: "unset",
        backgroundColor: "#f1f1f1"
    },


    contentimage: {
        padding: "5px",
        borderRadius: "12px",
        width: "100%",
        height: "200px",
        objectFit: "contain",
        cursor: "pointer !important",
        pointerEvents: "fill",
        maxWidth: '350px'
    },
    font16: {
        fontSize: "16px !important",
    },
    datepickercls: {
        marginTop: "0px !important"
    },
    contentclass: {
        padding: "10px !important"
    },
    complexityTooltip: {
        width: "180px !important",
        // width: "92px",
        height: "36px",
        borderRadius: "18px",
        boxShadow: "0 20px 80px 0",
        backgroundColor: "red"
    },
    selectedCard: {
        background: "#b9d7f1"
    }

}));

const MultipartALlProblemsList = (props) => {
    const classes = useStyles();
    let cardClassName = classes.card
    if (props.isSelectedChallenge) {
        cardClassName = cardClassName + " " + classes.selectedCard
    }
    const getColor = (complexity) => {
        if (complexity == "Medium")
            return 'yellow'
        else if (complexity == "Easy")
            return "green"
        else
            return 'orange';
    }
    return (
        <>
            <Card className={cardClassName} style={{ position: 'relative' }} onClick={props.cardClickHandler}>
                <Link
                    className="cardlink"
                    id={"challenge" + props.data.id}
                    to={{
                        pathname: props.challengeURL,
                        state: { prevPath: [props.prevPath], prevNode: [props.prevNode], problemSource: [props.problemSource] }
                    }}
                >
                    <>
                        <CardMedia
                            title="Click here to solve this problem"
                            component="img"
                            image={imgURL + props.data.thumbnail}
                            className={classes.contentimage}
                        />
                    </>
                    <CardContent className={classes.contentclass}>
                        <Typography className='ch-cardstitle'>
                            <OverlayTrigger
                                key="title1"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        {props.data.display_name}
                                    </Tooltip>
                                }
                            >
                                <div style={{ fontSize: "12px", boxShadow: "none", textDecoration: 'none', fontWeight: "600" }}>
                                    <span className="ch-title"> {props.data.display_name}</span>
                                </div>
                            </OverlayTrigger>

                        </Typography>
                        <Row className='status-title-source mt-1 mb-1 font-italic'>
                            <Col sm="12">
                                <div className='statusContainer'>
                                    <div>({props.data.section_name})</div>
                                </div>
                            </Col>
                        </Row>
                        <Typography>
                            <span class="questionfontsmulti">{parse(props.data.question)}</span>
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography className='ch-cardstext'>
                                <div className='challenge-status'>


                                    <Row className='status-title-source'>
                                        <Col sm="12">
                                            <div className='statusContainer'>

                                                <div>Source: {props.data.bookname}</div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div></Typography>

                            <Typography className='ch-cardstext'>
                                <div className='challenge-status'>
                                    <Row className='status-title'>
                                        <Col sm="12">
                                            <div className='statusContainer'>
                                                <OverlayTrigger
                                                    key="bottom"
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            {props.data.type}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span >{props.data.type}
                                                    </span>

                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    key="bottom"
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            {props.data.complexity}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div title={props.data.complexity}
                                                        style={{ marginLeft: '4px', width: '15px', height: '15px', background: getColor(props.data.complexity) }}>

                                                    </div>
                                                </OverlayTrigger>

                                            </div>
                                        </Col>
                                    </Row>

                                </div></Typography>
                        </div>
                    </CardContent>
                </Link>
            </Card>


        </>
    );
}
export default withRouter(React.memo(MultipartALlProblemsList));