import React, { Component, useState } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import { Row, Col, Container } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, filter, isNil } from 'lodash';
import './SubmitQuestion.scss';
import { checksessionsexistsforquestionsubmission, savecalssroomquestionByStudent, getrepresentativequestions, updatetotalcount, getSessionList, getStudentSessionList, studentSubscription } from '../../common/API'
import { getCurrentUserEmail, getCurrentUserId, getCurrentUserName, showFullApp, getInstituteId, isDemoUser, getCurrentUserRole, getMenuSelectedItem } from '../../common/Functions';
import voteUp from "../../assets/images/vote-up.svg";
import voteUpFill from "../../assets/images/vote-up-fill.svg";
import RealtimeQAImg from "../../assets/images/realtimeQAStudent.png";
import Cookies from 'universal-cookie';
import configData from '../../common/config.json';



export class SubmitQuestion extends Component {
    intervalTimer;
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            isQuestionEnabled: false,
            questionText: '',
            showaiQuestions: false,
            session_id: '',
            sessionName: '',
            aiQuestions: [],
            sessionId: '',
            sessionList: [],
            isPreviousSession: false,
            teacherName: '',
            hasValidUser: false,
            instituteName: '',
            demoUserEmail: '',
            sessionDate: ''
        };
    }

    componentDidMount() {
        this.intervalTimer = setInterval(this.getSessionInformation, 2000);
        this.getPreviousSessions()

        if (isDemoUser() !== "true") {
            this.setState({ demoUserEmail: getCurrentUserEmail() })
        }
    }

    getPreviousSessions() {

        const reqData = {
            params: {
                student_user_id: getCurrentUserId(true),
                course_id: getMenuSelectedItem()
            }
        }
        getStudentSessionList(reqData).then(res => {
            this.setState({ sessionList: res.data.sort((a, b) => Date.parse(b.createdate) - Date.parse(a.createdate)) })
        })
    }

    startNewSession() {
        this.setState({
            selected: false,
            isQuestionEnabled: false,
            questionText: '',
            showaiQuestions: false,
            session_id: '',
            sessionName: '',
            aiQuestions: [],
            sessionId: '',
            isPreviousSession: false,
            sessionDate: ''
        });
        this.intervalTimer = setInterval(this.getSessionInformation, 2000);
        var selectElement = document.getElementById("prevSessions");
        if (!isNil(selectElement)) {
            selectElement.selectedIndex = 0;
        }


    }
    getSessionIdFromURL() {
        const cookies = new Cookies();
        const queryParameters = new URLSearchParams(window.location.search.substring(1));
        const sessionId = queryParameters.get("sessionId")
        if (cookies.get('isValid') === 'yes' || getCurrentUserId(true) !== undefined) {
            this.setState({ hasValidUser: true, session_id: (!isNil(sessionId) && !isEmpty(sessionId)) ? Number(sessionId) : '' })
        } else {
            window.location.href = `/login/?fromQue=true&sessionId=${sessionId}`
        }

    }
    checkSession = () => {
        this.getSessionInformation();
        this.setState({ showaiQuestions: true, sessionarea: false })
    }

    getSessionInformation = () => {
        if (this.state.session_id !== "") {
            const reqData = {
                params: {
                    session_id: this.state.session_id,
                    sessionName: this.state.sessionName,
                    student_user_id: getCurrentUserId(true)
                }
            }
            checksessionsexistsforquestionsubmission(reqData).then(res1 => {
                this.setState({ loading: true })
                if (res1.data !== undefined && res1.data.issessionstop === 1) {
                    this.setState({ isQuestionEnabled: true, showaiQuestions: false, teacherName: res1.data.tutor_name })
                }
                if (res1.data.issessionstop === 0) {
                    this.setState({ isQuestionEnabled: true })
                    this.getPreviousSessions()
                    // if (this.state.aiQuestions !== undefined && this.state.aiQuestions.length === 0) {
                    this.getAIQuestions();
                    clearInterval(this.intervalTimer);
                    // }

                }
            })
        }
    }

    getAIQuestions = () => {
        const reqData1 = {
            params: {
                session_id: this.state.session_id,
                sessionName: this.state.sessionName,
                create_date: this.state.sessionDate
            }
        }

        getrepresentativequestions(reqData1).then(res => {
            if (res.data.representative_questions !== undefined) {
                let tempArr = [];
                res.data.representative_questions.forEach(element => {
                    tempArr.push({ theme: element.theme, ques: [...element.children] });
                });
                this.setState({ showaiQuestions: true, aiQuestions: tempArr, loading: false })

            }
        }).catch(err => {
            console.error(err.message)
            this.setState({ loading: false })
            toast.error("Opps! Something wrong", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    submitQuestion = () => {
        if (this.state.questionText.trim() !== '') {
            this.setState({ loading: true })
            const reqData = {
                session_id: this.state.session_id,
                student_name: getCurrentUserName(),
                student_user_id: getCurrentUserId(),
                questions: this.state.questionText
            }
            savecalssroomquestionByStudent(reqData).then(res => {
                if (res.data !== undefined) {
                    this.setState({ loading: false, questionText: "", student_name: "" })
                    toast.success("Your question submission was successfully received.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }).catch(err => {
                console.error(err.message)
                this.setState({ loading: false })
                toast.error("Opps! Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
        } else {
            toast.error("Please provide a question in the text field.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    }

    onVoteButtonClick = (index, id, parentindex) => {
        debugger;
        if (this.state.aiQuestions.length > 0) {
            let questionlistdata = [...this.state.aiQuestions]
            if (this.state.aiQuestions[parentindex].ques != undefined && this.state.aiQuestions[parentindex].ques.length > 0) {
                let tempArr = this.state.aiQuestions[parentindex].ques;


                tempArr[index].vote = tempArr[index].vote === 1 ? 0 : 1;
                let votecheck = tempArr[index].vote;

                if (parseInt(votecheck) === 1) {
                    tempArr[index].total_likes = tempArr[index].total_likes + 1;
                }
                else {
                    tempArr[index].total_likes = tempArr[index].total_likes - 1;
                }

                questionlistdata[parentindex]["ques"] = tempArr


                this.setState({ aiQuestions: questionlistdata })

                const reqData = {
                    id: id,
                    islike: votecheck
                }
                updatetotalcount(reqData).then(res => {
                }).catch(err => {
                    console.error(err.message)
                    toast.error("Opps! Something went wrong", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
            }
        }
    }

    handleChange = (event) => {
        this.setState({ questionText: event.target.value });
    };

    handleClear = () => {
        this.setState({ questionText: '' });
    };

    handleSessionChange = (e) => {
        clearInterval(this.intervalTimer)
        if (Number(e.target.value) > 0) {
            debugger;
            let sessionName = this.state.sessionList.filter((a) => a.sessionid === e.target.value)[0].session_name
            let sessionDt = this.state.sessionList.filter((a) => a.sessionid === e.target.value)[0].createdate
            this.state.session_id = e.target.value
            this.setState({ isQuestionEnabled: true, showaiQuestions: true })
            this.setState({ session_id: e.target.value, sessionName: sessionName, sessionDate: sessionDt, isPreviousSession: true, aiQuestions: [] }, () => {
                this.getAIQuestions();
            })
        }
        else {
            this.setState({ session_id: null, sessionName: null, sessionDate: null, isQuestionEnabled: false, aiQuestions: [], isPreviousSession: false })
        }
    }

    handleInstituteNameChange = (e) => {
        this.setState({ instituteName: e.target.value })
    }

    handleDemoUserEmailChange = (e) => {
        this.setState({ demoUserEmail: e.target.value })
    }

    handleSaveSubscriptionDetails = () => {
        const reqData = {
            user_id: getCurrentUserId(),
            action_type: 'realtimeqa',
            institute_name: this.state.instituteName,
            user_email: this.state.demoUserEmail,
            challenge_content_id: "c0"
        }

        try {
            studentSubscription(reqData).then(res => {
                if (res.status === 200) {
                    this.setState({ instituteName: '', demoUserEmail: '' })
                    toast.success("Details captured successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            })
        }
        catch (e) {
            console.log(e.message)
            toast.error("Some error occurred.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    render() {
        return (

            <>
                {
                    // isDemoUser() !== "true" ?

                    // (getInstituteId() !== "1") ?
                    <>
                        <div>
                            <Container className='' >
                                <Row>
                                    <Col className='col-6 pt-3'>
                                        <p>Welcome to aiPlato. </p>
                                    </Col>
                                </Row>
                            </Container>

                            {!this.state.isQuestionEnabled ?
                                <div className='container-fluid'>
                                    <Container className='secpad'>

                                        <Row style={{ paddingBottom: '10px' }} className='whiteBG'>
                                            <Col className=' col-xl-6 col-12'>
                                                <div className='enter-code-box'>
                                                    <p>Enter your code for new session:</p>
                                                    <input type='number' className="form-control inp mobinpst mb-3 " placeholder="Code"
                                                        onChange={e => this.setState({ session_id: e.target.value })} />
                                                    {/* <img className='physics-img' src={physicsImg} /> */}
                                                    {/* <div className='submitBtn'>
                                                    <button className='btn-blue mt-2' onClick={() => this.checkSession()}>Submit</button>
                                                </div> */}
                                                </div>
                                            </Col>
                                            <Col className='col-xl-6 col-12'>
                                                <div className='prevSessionList'>
                                                    <p>Or select previous session:</p>
                                                    <select value={this.state.session_id} onChange={this.handleSessionChange} id="prevSessions" name="prevSessions" className="form-control selectSessionOptions">
                                                        <option value="-1" selected="selected">
                                                            Select Session
                                                        </option>
                                                        {this.state.sessionList.map((item) =>
                                                            item.issessionstop ? <option value={item.sessionid}> {!isNil(item.session_name) ? item.session_name : ''} (#{item.sessionid})</option> : null
                                                        )}
                                                    </select>
                                                    {this.state.session_id === -1 ?
                                                        <span>{this.state.session_id}</span>
                                                        : null
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div> :
                                <div style={{ height: '100%' }} className='w-100'>
                                    <Container>
                                        <Row className='mb-3 align-items-end'>
                                            <Col className='col-xl-6 col-12'>
                                                <div className='enter-code-box'>
                                                    <p>Select previous session:</p>
                                                    <select value={this.state.session_id} onChange={this.handleSessionChange} id="prevSessions1" name="prevSessions1" className="form-control selectSessionOptions">
                                                        <option value="-1" selected="selected">
                                                            Select Session
                                                        </option>
                                                        {this.state.sessionList.map((item) =>
                                                            item.issessionstop ? <option value={item.sessionid}> {!isNil(item.session_name) ? item.session_name : ''} (#{item.sessionid})</option> : null
                                                        )}
                                                    </select>

                                                </div>

                                                {
                                                    !showFullApp() ? (<div className='titleorcssrq'><span>OR</span></div>) : null
                                                }
                                            </Col>
                                            <Col className=' col-xl-6 col-12'>
                                                <div className='enter-code-box'>
                                                    <div>{showFullApp() ? "Or" : null} <button className={showFullApp() ? "ml-2" : null} onClick={() => this.startNewSession()}>Join New Session</button></div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {!this.state.showaiQuestions ?
                                        <div className='container-fluid'>
                                            <Container>
                                                <Row>
                                                    <Col className='col-12 whiteBG'>
                                                        <p className='inputHead'>Ask a question to your Professor!</p>
                                                        <textarea rows={4} className='form-control' value={this.state.questionText} placeholder='Drop a question here & let aiPlato work its magic!' onChange={this.handleChange}></textarea>
                                                        <div className='submitBtn d-md-flex justify-content-between align-items-center'>
                                                            <small class="text-muted font-italic">Submit a single question at each instance.</small>
                                                            <button className='btn-blue mt-2' onClick={() => this.submitQuestion()}>Submit</button>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Container>
                                        </div> :
                                        <div className='container-fluid' >
                                            <Container className='whiteBG'>

                                                <Row>
                                                    <Col className='col-12'>
                                                        <p className='inputHead'>Representative student questions:</p>
                                                        <hr className='bdrDtted'></hr>
                                                    </Col>
                                                </Row>

                                                {this.state.aiQuestions.map((item, parentIndex) => {
                                                    return <Row className='pb-1 mb-2 border-bottom'>
                                                        <Col className='col-12'>
                                                            <p className='themeTitle'>{item.theme}</p>
                                                        </Col>
                                                        {item.ques.map((que, index) => {
                                                            return <div className='pb-1 col-12'>
                                                                {
                                                                    !this.state.isPreviousSession ?
                                                                        <Col className='col-12 text-left voteToggle'>
                                                                            {que.vote === 1 || que.vote === "1" ? (<ToggleButton
                                                                                value="check"
                                                                                selected={true}
                                                                                onChange={() => this.onVoteButtonClick(index, que.id, parentIndex)}
                                                                            >
                                                                                <img src={voteUpFill}></img>
                                                                            </ToggleButton>) : (<ToggleButton
                                                                                value="check"
                                                                                selected={false}
                                                                                onChange={() => this.onVoteButtonClick(index, que.id, parentIndex)}
                                                                            >
                                                                                <img src={voteUp}></img>
                                                                            </ToggleButton>)
                                                                            }



                                                                            <span className='pr-3'>{que.total_likes}</span>

                                                                        </Col>
                                                                        : null
                                                                }
                                                                {
                                                                    !this.state.isPreviousSession ?
                                                                        <Col className='col-12'>{que.representative_question}</Col>
                                                                        :
                                                                        <Col className='col-12'><b>Question :</b> {que.representative_question}
                                                                            {
                                                                                !isEmpty(que.session_transcript) ?
                                                                                    <div>
                                                                                        <span style={{ fontWeight: 'bold' }}>Answer : </span> {que.session_transcript}
                                                                                    </div> : null
                                                                            }
                                                                        </Col>
                                                                }
                                                            </div>
                                                        })}


                                                    </Row>
                                                })}
                                                {/* <Row>
                                                <Col className='col-12 submitBtn'>
                                                    <button className='btn-viewAll'>View all <span>(32)</span></button>
                                                </Col>
                                            </Row> */}
                                            </Container>

                                        </div>
                                    }


                                </div>}
                            <ToastContainer />
                        </div>
                    </>

                    // :
                    // (<>
                    //     <div>
                    //         <Container>
                    //             <Row>
                    //                 <Col sx={12} >
                    //                     <p className='text-center pt-4' dangerouslySetInnerHTML={{ __html: `For institutional students only - Please contact us for more information.` }}></p>
                    //                     {/* <a href=${configData.APP_URL}signup>subscribe</a> */}
                    //                     <div style={{ width: '50%', left: '35%', position: 'absolute' }}>
                    //                         <label style={{ verticalAlign: 'center', float: 'left', marginRight: '10px', fontSize: "14px" }}>Institute Name</label>
                    //                         <input style={{ float: 'left', width: '50%' }} class="form-control bg-yellow" value={this.state.instituteName} onChange={this.handleInstituteNameChange}></input>
                    //                     </div>
                    //                     <div style={{ marginTop: '45px', float: 'left', width: '50%', left: '35%', position: 'absolute' }}>
                    //                         <label style={{ verticalAlign: 'center', float: 'left', marginRight: '68px', fontSize: "14px" }}>Email</label>
                    //                         <input type='email' style={{ float: 'left', width: '50%' }} class="form-control bg-yellow" value={this.state.demoUserEmail} onChange={this.handleDemoUserEmailChange}></input>
                    //                     </div>
                    //                     <div style={{ marginTop: '100px', marginBottom: '20px', width: '50%', left: '49%', position: 'absolute' }}>
                    //                         <button onClick={this.handleSaveSubscriptionDetails}> Submit </button>
                    //                     </div>
                    //                     <img alt='' style={{ marginTop: '180px', marginBottom: '60px' }} src={RealtimeQAImg} className='w-100' />
                    //                 </Col>
                    //             </Row>
                    //         </Container>
                    //         <ToastContainer />
                    //     </div>

                    // </>)
                }


            </>

        )
    }

}
export default SubmitQuestion;