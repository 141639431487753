import React, { useEffect, useRef, useState } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HintShowIcon from '@mui/icons-material/EmojiObjectsOutlined';
import ShowFullSolutionIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ShowNextStepIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { isNil } from 'lodash';
import {
    fetchChallengeHint,
    fetchChallengeHintnonfrq,
    getNextStep,
    getEntireSolution,
    generateSVGFromMathML
} from '../../common/API';
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { checkEquation, updateWiris } from '../../common/API';
import { getCurrentUserId } from '../../common/Functions';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { substituteText } from '../../common/Constants';
import Aux from '../../hoc/Wrapper';
import Modal from '../../components/Common/ModalComponent/ModalComponent';
import parse from 'html-react-parser';
import MathJaxRenderer from '../../components/Common/MathJaxRenderer/MathJaxRenderer';
import ProgressSidebar from '../../components/Answerboard/ProgressSidebar/ProgressSidebar';
import AskAITutIcon from '../../assets/images/ai-tutor.png';

const CONTENT_TYPE = {
    EQUATION: 'eq',
    TEXT: 'text'
}

let modalBody = null;
let modalTitle = null;
let showButton = false;
let customButton = null;
let draggable = false;

export const obtainSubstitution = (substitution_map) => {
    let sub_keys = []
    let sub_values = []
    let substitution_mapkey = null
    let substitution_mapvalue = null
    let finalsubstitutionslist = ""

    for (let value of Object.values(substitution_map)) {
        sub_values.push(value);
    }
    //to get keys
    for (let key of Object.keys(substitution_map)) {
        sub_keys.push(key)
    }

    if (sub_keys.length > 0 && sub_values.length > 0) {
        substitution_mapkey = sub_keys
        substitution_mapvalue = sub_values
    }
    if (substitution_mapkey !== undefined && substitution_mapkey !== null && substitution_mapkey !== "null" && substitution_mapkey !== 'null') {

        for (var i = 0; i < substitution_mapkey.length; i++) {
            if (finalsubstitutionslist === "") {
                finalsubstitutionslist = substitution_mapvalue[i] + " in place of " + substitution_mapkey[i];
            }
            else {
                finalsubstitutionslist = finalsubstitutionslist + ", " + substitution_mapvalue[i] + " in place of " + substitution_mapkey[i]
            }

        }
    }
    return finalsubstitutionslist;
}

export default function HelperButtonComponent({ stepEquations, updateFeedback, boardId, challengeMapId, handleLoader, problemSource, assignmentId, questionType, askAITutorHandleClick }) {

    const [currentHintIndex, setCurrentHintIndex] = useState(0)
    const [hintRemedialText, setHintRemedialText] = useState("")
    const [hintLastMatchedCheckpointId, setHintLastMatchedCheckpointId] = useState(0)
    const [totalHints, setTotalHints] = useState(-1)
    const [showModal, setShowModal] = useState(false)
    const [nextStepCounters, setNextStepCounters] = useState(0)
    const [totalStepCounter, setTotalStepCounter] = useState(0)
    const [bannerOpen, setBannerOpen] = useState(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
    const [showProgressSidebar, setShowProgressSidebar] = useState(false)
    const [progressbarTitle, setProgressbarTitle] = useState("")
    const [progressSidebarContent, setProgressSidebarContent] = useState("")


    useEffect(() => {
        let requestArr = [];
        stepEquations.forEach((item) => {
            requestArr.push({
                'op': JSON.stringify({ x: 0, y: 0, opid: item.index }),
                'mathML': item.mathml,
                'text': '',
                'lineNumber': item.index,
                'type': CONTENT_TYPE.EQUATION,
                'isFixed': false
            })
        })

        let params = new URLSearchParams();
        params.append('board', boardId);
        params.append('challengeMapId', challengeMapId);
        params.append('userId', getCurrentUserId());
        params.append('contents', JSON.stringify(requestArr));
        params.append('roomId', null);
        params.append('problem_source', problemSource);
        params.append('assignment_id', assignmentId !== 0 ? assignmentId : null);

        updateWiris(params).then(res => {

        }).catch(err => {
            console.error(err.message);

        })
    }, [stepEquations])

    const openModal = (event, modalTitle1, modalBody1, customButton1 = null, showButton1 = false, draggable1 = false) => {
        if (event) {
            event.preventDefault();
        }
        modalBody = modalBody1;
        modalTitle = modalTitle1;
        showButton = showButton1;
        customButton = customButton1;
        draggable = draggable1;
        setShowModal(true)
        updateLoader(false)
    }

    const evaluateWork = () => {
        updateLoader(true)
        checkEquation({
            'board': boardId,
            'userId': getCurrentUserId(),
            'challengeMapId': challengeMapId,
            'hintUsed': 0,
            'is_finalsubmission': 0,
            'problem_source': problemSource,
            'image': null,
            'assignment_id': assignmentId !== 0 ? assignmentId : null

        }).then(async res => {
            if (!isNil(res.data)) {
                updateLoader(false)
                updateFeedback(res.data.result);
            }
            console.log("equation api run successfully.......")
        }).catch(err => {
            updateLoader(false)
            console.error(err.message);
        })
    }

    const showHintHandler = (event) => {
        updateLoader(true)

        if (questionType === "FRQ") {
            let reqData = {
                boardId: boardId,
                user_id: getCurrentUserId(),
                challengeMapId: challengeMapId,
                hintCount: currentHintIndex,
                prevsiousHintRemedialText: hintRemedialText,
                problem_source: problemSource,
                assignment_id: assignmentId !== 0 ? assignmentId : null
            }
            fetchChallengeHint({ params: reqData }).then(res => {
                updateLoader(false)
                console.log("********************HINT")
                console.log(hintLastMatchedCheckpointId)
                console.log(res.data.last_matched_checkpoint_id)

                let finalsubstitutionslist = ""
                if (!isNil(res.data.substitution_map)) {
                    finalsubstitutionslist = obtainSubstitution(res.data.substitution_map)
                }

                if (res.data.hint_remedial_text || res.data.hint_remedial_source) {
                    if (!(hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id)) {
                        console.log("HINT1")
                        setCurrentHintIndex(1)
                        setHintRemedialText(res.data.hint_remedial_text)
                        setHintLastMatchedCheckpointId(res.data.last_matched_checkpoint_id)
                        setTotalHints(res.data.total_hints_for_the_challenge)
                    }
                    else if (hintLastMatchedCheckpointId === res.data.last_matched_checkpoint_id) {
                        console.log("HINT2");
                        setCurrentHintIndex(res.data.total_hints_for_the_challenge > currentHintIndex ? currentHintIndex + 1 : currentHintIndex)
                        setHintRemedialText(res.data.hint_remedial_text)
                        setHintLastMatchedCheckpointId(res.data.last_matched_checkpoint_id)
                        setTotalHints(res.data.total_hints_for_the_challenge)
                    }

                    openHintResponseModel(event, finalsubstitutionslist, res, totalHints)

                    if (this.props.location.state.hintUsed) {
                        this.props.location.state.hintUsed = true;
                    } else {
                        this.props.location.state = {
                            ...this.props.location.state,
                            hintUsed: true
                        }
                    }
                } else if (res.data.message) {
                    toast(res.data.message, {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
            }).catch(err => {
                updateLoader(false)
                console.error(err.message);
                // alert('Error occurred while fetching the hint')
                toast("No more hints found!", {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
        }
        else {
            let reqData = {
                boardId: boardId,
                user_id: getCurrentUserId(),
                challengeMapId: challengeMapId,
                problem_source: problemSource,
                assignment_id: assignmentId !== 0 ? assignmentId : null
            }
            fetchChallengeHintnonfrq({ params: reqData }).then(res => {
                updateLoader(false)

                if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                    let contentdatafinal = []
                    if (res.data[0].remedial_text === "" || res.data[0].remedial_text === null || res.data[0].remedial_text === undefined) {
                        toast("No more hints found!", {
                            type: toast.TYPE.INFO,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    }
                    else {
                        res.data.forEach((rem) => {
                            let contentdata = (
                                <Aux>
                                    <p className='modal-p mt-3' dangerouslySetInnerHTML={{ __html: rem.remedial_text ? rem.remedial_text : '' }}></p>
                                    <a className='mt-2 ' href={rem.remedial_source_url} target='_blank' rel="noopener noreferrer" >{rem.remedial_source_title ? rem.remedial_source_title : rem.remedial_source_url}</a>
                                </Aux>
                            )
                            contentdatafinal.push(contentdata)
                        })
                        let modalBody = (
                            <>{contentdatafinal}</>
                        );
                        openModal(event, 'Hint', modalBody, null, true, true);
                    }

                    if (this.props.location.state.hintUsed) {
                        this.props.location.state.hintUsed = true;
                    } else {
                        this.props.location.state = {
                            ...this.props.location.state,
                            hintUsed: true
                        }
                    }
                } else if (res.data.message) {
                    toast(res.data.message, {
                        type: toast.TYPE.INFO,
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }
            }).catch(err => {
                console.error(err.message);
                updateLoader(false)
                toast("No more hints found!", {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
        }
    }

    const openHintResponseModel = (event, finalsubstitutionslist, res) => {
        let modalB = (
            <Aux>
                <p className='modal-p' dangerouslySetInnerHTML={{ __html: res.data.hint_remedial_text ? res.data.hint_remedial_text : '' }}></p>
                <a className='mt-2' href={res.data.hint_remedial_source} target='_blank' rel="noopener noreferrer" >{res.data.hint_remedial_resource_display_name ? res.data.hint_remedial_resource_display_name : res.data.hint_remedial_source}</a>

                {
                    finalsubstitutionslist !== "" && finalsubstitutionslist !== '' && finalsubstitutionslist !== undefined && finalsubstitutionslist !== null && finalsubstitutionslist !== "null" && finalsubstitutionslist !== "null" ? (
                        <>
                            <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                {substituteText} {finalsubstitutionslist}
                            </div>
                        </>
                    ) : null
                }
            </Aux>
        )

        let customButtons = (
            <div className='modal-button text-center d-flex justify-content-center'>
                <div>
                    <button
                        type='button'
                        className='modal-footer-button btn mx-auto'
                        onClick={closeModal}>OK</button>
                </div>
                <div style={{ marginLeft: "5px" }}>
                    <button
                        tooltip="Get further explanations from the interactive AI tutor."
                        type='button'
                        className='modal-footer-button btn mx-auto'
                        onClick={(e) => askAItutorHandlerhint(e, res.data.hint_remedial_text)}>Ask AI Tutor</button>
                </div>
            </div>
        )

        openModal(event, 'Hint', modalB, customButtons, customButtons ? false : true, true);
    }

    const askAItutorHandlerhint = () => {

    }

    const showNextStepHandler = async (ev) => {
        updateLoader(true)
        setBannerOpen(false)
        setOpenFeedbackModal(false)

        let hasNextStep = true;
        const nextStepCounter = nextStepCounters;
        if (nextStepCounter !== 0) {
            if (nextStepCounter >= totalStepCounter) {
                hasNextStep = false;
                toast('No next step available.', {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1500
                })
            }
        }
        if (!hasNextStep) return;

        setProgressSidebarContent(null)

        let content = null;
        let finalsubstitutionslistnext = ""
        if (boardId && challengeMapId && hasNextStep) {
            let reqData = {
                'userId': getCurrentUserId(),
                'board': boardId,
                'challengeMapId': challengeMapId,
                'currentstepno': nextStepCounter,
                'problem_source': problemSource,
                'assignment_id': assignmentId !== 0 ? assignmentId : null
            }
            let latex = null
            let explanation = null

            await getNextStep(reqData).then(res => {
                updateLoader(false)
                if (res.status === 200) {
                    latex = res.data.nextStep;
                    explanation = parse(res.data.explanation);

                    if (!isNil(res.data.substitute_map)) {
                        finalsubstitutionslistnext = obtainSubstitution(res.data.substitute_map)
                    }

                    setNextStepCounters(nextStepCounters + 1)
                    setTotalStepCounter(res.data.totalstepsno)
                } else if (res.status === 204) {
                    content = <p>No next step to display.</p>
                }

            }).catch(err => {
                console.error(err.message)
            })
            if (latex) {

                content = (
                    <>
                        <span>{explanation}</span>
                        <MathJaxRenderer math={'$$' + latex + '$$'}></MathJaxRenderer>
                    </>
                )
            }

            if (content) {
                setProgressSidebarContent(
                    <Aux>
                        <div className='d-flex flex-column mx-3 my-3'>
                            <h5><u>Next Step</u>:</h5>
                            {content}
                            {
                                finalsubstitutionslistnext !== "" && finalsubstitutionslistnext !== '' && finalsubstitutionslistnext !== undefined && finalsubstitutionslistnext !== null && finalsubstitutionslistnext !== "null" && finalsubstitutionslistnext !== "null" ? (
                                    <>
                                        <div style={{ fontSize: "11px", marginTop: "10px", maxWidth: "500px" }}>
                                            {substituteText} {finalsubstitutionslistnext}
                                        </div>

                                    </>
                                ) : null
                            }
                        </div>
                    </Aux>
                )
            }

            if (progressSidebarContent !== null) {
                updateLoader(false)
                setProgressbarTitle('Show Next Step')
                setShowProgressSidebar(true)
            }
        }
    }

    const showFullSolutionHandler = async () => {
        updateLoader(true)
        setBannerOpen(false)
        setOpenFeedbackModal(false)
        resetNextStepCounter();

        let content = null;
        let remedial_resources = []
        setProgressSidebarContent(null);

        if (challengeMapId) {
            let allEquations = null;
            let challengeType = null;
            let solution = []

            await getEntireSolution({
                params: {
                    'challengeMapId': challengeMapId,
                    'user_id': getCurrentUserId(),
                    'problem_source': problemSource,
                    'boardId': boardId,
                    'assignment_id': assignmentId !== 0 ? assignmentId : null
                }
            }).then(res => {
                challengeType = res.data.challengeType;
                solution = res.data.solution
                if (res.data.challengeType !== 'frq') {
                    let correctAnswer = null;
                    if (res.data.challengeType === 'mcq') {
                        correctAnswer = res.data.solution[0]
                    } else if (res.data.challengeType === 'msq') {
                        correctAnswer = (
                            <ul key='answer'>
                                {res.data.solution.map((ans, index) => <li key={'msq' + index}>{ans}</li>)}
                            </ul>
                        )
                    } else if (res.data.challengeType === 'ranking') {
                        correctAnswer = (
                            <ol>
                                {res.data.solution.map((ans, index) => <li key={'ranking' + index}>{ans}</li>)}
                            </ol>
                        )
                    }
                    content = correctAnswer;
                } else {
                    allEquations = res.data.solution;
                }

            }).catch(err => {
                updateLoader(false)
                console.error(err.message);
            })
            if (allEquations && challengeType === "frq") {
                let imgOfSolution = []
                for (let equation of allEquations) {
                    const latexReqData = {
                        params: {
                            format: 'svg',
                            fontSize: '18px',
                            latex: equation.latex
                        }
                    }
                    const response = await generateSVGFromMathML(latexReqData)
                    if (response.status === 200) {
                        const svg = response.data;
                        imgOfSolution.push({
                            image: svg2img(svg),
                            explanation: equation.explanation
                        });
                    } else {
                        console.error('Unable to convert latex to svg');
                    }
                }
                content = (
                    <Aux>
                        <div className="solutionGraphCotainer">
                            <div>
                                {
                                    solution.map(sol => {
                                        return (
                                            <>
                                                <div className="mt-3 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution green">
                                                    <MathJaxRenderer math={`$$${sol.latex}$$`}></MathJaxRenderer>
                                                </div>
                                                <div className="mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution">
                                                    <MathJaxRenderer math={sol.explanation}></MathJaxRenderer>
                                                </div>
                                                <div className="mt-1 d-flex align-items-center eqfont modalscroll popupSectionTitlemediumlatex, eqpaddingshowsolution">
                                                    {`For more help,  `}<button className='linkbutton' tooltip="Get further explanations from the interactive AI tutor."
                                                        onClick={(e) => askAITutorHandler(e, sol.latex, "correct", sol.mathml)}>
                                                        <img style={{ height: '24px', width: '24px', color: '#4252AF', marginRight: '6px', marginLeft: "5px" }}
                                                            alt="Get further explanations from the interactive AI tutor."
                                                            src={AskAITutIcon} width="20px" height="20px" />Ask AI Tutor</button>
                                                </div>
                                                <div className='titleorcss'></div>
                                            </>
                                        )

                                    })
                                }
                            </div>
                        </div>
                    </Aux>
                )

            } else if (content === null) {
                content = (
                    <p>No solutions found</p>
                )
                // Show no solutions found
            }

            if (challengeType === 'frq') {
                setProgressSidebarContent(
                    <div className='d-flex flex-column mx-3 my-3 solutionContainer'>
                        {content}
                    </div>
                )
            } else {
                setProgressSidebarContent(
                    <div className='d-flex flex-column mx-3 my-3'>
                        <h5 className='mb-2'><u>Correct Answer</u>:</h5>
                        {parse(content)}

                        {remedial_resources !== null && remedial_resources.length > 0 ?
                            <Aux>
                                <h5 className='mb-2 mt-3'><u>Resources</u>:</h5>
                                {remedial_resources.map(resource => {

                                    if (resource.remedial_source_url !== null && resource.remedial_source_url.length > 0) {
                                        return <a href={resource.remedial_source_url} target="_blank" rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: resource.remedial_source_title }}></p></a>
                                    }
                                    return null
                                })}
                            </Aux>
                            : null}
                    </div>
                )
            }

        }
        if (progressSidebarContent !== null) {
            setShowProgressSidebar(true)
            updateLoader(false)
            setProgressbarTitle("Entire Solution");
        }
    }

    const askAITutorHandler = () => {
askAITutorHandleClick()
    }

    const svg2img = svg => {
        var placeholder = document.createElement('div');
        placeholder.innerHTML = svg
        var elem = placeholder.firstChild;

        var xml = new XMLSerializer(svg).serializeToString(elem);
        var svg64 = ''
        try {
            svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
        } catch (e) {
            svg64 = btoa(unescape(encodeURIComponent(xml)))
        }
        var b64start = 'data:image/svg+xml;base64,';
        var image64 = b64start + svg64;
        return image64;
    }

    const resetNextStepCounter = () => {
        setNextStepCounters(0)
        setTotalStepCounter(0)
    }

    const updateLoader = (isLoading) => {
        handleLoader(isLoading)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const closeProgressSidebar = () => {
        setShowProgressSidebar(false)
    }

    return (
        <div className='helpCompBtns'>
            <button className='helpBtns' onClick={evaluateWork}><TaskAltIcon /> Evaluate</button>
            <button className='helpBtns' onClick={showHintHandler}><HintShowIcon /> Hints {questionType === 'FRQ' && totalHints >= 0 ? '(' + currentHintIndex + '/' + totalHints + ')' : ''}</button>
            <button className='helpBtns' onClick={showNextStepHandler}><ShowNextStepIcon /> Show Next Step</button>
            <button className='helpBtns' onClick={showFullSolutionHandler}><ShowFullSolutionIcon /> Show Full Solution</button>
            <button className='helpBtns' onClick={askAITutorHandler}>
                <img style={{ backgroundColor: 'white' }} width="24px" height="24px" alt='Get further explanations from the interactive AI tutor.' src={AskAITutIcon} />
                &nbsp;  Ask AI Tutor</button>

            <ToastContainer />

            <Modal
                show={showModal}
                handleClose={closeModal}
                title={modalTitle}
                body={modalBody}
                showButton={showButton}
                customButton={customButton}
                draggable={draggable} />

            <ProgressSidebar
                show={showProgressSidebar}
                title={progressbarTitle}
                closeSidebar={closeProgressSidebar} >
                {progressSidebarContent}
            </ProgressSidebar>
        </div>
    )
}


