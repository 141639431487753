import React from "react";
import "./Progress.scss";
import Aux from "../../../hoc/Wrapper";

export default class ProgressByWeek extends React.Component {
    componentDidMount() {

        this.drawprogressByWeekchart()
    }

    drawprogressByWeekchart = () => {
        let yAxisData = [];
        this.props.finaldata.forEach((element, index) => {
            yAxisData.push({ value: element.datalist, labelcaption: element.labellist, end_week: element.end_week, start_week: element.start_week })
        })
        var option;

        option = {

            xAxis: {
                type: 'category',
                data: this.props.weekcaption
            },
            yAxis: {
                type: 'value',
                name: '% Completion By Week',
                nameRotate: 90,
                nameLocation: "middle",
                nameGap: 40,  
                axisLine: { onZero: false },                              
                axisLabel: {
                    formatter: function (item) {
                        return item + '%'; // Append '%' to the value
                    },

                },
                boundaryGap: false,

            },
            
            tooltip: {
                trigger: 'item', // Trigger the tooltip on each item
                formatter: function (params) {
                    return `Attempted Problems: ${params.value}% <br/> From: ${params.data["start_week"]} <br/> To: ${params.data["end_week"]}`//`${params.data["labelcaption"]}`
                }
            },
            series: [
                {
                    data: yAxisData,
                    type: 'line',
                    symbolSize: 10,
                    symbol: 'circle',
                    smooth: true,
                    // label: {
                    //     show: true,
                    //     position: 'top',
                    //     formatter: function (params) {
                    //         return params.value + '%';
                    //     }
                    // },
                    lineStyle: {
                        width: 3,
                        shadowColor: 'rgba(0,0,0,0.3)',
                        shadowBlur: 10,
                        shadowOffsetY: 8
                    },
                }
            ]
        };

        var chartDom = document.getElementById('ProgresscompletionByWeek');
        var myChart = window.echarts.init(chartDom);
        option && myChart.setOption(option);
    }
    render() {


        return (
            <Aux>
                <div style={{ width: '600px' }} id='ProgresscompletionByWeek' className='ProgresscompletionByWeekcss'>
                </div>

            </Aux>
        );
    }
}

