import React from "react";
import "./UserProgress.scss";
import Aux from "../../../hoc/Wrapper";
import Progress from "../../Common/Progress/Progress";

import progressImg from "../../../assets/images/icon_progress-update-white.svg";

import { withNamespaces } from "react-i18next";
import i18next from "i18next";

const userProgress = (props) => {
  return (
    <Aux>
      <div className="user-progress">
        <a className="user-progress-anchor" href={props.link} title="Progress Update">
          <label className="user-progress-label">
            {i18next.t("header", { returnObjects: "true" }).progress}
          </label>
          <img src={progressImg} alt="Progress" />
        </a>
        <div className="progress-box">
          <Progress />
          
        </div>
      </div>
    </Aux>
  );
};

export default withNamespaces()(userProgress);
