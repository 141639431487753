import React from 'react';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MuiIconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

const MinimizeDialogCommon = (props) => {


    return (
        <>
            <TrapFocus open disableAutoFocus disableEnforceFocus onClick={props.handleMaximizeDialogue}>
                <Fade appear={false} in={props.minimize}>
                    <Paper
                        elevation={24}
                        role="dialog"
                        aria-modal="false"
                        aria-label="Cookie banner"
                        square
                        variant="outlined"
                        tabIndex={-1}
                        sx={{
                            position: 'fixed',
                            left: 0,
                            m: 0,
                            p: 2,
                            borderWidth: "1px",
                            borderColor: "#ccc",
                            // borderTopWidth: 1,
                            zIndex: 9999,
                            width: "15% !important",
                            left: "1px",
                            bottom: "40px",
                            borderRadius: "2px",
                            cursor: "pointer",
                            '@media (max-width: 600px)': {
                                padding: "5px"
                            },
                            '@media (max-width: 960px)': {
                                padding: "5px"
                            },
                            '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2)': {
                                width: '25% !important'
                            },

                            '@media screen and (min-device-width: 1200px)   and (max-device-width: 1600px)   and (-webkit-min-device-pixel-ratio: 2)  and (min-resolution: 192dpi)': {
                                padding: "5px"
                            }
                        }}

                        onClick={props.handleMaximizeDialogue}
                    >
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            justifyContent="flex-start"
                            gap={2}
                            onClick={props.handleMaximizeDialogue}
                        >
                            <Typography sx={{
                                '@media (max-width: 600px)': {
                                    fontSize: "8px !important"
                                },
                                '@media (max-width: 960px)': {
                                    fontSize: "8px !important"
                                },

                                '@media screen and (min-device-width: 1200px)   and (max-device-width: 1600px)   and (-webkit-min-device-pixel-ratio: 2)  and (min-resolution: 192dpi)': {
                                    fontSize: "8px !important"
                                },
                                fontSize: "0.85rem !important"
                            }}>{props.minimizedailogueText}</Typography>

                            <div style={{ paddingTop: "3px" }} onClick={props.handleMaximizeDialogue}>
                                <Tooltip title="Restore">
                                    <MuiIconButton
                                        aria-label="minus"
                                        onClick={props.handleMaximizeDialogue}
                                        sx={{
                                            position: 'absolute',
                                            right: 5,
                                            top: 8,
                                            color: '#413f3f',
                                        }}
                                    >
                                        <OpenInFullIcon />
                                    </MuiIconButton>
                                </Tooltip>
                            </div>


                        </Stack>



                    </Paper>
                </Fade>
            </TrapFocus >
        </>
    )
}
export default React.memo(MinimizeDialogCommon);