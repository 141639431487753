import React from 'react';
import './Answerboard.scss';
import Aux from '../../hoc/Wrapper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { isNil } from "lodash";
import { formatAnyProblemAPIResponse } from '../../common/Functions'
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';

class CheckMyworkResultC extends React.Component {

    checkifcorrectversionexists = (correct_version_exists, line_match_number, current_line) => {
        if (correct_version_exists) {
            //return <div className='extra_instructions'>(Note: You have already entered the correct version of this step in line # {line_match_number} 🤔)</div>
            if (line_match_number > current_line) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it with <label className="lineNumber">line #{line_match_number}</label>. Great job adapting and finding the right approach! </div>
                //Note: interim incorrect step. You have already entered the correct version of this step in line # {line_match_number}</div>
                //Great work on noticing the mistake in line 1 and correcting it with the appropriate equation in line 2! 
            }
            else if (line_match_number == -1) {
                return <div className='extra_instructions'>Note: Your <label className="lineNumber">line #{current_line}</label> is a bit off, but you've quickly corrected it in your mind as you have progressed to the more advanced step further in your solution. Great job adapting and finding the right approach!</div>
            }
            else {
                return <div className='extra_instructions'>Note: It seems like you've retraced your <label className="lineNumber">line #{current_line}</label>, but you actually had it right the first time in your <label className="lineNumber">line #{line_match_number}</label>. Stick with your initial approach. You're doing great!</div>
            }
            //Let’s assume you know the correct step, since you used the correct version of this step in your line # {line_match_number} in order to arrive at this line 🤔
            // I see that you've attempted this step again, but it looks like you made an error this time. Remember, you already got this step correct earlier. Let's move on to the next step and build upon your previous success!
            // You've returned to step 5, but your initial attempt was actually correct. Trust your instincts and let's keep moving forward to step 6. Keep up the good work!
        }
        return null
    }
    checkMyworkthumbsupdownhandle = (e, thumbpsup) => {
        alert(thumbpsup)
    }
    render() {


        let modalTitle = null;
        let modalBody = [];
        let assumed_content_list = [];
        let stepNo = 0;
        let line_match = 0;
        let isLastStepCorrect = false;
        let correct_version_exists = false;
        let correct_version_line_no = -1;
        let isChallengeCompleted = false;
        let is_partially_complete = false;
        let headerContent = null, subHeaderContent = null, footerContent = null;
        let noMatch = false;
        let correctEq = [];
        let incorrectHints1 = [];
        let knowledgeGraphUiJson = null;
        let ui_json
        let substitution_mapkey = null
        let substitution_mapvalue = null
        let finalsubstitutionslist = ""
        let assumed_content = null
        let propagation = 0
        let feedback_imageurl = ""
        if (this.props.checkmyworkuseddata !== undefined && this.props.checkmyworkuseddata !== null && this.props.checkmyworkuseddata !== "") {
            for (let result of this.props.checkmyworkuseddata) {
                line_match = result.line_match;
                stepNo += 1
                isLastStepCorrect = result.status === 'correct' ? true : false
                correct_version_exists = result.correct_version_exists ? result.correct_version_exists : false
                if (isLastStepCorrect === false)
                    correct_version_exists = result.correct_version_exists ? result.correct_version_exists : false
                if (correct_version_exists) {
                    correct_version_line_no = result.correct_version_line_no;
                }

                if (result.status === 'correct') {
                    const assumed_step_feedback = ["Aha! I see that you might have arrived here by thinking this.", "Great job advancing to this step! Kudos for understanding and applying the concepts effectively to get to this above step!", "I can see how your thought process led you to this advanced step, Let us review once to confirm the understanding of your implicit steps."]
                    let ind_assumed_step_feedback = Math.floor(Math.random() * 3);
                    const correct_step_feedback =
                        ["Well done! You’ve shown correct understanding of the concepts in ", "You've nailed it! Your thinking is right in ", "Spot on! You've chosen the right equation in ", "Fantastic! You've correctly understood the equation in ", "Great job applying the concept and reaching the right outcome in "];
                    let ind_correct_step_feedback = Math.floor(Math.random() * 5);
                    const correct_step_feedback_ending =
                        ["!", "!"];
                    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);

                    modalTitle = 'Correct'
                    // if (result.new) {
                    correctEq.push(result.eq_correct);
                    // }

                    const latexReqData = {
                        params: {
                            format: 'svg',
                            fontSize: '18px',
                            color: Number.isInteger(line_match) ? 'green' : 'black',
                            latex: result.inputLatex
                        }
                    }
                    //for (let i=0; i<2;i++){
                    let equationImg = undefined
                    // const response = await generateSVGFromMathML(latexReqData)
                    // if (response.status === 200) {
                    //   const svg = response.data;
                    //   equationImg = svg2img(svg)
                    // } else {
                    //   console.error('Unable to convert latex to svg');
                    // }
                    if (result.propagation) {
                        propagation = 1;
                    }
                    if (!Number.isInteger(line_match)) {
                        assumed_content = (
                            <div>
                                <div className='mb-3 d-flex align-items-center'>
                                    {equationImg ?
                                        <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                        :
                                        // <MathJax.Context
                                        //     input='tex'
                                        //     onError={(MathJax, error) => {
                                        //         console.warn(error);
                                        //         console.log("Encountered a MathJax error, re-attempting a typeset!");
                                        //         MathJax.Hub.Queue(
                                        //             MathJax.Hub.Typeset()
                                        //         );
                                        //     }}
                                        //     script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML">
                                        //     <MathJax.Node>{result.inputLatex}</MathJax.Node>
                                        // </MathJax.Context>
                                        <>
                                            <MathJaxRenderer math={result.inputLatex}></MathJaxRenderer>
                                            <br />
                                            <MathJaxRenderer math={result.explanation}></MathJaxRenderer>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                        assumed_content_list.push(assumed_content)
                    }

                    let content = (
                        <Aux>
                            {/* <p>{parse(result.explanation)}</p> */}
                            {<hr></hr>}

                            <p className="mb-0">
                                {
                                    <div className="green">
                                        <CheckCircleIcon style={{ fill: "green" }} />
                                        {<span><span>{correct_step_feedback[ind_correct_step_feedback]} </span> <label className="lineNumber"> line #{result.line_match}</label> <span>{correct_step_feedback_ending[ind_correct_step_feedback_ending]}</span></span>}
                                    </div>
                                }
                                <div className='mb-3 d-flex align-items-center'>
                                    {/* <div className='mr-2'>Step : </div> */}
                                    <div>
                                        {equationImg ?
                                            <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                            :
                                            // <MathJax.Context
                                            //     input='tex'
                                            //     onError={(MathJax, error) => {
                                            //         console.warn(error);
                                            //         console.log("Encountered a MathJax error, re-attempting a typeset!");
                                            //         MathJax.Hub.Queue(
                                            //             MathJax.Hub.Typeset()
                                            //         );
                                            //     }}
                                            //     script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML">
                                            //     <MathJax.Node>{result.inputLatex}</MathJax.Node>
                                            // </MathJax.Context>
                                            <>
                                                <MathJaxRenderer math={result.inputLatex}></MathJaxRenderer>
                                                <br />
                                                <MathJaxRenderer math={result.explanation}></MathJaxRenderer>
                                            </>
                                        }
                                    </div>
                                </div>

                            </p>
                            {Number.isInteger(line_match) && assumed_content_list.length > 1 ?
                                <div>
                                    <div className="grey">
                                        {assumed_step_feedback[ind_assumed_step_feedback]}
                                        {assumed_content_list}
                                    </div>
                                </div>
                                : null
                            }
                        </Aux>
                    );
                    content = !Number.isInteger(line_match) ? null : content;
                    assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                    propagation = Number.isInteger(line_match) ? 0 : propagation
                    headerContent = content;

                    // if (!Number.isInteger(line_match)){
                    //   modalBody.push(assumed_content);
                    // }
                    // else{
                    modalBody.push(content);
                    //}

                }
                else if (result.status === 'unrecognized') {
                    const incorrect_step_feedback =
                        ["Oops! Looks like there is a small mistake ", "There's a little error "];
                    let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                    const incorrect_step_feedback_ending =
                        [" but it's an opportunity to learn. Keep pushing!", " but we can fix it together. Mistakes help us learn!", " Let's refine it and move forward!"];
                    let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);


                    const correct_step_feedback =
                        ["Well done! You’ve shown correct understanding of the concepts in ", "You've nailed it! Your thinking is right in ", "Spot on! You've chosen the right equation in ", "Fantastic! You've correctly understood the equation in ", "Great job applying the concept and reaching the right outcome in "];
                    let ind_correct_step_feedback = Math.floor(Math.random() * 5);
                    const correct_step_feedback_ending =
                        ["! ", "! "];
                    let ind_correct_step_feedback_ending = Math.floor(Math.random() * 2);
                    // result.inputLatex = result.inputLatex.replace('\\stackrel{^}', '\\stackrel{\\text{^}}')
                    result.inputLatex = result.inputLatex.replace(/\\stackrel{\^}/g, '\\stackrel{\\text{^}}');
                    headerContent = result.inputLatex ?
                        <> <hr></hr>
                            <div className='mb-2 d-flex align-items-center'>
                                <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}>
                                    <span>
                                        {
                                            result.eq_status === "correct" ?

                                                <CheckCircleIcon style={{ fill: "green" }} />

                                                : <CancelIcon style={{ fill: "red" }} />
                                        }
                                        {
                                            result.eq_status === 'correct' ?
                                                correct_step_feedback[ind_correct_step_feedback] :
                                                incorrect_step_feedback[ind_incorrect_step_feedback]
                                        } in
                                    </span>
                                    <label className="lineNumber"> Line #{result.line_match}</label>
                                    <span>
                                        {
                                            result.eq_status === 'correct' ?
                                                correct_step_feedback_ending[ind_correct_step_feedback_ending] :
                                                incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]
                                        }
                                    </span>

                                    <br /><span className="aiAnswerNote">Note: Feedback under review to ensure its quality.</span></p>
                            </div>
                            {/* <MathJax.Context
                                onError={(MathJax, error) => {
                                    console.warn(error);
                                    console.log("Encountered a MathJax error, re-attempting a typeset!");
                                    MathJax.Hub.Queue(
                                        MathJax.Hub.Typeset()
                                    );
                                }}
                                script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                                input='tex'>

                                <MathJax.Node>{result.inputLatex}</MathJax.Node>
                            </MathJax.Context> */}
                            <MathJaxRenderer math={result.inputLatex}></MathJaxRenderer>
                            <br />
                            <MathJaxRenderer math={result.explanation}></MathJaxRenderer>

                        </>

                        : null
                    footerContent = <p className={result.eq_status === 'correct' ? 'correctRecognized' : 'incorrectRecognized'}><MathJaxRenderer math={formatAnyProblemAPIResponse(result.explanation)}></MathJaxRenderer></p>
                    modalBody.push((
                        <Aux>
                            {headerContent}
                            {footerContent}

                            {/* {'Try again and let me know if you need help. You got this! 💪'} */}
                        </Aux>
                    ));
                }
                else if (result.status === 'incorrect') {
                    const assumed_step_feedback_incorrect = ["It's evident you've got a good handle on the initial steps! Let's confirm your thought process which led you to the above step.", "Looks like you are doing great with interim steps as you are trying to attempt the above advanced step. Let's confirm your thought process which led you to the above step!", "Your ability to attempt this advanced step shows mastery of the interim steps. Let's review your approach once. Keep up the enthusiasm!"]
                    let ind_assumed_step_feedback_incorrect = Math.floor(Math.random() * 3);
                    const assumed_step_propagation_feedback = ["Nice effort in attempting this advanced step.  However, it seems that the following error, in an interim step that you did in your mind, is impacting your result.  Let's pinpoint that error to help our understanding."]
                    const incorrect_step_feedback =
                        ["Oops! Looks like there is a small mistake ", "There's a little error"];
                    let ind_incorrect_step_feedback = Math.floor(Math.random() * 2);
                    const incorrect_step_feedback_ending =
                        [" but it's an opportunity to learn. Keep pushing!", " but we can fix it together. Mistakes help us learn!", ". Let's refine it and move forward!"];
                    let ind_incorrect_step_feedback_ending = Math.floor(Math.random() * 3);
                    let content = null
                    let contentdatafinal = []

                    // let remedial_text_len = 0
                    // let remedial_source_display_name_len = 0
                    // let remedial_text2_len = 0


                    // remedial_text_len = result.remedial_text.length
                    // remedial_text2_len = result.remedial_text2.length

                    // remedial_source_display_name_len = result.remedial_source_display_name.length

                    // debugger;
                    // var max = Math.max(remedial_text_len, remedial_text2_len, remedial_source_display_name_len);

                    if (result.remedial_text) {
                        for (let i = 0; i <= result.remedial_text.length; i++) {
                            let data = result.remedial_text[i]
                            let data1 = result.remedial_text2[i]
                            let data3 = result.remedial_source[i]
                            let data4 = result.remedial_source_display_name[i]
                            if (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '') {
                                data = data.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                data = data.replace(".,", ".")
                            }
                            if (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '') {
                                data1 = data1.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                data1 = data1.replace(".,", ".")
                            }
                            if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                                data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                data3 = data3.replace(".,", ".")
                            }
                            if (data4 && data4 !== null && data4 !== 'null' && data4 !== "null" && data4 != "undefined" && data4 !== "" && data4 !== '') {
                                data4 = data4.toString().replace(/^,/, '').replace(/,\s*$/, "");
                                data4 = data4.replace(".,", ".")
                            }

                            incorrectHints1.push({
                                remedial: true,
                                remedial_text: result.remedial_text[i],
                                remedial_text2: result.remedial_text2[i],
                                remedial_source: result.remedial_source[i]
                            })
                            let contentdata = (
                                <Aux>
                                    {/* <hr className="horizontalRuler" /> */}
                                    {
                                        (data && data !== null && data !== 'null' && data !== "null" && data != "undefined" && data !== "" && data !== '' && data !== ',' && data !== ",") ?
                                            <div className="mb-1 mt-3" dangerouslySetInnerHTML={{ __html: data.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                            : null
                                    }
                                    {
                                        (data1 && data1 !== null && data1 !== 'null' && data1 !== "null" && data1 != "undefined" && data1 !== "" && data1 !== '' && data1 !== ",") ?
                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: data1.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div>
                                            : null
                                    }
                                    {
                                        (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') ?
                                            <a href={data3}
                                                target='_blank' rel="noopener noreferrer">
                                                <div dangerouslySetInnerHTML={{ __html: data4 ? data4.toString().replace(/^,/, '').replace(/,\s*$/, "") : data3.toString().replace(/^,/, '').replace(/,\s*$/, "") }}></div></a>
                                            : null
                                    }

                                </Aux>
                            );
                            contentdatafinal.push(contentdata)
                        }
                        // content = (
                        //   <Aux>
                        //     {(result.remedial_text && result.remedial_text != null) ? <p dangerouslySetInnerHTML={{ __html: result.remedial_text }}></p> : null}
                        //     {(result.remedial_text2 && result.remedial_text2 != null) ? <p dangerouslySetInnerHTML={{ __html: result.remedial_text2 }}></p> : null}
                        //     {(result.remedial_source && result.remedial_source != "null,undefined") ? <a href={result.remedial_source} target='_blank' rel="noopener noreferrer"><p dangerouslySetInnerHTML={{ __html: result.remedial_source_display_name ? result.remedial_source_display_name : result.remedial_source }}></p></a> : null}
                        //   </Aux>
                        // );
                    } else if (result.hint_remedial_text) {
                        let data3 = result.hint_remedial_text
                        if (data3 && data3 !== null && data3 !== 'null' && data3 !== "null" && data3 != "undefined" && data3 !== "" && data3 !== '') {
                            data3 = data3.toString().replace(/^,/, '').replace(/,\s*$/, "");
                        }
                        incorrectHints1.push({
                            hint: true,
                            hint_remedial_text: data3
                        })
                        content = (
                            <Aux>
                                {data3 ? <div>{data3}</div> : null}
                            </Aux>
                        );
                    } else {
                        if (result.symbolsDiff) {
                            if (result.symbolsDiff.length > 0) {
                                content = <>
                                    {content}
                                    <p className="mb-1 mt-3">Equations you entered don’t match expected steps.</p>
                                    <p className="mb-1 mt-3">Unexpected Symbols or Subscripts: <em>{result.symbolsDiff}</em></p>
                                    <p className="mb-2">Expected Symbols or Subscripts: <em>{result.expectedSymbols}</em></p>
                                    <a href='/docs/Ref_Symbols_and_Equations_AP_1999_C_Fr.pdf' target='_blank' rel="noopener noreferrer"><p>Physics Symbols and Equations Reference Sheet</p></a>
                                </>
                            }
                        }

                    }


                    let equationImg = undefined


                    subHeaderContent = !result.no_match ? <>
                        {correctEq.length === 0 ?
                            <p className='sidebar-heading mb-2'>You made this mistake:</p>
                            :
                            <p className='sidebar-heading mb-2'><span className='text-dark'>Seems like you understood this.</span><br /> Then you made this mistake:</p>}
                    </> : null

                    let lastElement3 = this.checkifcorrectversionexists(correct_version_exists, correct_version_line_no, line_match)
                    headerContent = <>
                        {<hr></hr>}
                        {result.no_match ?
                            <>
                                {!isNil(result.valid) && !result.valid ?
                                    <p>Your line {result.line_match} is not anything I was expecting. Please correct if you mean to enter an equation.</p>
                                    : <p>Line #{result.line_match}:
                                        <br></br><br></br>
                                        {result.explanation}
                                    </p>
                                }
                                <div>
                                    <br />
                                    {''}
                                    {equationImg ?
                                        <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                        :
                                        // <MathJax.Context
                                        //     onError={(MathJax, error) => {
                                        //         console.warn(error);
                                        //         console.log("Encountered a MathJax error, re-attempting a typeset!");
                                        //         MathJax.Hub.Queue(
                                        //             MathJax.Hub.Typeset()
                                        //         );
                                        //     }}
                                        //     script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                                        //     input='tex'>
                                        //     <MathJax.Node>{result.inputLatex}</MathJax.Node>
                                        // </MathJax.Context>
                                        <MathJaxRenderer math={result.inputLatex}></MathJaxRenderer>

                                    }
                                </div>

                            </>
                            :
                            <>

                                {lastElement3}
                                <div className="red">
                                    {/* <CancelIcon style={{ fill: "red" }} /> {incorrect_step_feedback[ind_incorrect_step_feedback] + 'in line # ' + line_match + ' ' +incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]}                   */}
                                    {correct_version_exists ? null : <> <CancelIcon style={{ fill: "red" }} /> <span><span>{incorrect_step_feedback[ind_incorrect_step_feedback]} in </span><label className="lineNumber"> line #{line_match}</label><span>{incorrect_step_feedback_ending[ind_incorrect_step_feedback_ending]}</span></span> </>}
                                    <div>
                                        <br />
                                        {'Incorrect Step: '}
                                        {equationImg ?
                                            <img src={equationImg} alt={"Step " + stepNo + " Equation"} />
                                            :
                                            // <MathJax.Context
                                            //     onError={(MathJax, error) => {
                                            //         console.warn(error);
                                            //         console.log("Encountered a MathJax error, re-attempting a typeset!");
                                            //         MathJax.Hub.Queue(
                                            //             MathJax.Hub.Typeset()
                                            //         );
                                            //     }}
                                            //     script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-MML-AM_CHTML"
                                            //     input='tex'>
                                            //     <MathJax.Node>{result.inputLatex}</MathJax.Node>
                                            // </MathJax.Context>
                                            <MathJaxRenderer math={result.inputLatex}></MathJaxRenderer>

                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </>
                    noMatch = result.no_match ? true : false;
                    // footerContent = content
                    footerContent = <>
                        <div className="red">
                            {contentdatafinal} <br></br>
                            {content} <br></br>
                            {Number.isInteger(line_match) && assumed_content_list.length > 1 && !correct_version_exists ?
                                <div>
                                    <div className="grey">
                                        {propagation == 1 ? assumed_step_propagation_feedback[0] : assumed_step_feedback_incorrect[ind_assumed_step_feedback_incorrect]}
                                        {assumed_content_list}
                                    </div>
                                </div>
                                : null
                            }
                        </div>


                    </>
                    assumed_content_list = Number.isInteger(line_match) ? [] : assumed_content_list
                    propagation = Number.isInteger(line_match) ? 0 : propagation

                    modalBody.push((
                        <Aux>
                            {headerContent}
                            {footerContent}
                            {/* {content} */}
                        </Aux>
                    ));
                    // break
                } else if (result.status === 'error') {
                    // show dialog for video conf./hint
                    setTimeout(() => { this.props.showHintAndConferenceDialog() }, 1000)
                    headerContent = result.inputLatex ?
                        <div className='mb-2 d-flex align-items-center'>
                            <p>You've made an error at line {result.line_match}</p>
                            <div className='mr-2'>Erroneous Step: </div>

                        </div>
                        : null
                    footerContent = <p>{result.message}</p>
                    modalBody.push((
                        <Aux>
                            {headerContent}
                            {footerContent}
                            {'Try again and let me know if you need help. You got this!'}
                        </Aux>
                    ));
                    console.log(result.message);
                    break
                }

                if (result.is_partially_complete === true) {
                    is_partially_complete = true
                    if (stepNo === this.props.checkmyworkuseddata.length) {
                        modalBody.push((
                            <h5><hr></hr><div className='green'>You've achieved one of the right answers! Keep honing your skills to discover
                                the second solution. Excellent progress!
                            </div></h5>
                        ));
                    }
                }
                if (result.final === true) {
                    isChallengeCompleted = true
                    if (stepNo === this.props.checkmyworkuseddata.length) {
                        modalBody.push((

                            <h5><hr></hr><div className='green'>Problem Completed! It's clear that you've been putting in the effort to understand the concepts.</div></h5>
                        ));
                    }
                }

            }
        }
        return (
            <>
                {
                    this.props.checkmyworkuseddata !== undefined && this.props.checkmyworkuseddata !== "" && this.props.checkmyworkuseddata !== "[]" && this.props.checkmyworkuseddata.length > 0 ?
                        (
                            <>
                                {modalBody}
                            </>
                        ) : null

                }
            </>

        )
    }
}
export default React.memo(CheckMyworkResultC);