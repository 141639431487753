import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import './UserProfile.scss';
import user from '../../../assets/images/user.png';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { getCurrentUserName, getCurrentUserRole, User_Role, getCurrentUserId, APP_URL, getCurrentUserEmail, WEBSITE_URL } from '../../../common/Functions';
import Cookies from 'universal-cookie';
import { getuserroles } from '../../../common/API'
import Select from 'react-select';
import { withNamespaces } from 'react-i18next';
import Feedback from "../../Feedback/Feedback";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a style={{ boxShadow: "none", textDecoration: 'none' }}
        href="#"
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        Change Role
    </a>
));
const UserProfile = (props) => {
    const username = getCurrentUserName();
    const userRole = getCurrentUserRole();
    const [userrolelist, setuserrolelist] = useState([])
    const [selected, setSelected] = useState({ key: userRole, value: userRole });
    const [show, setshow] = useState(false);
    useEffect(() => {
        if (userRole !== User_Role.Student || userRole !== User_Role.Tutor) {
            if (getCurrentUserId() !== undefined) {
                const reqData = { params: { 'user_id': getCurrentUserId() } }
                getuserroles(reqData).then(res => {
                    if (res.status === 200) {
                        if (res.data.roleslist.length > 0)
                            setuserrolelist(res.data.roleslist)
                    }
                })
            }

        }
    }, [])
    // const handleSelectmodal = (selected) => {
    //     setSelected(selected);
    // };
    const handleSelect = (selected) => {
        setSelected(selected);
	const cookies = new Cookies()
        cookies.remove('role');
        cookies.set('role', selected.key, { path: '/', domain: window.location.hostname, maxAge: 7200 });
        //window.location.reload(false);

        toast("Your role changed successfully.", {
            type: toast.TYPE.INFO,
            position: toast.POSITION.TOP_CENTER
        })
        window.location.href = "/"
	//end stream
        let domainName = (document.domain.match(/[^\.]*\.[^.]*$/)[0]);
        console.log(domainName)
        cookies.set('role', selected.key, { path: '/', domain:domainName, maxAge: 7200 });
        window.location.reload(false);
	//stashed
    };
    const logoutUser = (e) => {
        e.preventDefault()
        const cookies = new Cookies();
        console.log(cookies.getAll());
        Object.keys(cookies.getAll()).forEach(cookie => {
            cookies.remove(cookie)
        })
        window.location.href = WEBSITE_URL;//process.env.REACT_APP_BASE_URL;
    }
    const handlemodalopen = () => {
        setshow(true)
    }
    const handleClose = () => {
        setshow(false)
    }

    // const handlesave = () => {
    //     const cookies = new Cookies()
    //     cookies.remove('role');
    //     cookies.set('role', selected.key, { path: '/', domain: window.location.hostname, maxAge: 7200 });
    //     setshow(false)
    //     window.location.reload(false);
    // }
    return (
        <>
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change The Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>                   
                    <Select
                        getOptionLabel={option => option.key}
                        getOptionValue={option => option.value}
                        onChange={handleSelectmodal}
                        options={userrolelist}
                        value={selected}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlesave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Feedback />
                </Modal.Body>
            </Modal>

            <div className='user-profile'>
                <a href={props.link} title={username}>
                    <label className="user-profile-label">{username}</label>
                    <img src={user} alt='User' />
                </a>
                <div className='user-profile-menu'>

                    <Link to='/profile'>Profile</Link>
                    <Link to='/changepassword'>Change Password</Link>
                    {
                        getCurrentUserEmail() === "professor@aiplato.ai" ? (
                            <>
                                <Link to='/datacollection'>Data Collection</Link>
                                <Link to='/interactionEvaluation'>Evaluate - aiPlato vs Other Platforms</Link>
                            </>
                        )
                            : null
                    }
                    <Link onClick={handlemodalopen} to='#'>Submit Feedback</Link>
                    <Link to='/notes-bookmarks'>Notes / Bookmarks</Link>
                    <Link className='disabled-link' to='#'>Messages</Link>
                    {
                        userRole === User_Role.Professor ? (
                            <Link to='/manage-class'>Manage Class</Link>) : null
                    }
                    {
                        userRole !== User_Role.Admin ?
                            (
                                <>
                                    {/* <Link to='#' onClick={handlemodalopen}>Change Role</Link> */}
                                    <Dropdown style={{ textDecoration: "none !important", padding: "0px !important" }}>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="" style={{ minWidth: "0", border: "1px solid #ccc !important" }}>
                                            <Select
                                                getOptionLabel={option => option.key}
                                                getOptionValue={option => option.value}
                                                onChange={handleSelect}
                                                options={userrolelist}
                                                value={selected}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            ) : null
                    }
                    <Link to='#' onClick={logoutUser}>Logout</Link>
                </div>
            </div >

            <ToastContainer />
        </>
    );
};

export default withNamespaces()(UserProfile);
