import React, { useState, useEffect, useRef } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    Controls,

} from "react-flow-renderer";
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';

import { getTextWidth } from 'get-text-width';
import { filter, isNil, clone, isEmpty, isNull } from "lodash";

import EquationNode from "../../components/SolutionGraph/CustomNodes/EquationNode";
import "../../components/SolutionGraph/index.css";
//import useUndo from "./use-history";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';

import { Challenge_status, getCurrentUserRole, User_Role } from '../../common/Functions';
import { Link } from 'react-router-dom';
import {
    fetchResources,
    getChallengenodes,
    getchallengesubsection,
    updateResources,
    updateChallengenodes,
    SaveAllResource,
    getHintText,
    checkCombination,
    getresourcetopic,
    getChallengeMCQ,
    deleteChallengeMCQ,
    UpdateChallengeMCQ,
    generateLatexFromMathML,
    getSympyEquation,
} from "../../common/API"
import dagre from 'dagre';
import { makeStyles } from "@material-ui/core/styles";
const nodeBackground = "#bfd4ff";
const nodeTypes = {
    nodeWithOnlyText: EquationNode
};

const WIRIS_FOR = {
    EQUATION: 'equation',
    UNIT: 'unit'
}
const BASIC_ERROR = 'basic';
const AUTHOR_COMBINATION = 'author_combination';
const SYSTEM_COMBINATION = 'system_combination';
const PROPAGATION_ERROR = 'propagation_error';
const PRIMARY = 'primary';
const NON_PRIMARY = 'nonPrimary';
const OPTIONAL = 'optional';
const NODE_BACKGROUND = [{ type: PRIMARY, color: nodeBackground }, { type: NON_PRIMARY, color: nodeBackground }, { type: OPTIONAL, color: "#cccccc" }];
const INCORRECT_COLOR = "#ff6666";
const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";
let requisiteKnowledgeResponse;
let initialElements = [];
//let isAutoPositioningRequired = false;
let maxNodeId = 0;
let maxOptionId = 0;
let challengeType = "";
let challengeId = ""
let isCorrectFlag = false;
let addnewAnswer = false;
let correctOptionId = 0;
let correctCount = 0;
let mcqArray = [];
let optionIds = [];
let mcqAddNewObj = {
    optionId: "",
    tempOptionId: "",
    challenge_id: "",
    optionText: "",
    requisiteKnowledgeText: "",
    requisiteKnowledge: '',
    isCorrect: true,
    isCorrectText: "",
    errorRootCause: "",
    resourceids: "",
    remedialText: "",
    remedialResources: "",
    remedialResourcedisplay: "",
    isRemedialResoueReusable: false,
    remedialResourceURLs: "",
    hints: [],
    subsection_id: "",
};
let mcqDataObject = {
    optionId: "",
    tempOptionId: "",
    challenge_id: "",
    optionText: "",
    requisiteKnowledgeText: "",
    requisiteKnowledge: '',
    isCorrect: true,
    isCorrectText: "",
    errorRootCause: "",
    resourceids: "",
    remedialText: "",
    remedialResources: "",
    remedialResourcedisplay: "",
    isRemedialResoueReusable: false,
    remedialResourceURLs: "",
    hints: [],
    subsection_id: "",
    resourcetopicmasterid: "",
};

let data = {
    label: `Add Equation`,
    equation: "",
    background: nodeBackground,
    isRequired: true,
    isPrimary: true,
    isnonPrimary: false,
    isOptional: false,
    isNodeCorrect: true,
    explanation: "",
    requisiteKnowledge: "",
    hintText: "",
    remedialText: "",
    remedialResources: "",
    remedialResourcedisplay: "",
    isRemedialResoueReusable: false,
    remedialResourceURLs: "",
    incorrect_variants: "",
    importance: PRIMARY,
    errortype: BASIC_ERROR,
    parent: "",
    child: "",
    optional_parent: "",
    optional_child: "",
    border: "#eeeeee",
    hints: [],
    equation_comment: "",
    equation_latex_comment: "",
    explanation_comment: "",
    mathml: '',
    unitMathml: '',
    is_ue: false
};
let lastSelectedElement;
let hasWiris = false;
let wirisEditor;
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const Input = (props) => (
    <components.Input {...props} readOnly={props.selectProps.isReadOnly} />
);


const useStyles = makeStyles((theme) => ({

    summarycss: {
        backgroundColor: "#e4e3e3 !important"
    }

}));


const GraphFlow = (props) => {
    const params = useParams();
    const [hasWiris, setHasWiris] = useState(false);
    const [wirisSize, setWirisSize] = useState('medium');
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [elements, setElements] = useState(initialElements);
    const [aftersave, setaftersave] = useState(false)
    const [nodeName, setNodeName] = useState("");
    const [nodeMathml, setNodeMathml] = useState("");
    const [unitMathml, setUnitMathml] = useState("");
    const [unitLatex, setUnitLatex] = useState("");
    const [equation, setEquation] = useState("");
    const [wirisOpenFor, setWirisOpenFor] = useState(WIRIS_FOR.EQUATION);
    const [unit, setUnit] = useState("");
    const [points, setPoints] = useState(0);
    const [decimalPoints, setdecimalPoints] = useState(2);
    const [hints_perc, sethints_perc] = useState(0);
    const [resourceids, setresourceids] = useState("");
    const [nodeErrorType, setErrorType] = useState(BASIC_ERROR);
    const [nodeImportance, setNodeImportance] = useState(PRIMARY);
    const [nodeExplanation, setNodeExplanation] = useState("");
    const [explanationEditor, setExplanationEditor] = useState(null);
    const [IsCorrect, setIsCorrect] = useState(true);
    const [isloading, setisloading] = useState(false);
    const [nodeRootCause, setNodeRootCause] = useState([]);
    const [nodehintRemedialText, setNodehintRemedialText] = useState("");
    const [hintEditor, setHintEditor] = useState(null);
    const [remedialText, setRemedialText] = useState("");
    const [nodehintRemedialResource, setNodehintRemedialResource] = useState("");
    const [nodeBg, setNodeBg] = useState(nodeBackground);
    const [nodeX, setNodeX] = useState("");
    const [nodeY, setNodeY] = useState("");
    const [element, setElement] = useState({});
    //const [nodeHidden, setNodeHidden] = useState(true);
    const [hideText1, setHideText1] = useState(false);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(200);
    const [border, setRadius] = useState(nodeBackground);
    const [nodeRemedialResourcedisplay, setNodeRemedialResourcedisplay] = useState("");


    const [isreusable, setIsReusable] = useState(false);
    const [hidden, setHidden] = useState(false);
    // Edge Properties
    const [edgeLabel, setEdgeLabel] = useState("Edge Label");
    const [edgeLabelStyle, setEdgeLabelStyle] = useState({});
    const [edgeType, setEdgeType] = useState("default");
    const [edgeAnimated, setEdgeAnimated] = useState(true);
    // const [edgeStyle, setEdgeStyle] = useState({});
    const [edgeArrowHead, setEdgeArrowHead] = useState("arrow");
    const [showEdgeProperties, setShowEdgeProperties] = useState(false);

    //const [addresource, setAddResource] = useState(false)
    const [addresourcelable, setAddresourcelable] = useState("Add Hint");
    //const [hinttext, setHintText] = useState("Hint Text")

    const [remedialResourceOption, setRemedialResourceOption] = useState([]);
    const [remedialResourceSelected, setRemedialResourceSelected] = useState([]);

    const [hintTextOptions, setHintTextOptionsOptions] = useState(null);
    const [hintTextSelected, setHintTextSelectedOptions] = useState([]);

    const [errorRootCauseOption, setErrorRootCauseOption] = useState(null);
    const [errorRootCauseValue, setErrorRootCauseValue] = useState(null);


    const [resourcetopicvalue, setresourcetopicvalue] = useState(null);
    const [resourcesvalue, setresourcesvalue] = useState(null);
    const [resourcesdata, setresourcesdata] = useState([])
    const [noderesourcetopicsubsectionid, setnoderesourcetopicsubsectionid] = useState("");
    const [resourcenamedisabled, setresourcenamedisabled] = useState(false)
    const [resourceurldisabled, setresourceurldisabled] = useState(false)
    const [isnewmode, setisnewmode] = useState(1)
    const [resourcetopicId, setresourcetopicId] = useState("");
    const [neweditbuttonvisible, setneweditbuttonvisible] = useState(false)
    const [addeditmode, setaddeditmode] = useState("Add")

    const [resourcetopicdisbaled, setresourcetopicdisbaled] = useState(false)


    const [defualtRootCauseOption, setDefaultErrorRootCauseOption] = useState(null);
    const [newnodeid, setNewNodeId] = useState(null);
    const [nodeHints, setNodeHints] = useState([]);
    const [dataSizeLabel, setShowDataSizeLabel] = useState(SHOW_MORE)
    const [layoutDirection, setLayoutDirection] = useState('')
    const [seletedResourceIdForEdit, setSeletedResourceIdForEdit] = useState("")

    const [showhintmodel, setShowhintmodel] = useState(false)
    const [informationtext, setInformationtext] = useState(false)
    const [statustext, setStatusText] = useState(false)
    const [opensavehintmodal, setopensavehintmodal] = useState(false)

    const [editonlyfornode, seteditonlyfornode] = useState(false)

    const [challengedata, setChallengeData] = useState({
        challenge_state: null
        , user_id: null
        , reviewer_user_id: null
        , expert_user_id: null
        , modified_user_id: null
    });

    const [equation_comment, setequation_comment] = useState("")
    const [equation_latex_comment, setequation_latex_comment] = useState("")
    const [explanation_comment, setexplanation_comment] = useState("")
    //const [hints_comment, sethints_comment] = useState("")
    const [showcommentmodal, setshowcommentmodal] = useState(false)

    const [modelheadertitle, setmodelheadertitle] = useState("")
    const [fieldname, setfieldname] = useState("")
    const [reviewcomments, setreviewcomments] = useState("")

    const [equation_comment_color_change, setequation_comment_color_change] = useState(false)
    const [equation_latex_comment_color_change, setequation_latex_comment_color_change] = useState(false)
    const [explanation_comment_color_change, setexplanation_comment_color_change] = useState(false)
    //const [hints_comment_color_change, sethints_comment_color_change] = useState(false)
    const [showcommentsfields, setshowcommentsfields] = useState(false)

    // const [equation_previous_comment, setequation_previous_comment] = useState("")
    // const [equation_latex_previous_comment, setequation_latex_previous_comment] = useState("")
    // const [explanation_previous_comment, setexplanation_previous_comment] = useState("")

    const [isread_equation_comment, setisread_equation_comment] = useState(false)
    const [isread_equation_latex_comment, setisread_equation_latex_comment] = useState(false)
    const [isread_explanation_comment, setisread_explanation_comment] = useState(false)
    //const [userrole, setUserRole] = useState(getCurrentUserRole())
    const [buttondisabled, setButtondisabled] = useState(false)
    const [isHintDetailsSelected, setIsHintDetailsSelected] = useState(false)

    const [hintmodalheadertext, setHintmodalheadertext] = useState("")
    const [requiredmessage, setrequiredmessage] = useState(false)

    const [isDisabledNotForUE, setisDisabledNotForUE] = useState(false);

    ///////////////// MCQ Changes New Functions START ////////////////////

    const [nodeRootCauseText, setRequisiteText] = useState([]);
    const [newoptionId, setnewoptionId] = useState(null);
    const [tempOptionId, setTempOptionId] = useState(null);
    const [isCorrectText, setIsCorrectText] = useState("");
    const [noderesourcetopicid, setnoderesourcetopicid] = useState("");
    const [expand, setExpand] = useState(true);
    const [openHideMCQGrid, setOpenMCQGrid] = useState(true);
    const [openHideReactFlow, setOpenHideReactFlow] = useState(true);
    const [hideNodeFieldsForMCQ, setHideNodeFieldsForMCQ] = useState(true);
    const [showNodeFieldsForMCQ, setShowNodeFieldsForMCQ] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState();
    const [hintpershow, sethintpershow] = useState(true)


    const [expanded, setExpanded] = useState(false)
    const [uejsondata, setuejsondata] = useState([])
    const [rIncorrectjsondata, setRIncorrectjsondata] = useState([])
    const [isUEdragdrop, setisUEdragdrop] = useState(false)
    const [uejsonelement, setuejsonelement] = useState([])
    const [draggedUEIds, setdraggedUEIds] = useState([])

    const [savegraphDisabled, setsavegraphDisabled] = useState(false)




    const reactFlowWrapper = useRef(null);
    // const [toggled, setToggled] = useState(false);
    // const handleToggleSidebar = (value) => {
    //     setToggled(value);
    // };







    const onConnect = (params) => {
        debugger;
        if (hasCyclicDependency(params.source, params.target)) {
            toast('Please check, there is chance of cyclic reference.', {
                type: toast.TYPE.WARNING,
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
        setElements((els) =>
            addEdge(
                {
                    ...params,
                    type: "default",
                    animated: true,
                    style: { stroke: IsCorrect ? "#000000" : INCORRECT_COLOR, cursor: "pointer" },
                    label: props.readOnly ? "" : "Click to select",
                    labelStyle: {
                        fill: "#000",
                        fontWeight: "100",
                        fontSize: "12px",
                        cursor: "pointer"
                    },
                    arrowHeadType: "arrow"
                },
                els
            )
        );
    }


    const hasCyclicDependency = (source, target) => {
        if (props.readOnly) return;
        let hasDependency = false;
        let node = filter(elements, { "id": source });
        const pc = node[0]['data']['pc'];
        if (pc !== '' && !isNil(pc)) {
            const parents = pc.split(",");
            parents.forEach((id) => {
                if (id === target) {
                    hasDependency = true;
                }
            })
        }
        if (!hasDependency) {
            let targetNode = filter(elements, { "id": target });
            targetNode[0]['data']['pc'] = node[0]['data']['pc'] !== '' ? node[0]['data']['pc'] + "," + source : source;

        }
        return hasDependency
    }


    const onLoad = (_reactFlowInstance) => {

        if (!isNil(params.challengeId)) {
            if (params.challengeId.includes("-")) {
                challengeId = params.challengeId.split('-')[0];
                challengeType = params.challengeId.split('-')[1];
            }
            else {
                challengeId = params.challengeId
                challengeType = "challengeType"
            }

        }
    }
    const onDrop = (event) => {

        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData("application/reactflow");
        const design = event.dataTransfer.getData("application/reactflow2");
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top
        });



        maxNodeId = maxNodeId + 1;
        let nwnodeid = maxNodeId;

        if (!isUEdragdrop) {
            setElements((es) =>
                es.concat({
                    id: nwnodeid.toString(),
                    isue: false,
                    type,
                    position,
                    data: { ...data, hints: [] },
                    hidden: true,
                    originalPosition: position,
                    style: {
                        wordWrap: "break-word",
                        textAlign: "center",
                        borderColor: "#00008B",
                        maxHeight: "1400px"
                    }
                })
            );
            setsavegraphDisabled(false)
        }
        else {

            if (uejsonelement !== undefined && uejsonelement !== null && uejsonelement !== "")
                setdraggedUEIds(draggedUEIds => [...draggedUEIds, parseInt(uejsonelement.id)]);

            let data1 = uejsonelement

            setElements((es) =>
                es.concat({
                    id: nwnodeid.toString(),
                    type: data1.type,
                    position: position,
                    data: { ...data1.data, hints: [], displaynodeid: "N" + maxNodeId.toString() },
                    hidden: data1.hidden,
                    originalPosition: position,
                    isue: data1.isue,
                    style: {
                        wordWrap: "break-word",
                        textAlign: "center",
                        borderColor: "#00008B",
                        maxHeight: "1400px"
                    }
                })
            );
            setsavegraphDisabled(true)
        }
        setNewNodeId(nwnodeid.toString())
    };
    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };
    const onDragEnd = (event, element) => {
        setElement(element);
        setNodeX(element.position.x);
        setNodeY(element.position.y);
        if (!isNil(lastSelectedElement)) {
            setElement(lastSelectedElement);
        }
    };

    const isEdge = (val) => {
      
        if (
            val === "default" ||
            val === "straight" ||
            val === "step" ||
            val === "smoothstep"
        ) {
            return true;
        } else {
            return false;
        }
    };

    const onElementsRemove = (elementsToRemove) => {
        if (!isNil(elementsToRemove) && elementsToRemove[0].type === 'default') {
            // updateCyclicDependency(elementsToRemove[0]);
            setElements((els) => removeElements(elementsToRemove, els));
        } else {

            let currentSteps = [...elements];
            const index = currentSteps.indexOf(element);

            setElements((els) => removeElements([elements[index]], els));
            setHideText1(false);
            // handleToggleSidebar(!toggled)
            setNewNodeId(null)
        }


    }
    const onElementClick = (event, elem) => {
        if (props.readOnly && props.isKnowledgeGraph && props.openContentPopup) {
            props.openContentPopup(event, elem.id);
        } if (props.readOnly) {
            return;
        }
    };

    const handleNodeMouseEvents = (eventType, element) => {
        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    const showExplanation = eventType === 'onNodeMouseEnter' ? true : false;
                    el.data = {
                        ...el.data,
                        showExplanation: showExplanation
                    };
                }
                return el;
            })
        );
    }


    const getLayoutedElements = (direction = 'TB') => {
        debugger;

        const isHorizontal = false;
        dagreGraph.setGraph({ rankdir: direction });
        elements.forEach((node) => {

            if (!isEdge(node.type)) {
                dagreGraph.setNode(node.id, { width: node.data.width, height: node.data.height });
            } else {
                dagreGraph.setEdge(node.source, node.target);
            }

        });

        dagre.layout(dagreGraph);
        elements.forEach((node) => {

            if (!isEdge(node.type)) {
                const nodeWithPosition = dagreGraph.node(node.id);
                node.targetPosition = isHorizontal ? 'left' : 'top';
                node.sourcePosition = isHorizontal ? 'right' : 'bottom';
                node.position = {
                    x: nodeWithPosition.x,
                    y: nodeWithPosition.y,
                };

                return node;
            }


            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).

        });
        setElements([...elements]);

    };


    useEffect(() => {
        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el = { ...el, type: edgeType };
                }
                return el;
            })
        );
    }, [edgeType, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el = { ...el, label: edgeLabel };
                }
                return el;
            })
        );
    }, [edgeLabel, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el = { ...el, animated: edgeAnimated };
                }
                return el;
            })
        );
    }, [edgeAnimated, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el = { ...el, arrowHeadType: edgeArrowHead };
                }
                return el;
            })
        );
    }, [setEdgeArrowHead, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el.labelStyle = {
                        ...el.labelStyle,
                        fontSize: edgeLabelStyle.fontSize
                    };
                }
                return el;
            })
        );
    }, [edgeLabelStyle, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el.labelStyle = {
                        ...el.labelStyle,
                        fill: edgeLabelStyle.fill
                    };
                }
                return el;
            })
        );
    }, [edgeLabelStyle, setElements]);

    useEffect(() => {

        setElements((els) =>
            els.map((el) => {
                if (el.id === element.id) {
                    el.labelStyle = {
                        ...el.labelStyle,
                        fontWeight: edgeLabelStyle.fontWeight
                    };
                }
                return el;
            })
        );
    }, [edgeLabelStyle, setElements]);



    useEffect(() => {
        console.log("layout direction.....", layoutDirection)
        if (layoutDirection !== '') {
            setTimeout(() => {
                getLayoutedElements();
            }, 1000)
            //isAutoPositioningRequired = false;
            setLayoutDirection("");
        }

    }, [layoutDirection, setElements]);

    const clearElements = () => {
        setElements((els) => els.splice(0, els.length));
    }

    useEffect(() => {
        clearElements();
        if (!isNil(props) && (props.readOnly)) {
            clearTimeout(debounceTimer);
            setDebounceTimer(setTimeout(() => {
                setElements(parseUIJson(JSON.parse(props.uiJson)));
                // if (!props.isKnowledgeGraph) {
                setTimeout(() => {
                    setLayoutDirection('TB')
                }, 1000)
                // }
            }, 700));
        }
    }, [props])

    const compare = (a, b) => {
        return parseInt(a.id) - parseInt(b.id);
    }

    const parseUIJson = (uiJson) => {

        uiJson = uiJson.sort(compare);
        if (uiJson.length > 0) {

            maxNodeId = parseInt(uiJson[uiJson.length - 1]['id']);
        }
        let count = 0;
        uiJson.forEach((element) => {

            let importance = '';
            if (element.data.isPrimary) {
                importance = PRIMARY
            } else if (element.data.isnonPrimary) {
                importance = NON_PRIMARY
            } else {
                importance = OPTIONAL
            }
            if (element.data.error_source === 0) {
                element.data.errortype = BASIC_ERROR
            } else if (element.data.error_source === 1)
                element.data.errortype = AUTHOR_COMBINATION
            else if (element.data.error_source === 2) {
                element.data.errortype = SYSTEM_COMBINATION
            } else {

                element.data.errortype = PROPAGATION_ERROR
            }
            element.originalPosition = clone(element.position);
            if (parseInt(element.position.x) === 0 && parseInt(element.position.y) === 0) {
                count++;
            }
            // if (!isNil(props.isKnowledgeGraph) && props.isKnowledgeGraph) {
            //   element.data.content = element.data.label;
            //   element.data.label = '';
            // }
            element.data.isReadOnly = props.readOnly;
            element.data.isresourceidsdisplay = !element.data.isNodeCorrect ? true : false

            element.data.isnodeiddisplay = true
            if (props.readOnly) {
                console.log(element.position)
                element.position = { x: -100, y: -100 }
            }

            element.data.hidden = false;
            element.data.importance = importance;
            element.data.background = element.data.isNodeCorrect ? (filter(NODE_BACKGROUND, ['type', importance]))[0]['color'] : INCORRECT_COLOR;
            element.data.border = nodeBackground;
            if (!isNil(element.user_added)) {
                element.data.user_added = element.user_added
            }
        })

        //if (count > 1) isAutoPositioningRequired = true;
        return createEdges(uiJson);
    }

    const createEdges = (uiJson) => {
        let edges = [];
        let edgeId = 1000;
        uiJson.forEach((element) => {
            let parents = [];

            const optional_prereqs = element.data.optional_prereqs.split(',');
            const incrorrect_variants = element.data.incorrect_variants.split(',');
            if (element.data.prereqs.length > 0 && (element.data.isNodeCorrect || props.hasCheckMyWOrk)) {
                parents = [...element.data.prereqs.split(',')];
            }
            if (element.data.optional_prereqs.length > 0 && element.data.isNodeCorrect) {
                parents = [...parents, ...optional_prereqs];
            }

            if (!isNil(element.data.combination_node) && element.data.combination_node.length > 0 && !element.data.isNodeCorrect) {
                parents = [...element.data.combination_node.split(',')];
            }

            for (let i = 0; i < parents.length; i++) {
                const edgeObj = createEdge(element, parents[i], element.id, edgeId)
                edges.push(edgeObj);
                edgeId++;
            }

            if (element.data.incorrect_variants.length > 0 && !(props.readOnly)) {
                for (let i = 0; i < incrorrect_variants.length; i++) {
                    const childElement = filter(uiJson, ['id', incrorrect_variants[i]])[0];
                    if (!isNil(childElement)) {
                        const edgeObj = createEdge(filter(uiJson, ['id', incrorrect_variants[i]])[0], element.id, incrorrect_variants[i], edgeId)
                        edges.push(edgeObj);
                        edgeId++;
                    }

                }
            }
        })
        return [...uiJson, ...edges];
    }

    const createEdge = (element, source, target, edgeId) => {

        return {
            source: source,
            target: target,
            type: "default",
            animated: true,
            sourceHandle: "source_handle",
            targetHandle: "target_handle",
            id: source + "_" + target,
            style: { stroke: element.data.isNodeCorrect ? "#000000" : INCORRECT_COLOR, cursor: "pointer" },
            label: props.readOnly ? "" : "Click to select",
            labelStyle: {
                fill: "#000",
                fontWeight: "100",
                fontSize: "12px",
                cursor: "pointer"
            },
            arrowHeadType: "arrow"
        }
    }

    return (
        <>
            <div id="dndflowgraph" className="dndflow" ref={reactFlowWrapper} style={props.readOnly ? { minHeight: '100px' } : { minHeight: 'calc(100vh - 100px)' }}>
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" id="reactflow-wrapper">
                        <ReactFlow
                            elements={elements}
                            onConnect={onConnect}
                            onElementClick={onElementClick}
                            // onElementsRemove={onElementsRemove}
                            nodeTypes={nodeTypes}
                            // onLoad={onLoad}
                            // onDrop={onDrop}
                            // onDragOver={onDragOver}
                            // onNodeDragStop={onDragEnd}
                            onNodeMouseEnter={(event, node) =>
                                handleNodeMouseEvents('onNodeMouseEnter', node)
                            }
                            onNodeMouseLeave={(event, node) =>
                                handleNodeMouseEvents('onNodeMouseLeave', node)
                            }
                            defaultZoom={0}
                        >

                            <Controls />
                        </ReactFlow>
                    </div>



                </ReactFlowProvider>
                <ToastContainer />



            </div >
        </>
    );
};
export default GraphFlow;