import React, { useEffect, useState } from 'react'
import './TestReport.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatNumber } from '../../common/Functions'
import { PrProgressMaxValue } from '../../common/Constants'
import MathJaxRenderer from '../Common/MathJaxRenderer/MathJaxRenderer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { isNil } from 'lodash';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "15px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
    "&:last-child td, &:last-child th": {
        // border: "1px solid red"
    },
}));

const StyledTableRowInner = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
    "&:last-child td, &:last-child th": {
        border: 0
    },
}));


function CommonErrorItems(props) {

    const { row } = props;
    const [open, setOpen] = React.useState(true);
 
    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell sx={{ width: "10px" }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" style={{fontSize:'medium'}}>
                    {row.Group_name}({row.Group_counter})
                </StyledTableCell>

            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <Table sx={{ marginLeft: "5%", width: "100%" }}
                                stickyHeader aria-label="sticky table"
                                size={'medium'}>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width:'70%'}}>Equations</StyledTableCell>
                                        <StyledTableCell style={{width:'10%'}}>#Count</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        row.Equations !== undefined && row.Equations !== null && row.Equations.length > 0 ? (
                                            <>
                                                {
                                                    row.Equations.map((data, cindex) => {
                                                        let progresscount = data.count
                                                        if (parseFloat(data.count) > parseFloat(PrProgressMaxValue)) {
                                                            progresscount = PrProgressMaxValue
                                                        }
                                                        let latexvalue = ""
                                                        if (data.latex !== undefined && data.latex !== null && data.latex.length > 0) {
                                                            latexvalue = data.latex[0]
                                                        }
                                                        return (
                                                            <StyledTableRowInner key={cindex}>
                                                                <StyledTableCell component="th" scope="row">
                                                                    {/* <MathJaxRenderer math={latexvalue}></MathJaxRenderer> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: latexvalue }} />
                                                                </StyledTableCell>
                                                                <StyledTableCell>

                                                                    {`${data.count !== null && data.count !== undefined ? formatNumber(data.count) : 0.00}`
                                                                    }

                                                                </StyledTableCell>
                                                            </StyledTableRowInner>
                                                        )
                                                    }
                                                    )
                                                }
                                            </>
                                        ) : (<div>No Equations Found</div>)
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}
export default CommonErrorItems