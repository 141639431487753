import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TextField, Grid, Box, Button, FormLabel } from '@mui/material';
import { getCurrentUserId, getCurrentUserName } from '../../common/Functions';
import { saveFeedbackDetails } from '../../common/API';
import { FaStar } from "react-icons/fa";
import { Container, RadioRate, Rating } from "./RatingStyles";

class Feedback extends Component {

    state = {
        userId: getCurrentUserId(),
        userName: getCurrentUserName(),
        otherFeedback: '',
        rating: 0
    }

    handleOtherFeedbackChange = (e) => {
        this.setState({ otherFeedback: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.otherFeedback === '' && this.state.rating === 0) {
            toast.error("We value your feedback, Please fill up the feedback form and submit !", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
        else {
            const reqData = {
                userId: this.state.userId,
                otherFeedback: this.state.otherFeedback,
                rating: this.state.rating,
                sessionId: 0,
                userName: this.state.userName,
                feedbackType: "",
                issueDetail: "",
                overallExpRating: 0,
                easeOfNavigationRating: 0,
                recommendUs: "",
                additionalComments: "",
                isHelped: "",
                accuracyRating: 0,
                hintQualityRating: 0,
                explanationRating: 0,
                rootcauseAnalysisRating: 0,
                videoLectureEngagingRating: 0,
                raiseHandRating: 0,
                isEffective: "",
                knowledgebaseRating: 0,
                aiResponseRating: 0
            }

            saveFeedbackDetails(reqData).then(res => {
                toast.success("Thank you for writting to us !", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                /* Clear Form Data */
                this.setState({ otherFeedback: '', rating: 0 })
            })
                .catch(err => {
                    console.error(err.message);
                    toast.error("Some error occurred", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
        }
    }

    render() {

        return (
            <>
                <div className='fwidth'>
                    <Box component="form" sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="otherFeedback"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    id="otherFeedback"
                                    label="Please provide any additional feedback or suggestions."
                                    value={this.state.otherFeedback}
                                    onChange={this.handleOtherFeedbackChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel id="refer-us-radio">Rate Us </FormLabel>
                                <Container>
                                    {[...Array(5)].map((item, index) => {
                                        const givenRating = index + 1;
                                        return (
                                            <label>
                                                <RadioRate
                                                    type="radio"
                                                    value={givenRating}
                                                    onClick={() => { this.setState({ rating: givenRating }) }}
                                                />
                                                <Rating>
                                                    <FaStar
                                                        color={
                                                            givenRating < this.state.rating || givenRating === this.state.rating
                                                                ? "#d7ea4e"
                                                                : "rgb(192,192,192)"
                                                        }
                                                    />
                                                </Rating>
                                            </label>
                                        );
                                    })}
                                </Container>
                            </Grid>
                        </Grid>
                        <Button onClick={this.handleSubmit} type='submit' fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}> Submit </Button>
                    </Box>

                    <ToastContainer />
                </div>
            </>
        )
    }
}

export default withRouter(Feedback);