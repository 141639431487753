import React, { useEffect, useState } from 'react';
import './TestReport.scss';
import PropTypes from 'prop-types';
import {
    withStyles
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getInstituteId, getMenuSelectedItem } from '../../common/Functions'
import { getCommonEquuationErrors_Report } from '../../common/API'
import MuiTableHead from "@material-ui/core/TableHead";
import { styled } from '@mui/material/styles';
import CommonErrorItems from './CommonErrorItems';

export const DEFAULT_COLOR = '#5470C6';
export const SELECTED_COLOR = '#FFA756';


const TableHead = withStyles(theme => ({
    root: {
        backgroundColor: '#ebe7e7'
    }
}))(MuiTableHead);

const TableRowCustom = withStyles(theme => ({
    root: {
        color: '#000',
        fontWeight: "bold"
    }
}))(TableRow);



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: "7px"
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0,
    // },
}));


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadPaticipantReport(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRowCustom>
                <TableCell>
                </TableCell>
                <TableCell>
                    Common Errors
                </TableCell>
            </TableRowCustom>
        </TableHead>
    );
}

EnhancedTableHeadPaticipantReport.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const CommonErrorsReport = (props) => {
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [displayMessage, setDisplayMessage] = useState("")
    const [commonErrorData, setcommonErrorData] = React.useState([]);
    const [loader, setloader] = useState(false);



    // useEffect(() => {
    //     fetchcommonerrors()
    // }, [])

    useEffect(() => {
        fetchcommonerrors()
    }, [props.challenge_id])

    const fetchcommonerrors = () => {
        setDisplayMessage("")
        setloader(true)
        const reqData = {
            params: {
                assignment_id: parseInt(props.assignment_id),
                institute_id: getInstituteId(),
                course_id: getMenuSelectedItem(),
                challenge_id: props.challenge_id
            }
        }
        getCommonEquuationErrors_Report(reqData).then(res => {
            if (res.data.data !== undefined && res.data.data !== null && res.data.data.length > 0) {
                setcommonErrorData(res.data.data)
                setDisplayMessage("")
                setloader(false)
            }
            else {
                setDisplayMessage("No Data Found.")
                setloader(false)
            }
        }).catch(err => {
            console.error(err.message);
            setloader(false)
            setDisplayMessage("No Data Found.")
        })
    }
    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const visibleRowsParticipantReportList = React.useMemo(
        () =>
            stableSort(commonErrorData, getComparator(order, orderBy)),
        [commonErrorData, order, orderBy],
    );

    return (
        <div style={{width:'100%', overflowX:'hidden'}}>
            <Table
                stickyHeader aria-label="sticky table"
                size={'medium'}>
                <EnhancedTableHeadPaticipantReport
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {
                        visibleRowsParticipantReportList !== undefined && visibleRowsParticipantReportList !== null && visibleRowsParticipantReportList.length > 0 ?
                            (visibleRowsParticipantReportList.map((row, index) => {
                                return (
                                    <>
                                        <CommonErrorItems row={row}></CommonErrorItems>
                                    </>
                                );
                            })) :
                            (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={10} style={{ padding: "20px", textAlign: "center", fontSize: "17px" }}>
                                        {
                                            loader && (
                                                <button class="btn btn-primary" type="button" disabled>
                                                    <span style={{ marginRight: '5px' }} class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Processing...
                                                </button>
                                            )
                                        }
                                        {
                                            <div>{displayMessage}</div>
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                    }

                </TableBody>
            </Table>
        </div>
    )
}
export default React.memo(CommonErrorsReport);