import React from 'react';
import Typography from '@mui/material/Typography';
import GraphFlow from './GraphFlow';


const SolKnowGraph = (props) => {

    return (
        <>
            <div className="solutionGraphCotainer">
                {
                    props.isKnowledgeGraph ?
                        (

                            <Typography>
                                <div style={{ height: '830px' }}>
                                    <GraphFlow readOnly={props.readOnly} uiJson={props.knowledgeGraphUiJson} hasCheckMyWOrk={props.hasCheckMyWOrk}
                                        isKnowledgeGraph={props.isKnowledgeGraph} openContentPopup={props.openContentPopup}></GraphFlow>
                                </div>
                            </Typography>

                        ) :

                        (

                            <>
                                <Typography>
                                    {
                                        props.finalsubstitutionslist !== "" && props.finalsubstitutionslist !== '' && props.finalsubstitutionslist !== undefined && props.finalsubstitutionslist !== null && props.finalsubstitutionslist !== "null" && props.finalsubstitutionslist !== "null" ? (
                                            <>
                                                <div style={{ fontSize: "11px", maxWidth: "500px" }}>
                                                    {props.substituteText} {props.finalsubstitutionslist}
                                                </div>

                                            </>
                                        ) : null
                                    }
                                </Typography>
                                <Typography>
                                    {props.uiJson !== "[]" ?
                                        <div style={{ height: '730px', marginTop: "20px" }}>
                                            <div className="legendContainer">
                                                <div className="legendItemContainer">
                                                    <div className="correct"></div>
                                                    <span>Correct step</span>
                                                </div>

                                                <div className="legendItemContainer">
                                                    <div className="optional"></div>
                                                    <span>Step inferred as correct step</span>
                                                </div>

                                                <div className="legendItemContainer">
                                                    <div className="incorrect"></div>
                                                    <span>Incorrect step</span>
                                                </div>

                                            </div>


                                            <GraphFlow readOnly={props.readOnly} uiJson={props.uiJson} hasCheckMyWOrk={props.hasCheckMyWOrk}  ></GraphFlow>
                                        </div>
                                        :
                                        <div style={{ height: '730px' }}>
                                            No diagram is available to show.
                                        </div>
                                    }

                                </Typography>
                            </>

                        )
                }



            </div>
        </>
    );
}
export default SolKnowGraph
