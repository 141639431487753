import React from 'react';
import { PanelResizeHandle } from "react-resizable-panels";

import "./style.css";


const ResizePanelHandle = (props) => {

    return (
        <>
            <PanelResizeHandle
                className="ResizeHandleOuter"
                id={props.id}
            >
                <div className="ResizeHandleInner">
                    <svg className='Icon' viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M13,20V4H15.03V20H13M10,20V4H12.03V20H10M5,8L9.03,12L5,16V13H2V11H5V8M20,16L16,12L20,8V11H23V13H20V16Z"
                        />
                    </svg>
                </div>
            </PanelResizeHandle>
        </>
    );
}
export default ResizePanelHandle
